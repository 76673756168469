import React from 'react';
import {ReactComponent as SunIcon} from '../../../assets/images/ic_sun.svg';
import {ReactComponent as PickaxeIcon} from '../../../assets/images/ic_pickaxe.svg';
import {ReactComponent as MountainIcon} from '../../../assets/images/ic_mountain.svg';
import {DiagnosticEssenceNonIBS, TemperatureNonIBS} from "../../../interfaces/Diagnostic_interface";
import {getBorderColor, getIconColor} from '../../../assets/styles/inbColors';
import {formatEssence, getSumTemperature} from "./common/INBHelpers";
import BaseTable from "./common/BaseTable";

interface INBIconProps {
    temperature: TemperatureNonIBS;
}

const INBIcon: React.FC<INBIconProps> = ({temperature}) => {
    const iconStyle = {
        width: '16px',
        height: '16px'
    };

    const borderColor = getBorderColor(
        temperature.climatique.color,
        temperature.pedologique.color,
        temperature.topographique.color
    );

    const containerStyle = {
        width: '22px',
        height: '70px',
        borderRadius: '5px',
        border: `1px solid ${borderColor}`,
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '3px',
        padding: '4px'
    };

    return (
        <div style={containerStyle}>
            <SunIcon
                style={iconStyle}
                fill={getIconColor(temperature.climatique.color)}
            />

            <PickaxeIcon
                style={iconStyle}
                fill={getIconColor(temperature.pedologique.color)}
            />
            <MountainIcon
                style={iconStyle}
                fill={getIconColor(temperature.topographique.color)}
            />

        </div>
    );
}

const INBTable: React.FC<{ diagnosticsEssenceNonIBS: DiagnosticEssenceNonIBS[] }> = ({diagnosticsEssenceNonIBS}) => {
    const sortedData = diagnosticsEssenceNonIBS.slice().sort((a, b) => {
        const sumA = getSumTemperature(a.temperature);
        const sumB = getSumTemperature(b.temperature);
        return sumA - sumB;
    });

    const renderCell = (diagnostic: DiagnosticEssenceNonIBS, columnIndex: number) => {
        if (columnIndex === 0) {
            return formatEssence(diagnostic.essence);
        }
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <INBIcon temperature={diagnostic.temperature[columnIndex - 1]}/>
            </div>
        );
    };

    return <BaseTable data={sortedData} renderCell={renderCell} />;
};

export default INBTable;