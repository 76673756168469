import React from 'react';
import {
    Box,
    Typography,
    Button,
    Chip,
    TextField,
    Card,
    Divider,
    Stack,
} from '@mui/material';
import {
    Edit,
    Delete,
    CloudUpload,
    Save,
    Cancel
} from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {LUF} from "../../interfaces/luf_interface";
import {PPD} from "../../interfaces/ppd_interface";

interface DocumentDetailsProps<T extends LUF | PPD> {
    document: T;
    editing: boolean;
    setEditing: (editing: boolean) => void;
    setCurrentDocument: (document: T) => void;
    setShowConfirmation: (show: boolean) => void;
    setShowOnlineConfirmation: (show: boolean) => void;
    setShowDeleteConfirmation: (show: boolean) => void;
    disabled?: boolean;
}

function DocumentDetails<T extends LUF | PPD>({
                                                 document,
                                                 editing,
                                                 setEditing,
                                                 setCurrentDocument,
                                                 setShowConfirmation,
                                                 setShowOnlineConfirmation,
                                                 setShowDeleteConfirmation,
                                                 disabled = false
                                             }: DocumentDetailsProps<T>) {
    const modules = {
        toolbar: [
            [{'font': []}],
            [{'header': [1, 2, 3, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'align': []}],
            ['clean']
        ],
        clipboard: {
            matchVisual: false
        },
    };

    const formats = [
        'font',
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'link', 'image',
        'align'
    ];

    const renderContent = () => {
        if (!document.content) return '';

        if (document.content.trim().startsWith('<')) {
            return (
                <Box sx={{
                    fontSize: '0.9rem',
                    '& h1': { fontSize: '1.5rem' },
                    '& h2': { fontSize: '1.3rem' },
                    '& h3': { fontSize: '1.1rem' },
                    '& p': { fontSize: '0.9rem', lineHeight: 1.5 },
                    '& li': { fontSize: '0.9rem' }
                }}>
                    <div dangerouslySetInnerHTML={{ __html: document.content }} />
                </Box>
            );
        } else {
            return document.content.split('\n').map((line, index) => (
                <Typography key={index} paragraph sx={{ fontSize: '0.9rem', lineHeight: 1.5 }}>
                    {line}
                </Typography>
            ));
        }
    };

    return (
        <Card elevation={3} sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                flexShrink: 0
            }}>
                <Stack direction="row" spacing={2} alignItems="center">
                    {editing ? (
                        <TextField
                            variant="outlined"
                            value={document.version}
                            onChange={(e) => setCurrentDocument({ ...document, version: e.target.value } as T)}
                            size="small"
                            sx={{ width: '180px' }}
                            disabled={disabled}
                            label="Version"
                            InputProps={{
                                style: { fontSize: '0.9rem' }
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize: '0.9rem',
                                    fontWeight: 'medium'
                                },
                                shrink: true
                            }}
                        />
                    ) : (
                        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                            Version {document.version}
                        </Typography>
                    )}
                    {document.online && !editing && (
                        <Chip
                            label="En ligne"
                            color="success"
                            size="small"
                            sx={{ color: 'white', fontWeight: 'bold', backgroundColor: 'primary.main' }}
                        />
                    )}
                </Stack>

                {!editing ? (
                    <Stack direction="row" spacing={1}>
                        <Button
                            startIcon={<Edit />}
                            variant="outlined"
                            color="primary"
                            onClick={() => setEditing(true)}
                            sx={{ textTransform: "none" }}
                            size="small"
                        >
                            Modifier
                        </Button>
                        {!document.online && (
                            <Button
                                startIcon={<CloudUpload />}
                                color="primary"
                                variant="contained"
                                onClick={() => setShowOnlineConfirmation(true)}
                                sx={{ textTransform: "none"}}
                                size="small"
                            >
                                Mettre en ligne
                            </Button>
                        )}
                        <Button
                            startIcon={<Delete />}
                            color="error"
                            variant="outlined"
                            onClick={() => setShowDeleteConfirmation(true)}
                            sx={{ textTransform: "none" }}
                            size="small"
                        >
                            Supprimer
                        </Button>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={1}>
                        <Button
                            startIcon={<Cancel />}
                            color="secondary"
                            variant="outlined"
                            onClick={() => setEditing(false)}
                            sx={{ textTransform: "none" }}
                            size="small"
                        >
                            Annuler
                        </Button>
                        <Button
                            startIcon={<Save />}
                            color="primary"
                            variant="contained"
                            onClick={() => setShowConfirmation(true)}
                            sx={{ textTransform: "none" }}
                            size="small"
                        >
                            Enregistrer
                        </Button>
                    </Stack>
                )}
            </Box>

            <Box sx={{
                px: 2,
                pb: 2,
                flexShrink: 0
            }}>
                {editing ? (
                    <TextField
                        label="Titre"
                        variant="outlined"
                        value={document.title}
                        onChange={(e) => setCurrentDocument({ ...document, title: e.target.value } as T)}
                        fullWidth
                        size="small"
                        InputProps={{
                            style: { fontSize: '0.9rem' }
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '0.9rem',
                                fontWeight: 'medium'
                            },
                            shrink: true
                        }}
                    />
                ) : (
                    <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 'medium' }}>
                        {document.title}
                    </Typography>
                )}
            </Box>

            <Divider sx={{ flexShrink: 0 }} />

            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                p: 2,
                overflow: 'hidden',
                minHeight: 0
            }}>
                {editing ? (
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        '& .quill': {
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        },
                        '& .ql-toolbar.ql-snow': {
                            border: '1px solid #e0e0e0',
                            borderBottom: 'none',
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                            zIndex: 1,
                            backgroundColor: '#ffffff',
                            flexShrink: 0
                        },
                        '& .ql-container.ql-snow': {
                            flexGrow: 1,
                            border: '1px solid #e0e0e0',
                            borderTop: 'none',
                            borderBottomLeftRadius: '4px',
                            borderBottomRightRadius: '4px',
                            overflow: 'auto',
                            height: 'auto'
                        },
                        '& .ql-editor': {
                            minHeight: '100%',
                            fontSize: '0.9rem'
                        }
                    }}>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={document.content}
                            onChange={(content) => setCurrentDocument({ ...document, content } as T)}
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        />
                    </Box>
                ) : (
                    <Box sx={{
                        width: '100%',
                        padding: 2,
                        overflow: 'auto'
                    }}>
                        {renderContent()}
                    </Box>
                )}
            </Box>
        </Card>
    );
}

export default DocumentDetails;