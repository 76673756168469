export const HumusEnum = {
    REBOISEMENT: "Reboisement",
    AMPHIMULL: "Amphimull",
    DYSMODER: "Dysmoder",
    DYSMULL: "Dysmull",
    EUMODER: "Eumoder",
    EUMULL: "Eumull",
    HEMIMODER: "Hémimoder",
    MESOMULL: "Mésomull",
    MOR: "Mor",
    OLIGOMULL: "Oligomull",
    TERRE_AGRICOLE: "Terre agricole",
};

export const HumusMapping:{ [key: string]: string }  = {
    'reboisement': HumusEnum.REBOISEMENT,
    'amphimull': HumusEnum.AMPHIMULL,
    'dysmoder': HumusEnum.DYSMODER,
    'dysmull': HumusEnum.DYSMULL,
    'eumoder': HumusEnum.EUMODER,
    'eumull': HumusEnum.EUMULL,
    'hémimoder': HumusEnum.HEMIMODER,
    'mésomull': HumusEnum.MESOMULL,
    'mor': HumusEnum.MOR,
    'oligomull': HumusEnum.OLIGOMULL,
    'terre_agricole': HumusEnum.TERRE_AGRICOLE,
};
