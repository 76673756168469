import React, {useRef, useState} from "react";
import { Box, Button } from "@mui/material";
import AppHeader from "../../components/common/AppHeader";
import {PopupTable, PopupTableHandle} from "./popup_table";
import {PopupModal} from "./components/PopupModal";
import {Popup} from "../../interfaces/popup_interface";

const Popups: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPopup, setSelectedPopup] = useState<Popup | null>(null);
    const tableRef = useRef<PopupTableHandle>(null);


    const handleOpenModal = (popup?: Popup) => {
        setSelectedPopup(popup || null);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPopup(null);
    };

    const handleSuccess = () => {
        tableRef.current?.refreshData();
    };

    return (
        <Box sx={{ p: 4 }}>
            <AppHeader title="Popup" />
            <Button
                sx={{ mb: 2 }}
                variant="outlined"
                onClick={() => handleOpenModal()}
            >
                Ajouter un popup
            </Button>

            <PopupTable
                ref={tableRef}
                onEdit={handleOpenModal}
            />

            <PopupModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                popupToEdit={selectedPopup}
                onSuccess={handleSuccess}
            />
        </Box>
    );
};
export default Popups;