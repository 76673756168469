import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Box,
} from '@mui/material';
import {PopupApi} from "../../../api/popup_api";
import {Popup} from "../../../interfaces/popup_interface";

interface ViewPopupProps {
    open: boolean;
    handleClose: () => void;
    popupId: number;
}

export const ViewPopup: React.FC<ViewPopupProps> = ({open, handleClose, popupId}) => {
    const [popup, setPopup] = useState<Popup | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            if (!open || !popupId) return;

            setIsLoading(true);
            try {
                const popupData = await PopupApi.getById(popupId);
                setPopup(popupData);
            } catch (error) {
                console.error('Erreur de chargement des données');
            }
            setIsLoading(false);
        };

        loadData();
    }, [open, popupId]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            {isLoading ? (
                <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress/>
                </Box>
            ) : (
                <>
            <DialogTitle sx={{mx: 2, fontSize: '2rem'}}>{popup?.title}</DialogTitle>

            <DialogContent>

                        <Box sx={{mx: 2, my: 1}}>
                            <div dangerouslySetInnerHTML={{__html: popup?.descriptif || ''}}/>
                        </Box>

            </DialogContent>
                </>
            )}
            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};