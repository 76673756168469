import React from 'react';
import DocumentCreation from '../../../components/common/DocumentCreation';
import { LUF } from '../../../interfaces/luf_interface';

interface LufCreationProps {
    currentLuf: LUF | null;
    setCurrentLuf: (luf: LUF) => void;
    newVersion: string;
    setNewVersion: (version: string) => void;
    setShowConfirmation: (show: boolean) => void;
    setCurrentTab: (tab: number) => void;
    disabled?: boolean;
}

const LufCreation: React.FC<LufCreationProps> = ({
                                                     currentLuf,
                                                     setCurrentLuf,
                                                     newVersion,
                                                     setNewVersion,
                                                     setShowConfirmation,
                                                     setCurrentTab,
                                                     disabled = false
                                                 }) => {
    return (
        <DocumentCreation<LUF>
            currentDocument={currentLuf}
            setCurrentDocument={setCurrentLuf}
            newVersion={newVersion}
            setNewVersion={setNewVersion}
            setShowConfirmation={setShowConfirmation}
            setCurrentTab={setCurrentTab}
            placeholder={{
                title: "Titre de la licence utilisateur final",
                content: "Contenu de la licence utilisateur final..."
            }}
            disabled={disabled}
        />
    );
};

export default LufCreation;