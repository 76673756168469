import React, { ReactNode } from 'react';
import { Box, Paper } from '@mui/material';
import logo from '../../assets/images/logo_bioclimsol_solo.png';

interface AuthLayoutProps {
    children: ReactNode;
    showLogo?: boolean;
    maxWidth?: string | number;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
                                                   children,
                                                   showLogo = true,
                                                   maxWidth = '500px'
                                               }) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: '100vh',
            width: '100%',
            position: 'relative'
        }}>
            {/* Images */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0
            }}>
                <Box
                    id="login_background_1"
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'block'
                        },
                        width: '49.7%',
                        height: '100%',
                        backgroundSize: 'cover',
                        objectFit: 'contain',
                    }}
                />

                <Box
                    id="login_background_2"
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'block'
                        },
                        width: '49.7%',
                        height: '100%',
                        backgroundSize: 'cover',
                        objectFit: 'contain',
                    }}
                />
            </Box>

            {/* Content card */}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    zIndex: 1,
                    p: 2
                }}
            >
                <Box
                    component={Paper}
                    sx={{
                        width: '100%',
                        maxWidth: {
                            xs: '100%',
                            sm: maxWidth
                        },
                        display: 'flex',
                        paddingTop: '2rem',
                        paddingBottom: '3rem',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    }}
                >
                    {showLogo && (
                        <img
                            src={logo}
                            alt="Logo de BioClimSol"
                            style={{
                                width: '40%',
                                height: 'auto',
                                padding: 1,
                                maxWidth: '180px',
                            }}
                            className="login_logo"
                        />
                    )}

                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default AuthLayout;