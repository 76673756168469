import React from 'react';
import { Card, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, Box, Divider } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import {LUF} from "../../interfaces/luf_interface";
import {PPD} from "../../interfaces/ppd_interface";

interface DocumentListProps<T extends LUF | PPD> {
    items: T[];
    currentItemId?: number;
    onSelectItem: (item: T) => void;
    title: string;
    emptyMessage: string;
}

function DocumentList<T extends LUF | PPD>({
                                              items,
                                              currentItemId,
                                              onSelectItem,
                                              title,
                                              emptyMessage
                                          }: DocumentListProps<T>) {
    return (
        <Card elevation={3} sx={{
            width: { xs: '100%', md: 280 },
            minWidth: { xs: '100%', md: 280 },
            mr: { xs: 0, md: 2 },
            mb: { xs: 2, md: 0 },
            overflow: 'auto',
            flexShrink: 0,
            height: { xs: 'auto', md: '100%' },
            maxHeight: { xs: '300px', md: 'none' }
        }}>
            <Box sx={{ p: 2, backgroundColor: 'primary.main', color: 'white' }}>
                <Typography variant="subtitle1" sx={{
                    fontWeight: 'medium',
                    fontSize: '0.95rem'
                }}>
                    {title}
                </Typography>
            </Box>
            <Divider />

            {items.length === 0 ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.875rem' }}>
                        {emptyMessage}
                    </Typography>
                </Box>
            ) : (
                <List dense sx={{ p: 0 }}>
                    {items.map((item) => (
                        <React.Fragment key={item.id}>
                            <ListItem
                                button
                                onClick={() => onSelectItem(item)}
                                selected={currentItemId === item.id}
                                sx={{
                                    px: 2,
                                    py: 1.5,
                                    '&.Mui-selected': {
                                        backgroundColor: 'rgba(43, 96, 62, 0.1)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(43, 96, 62, 0.15)'
                                        }
                                    }
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{ fontSize: '0.9rem' }}>
                                            {`v${item.version}`}
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                                sx={{
                                                    display: 'block',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '0.8rem'
                                                }}
                                            >
                                                {item.title}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                color="text.secondary"
                                                sx={{ fontSize: '0.75rem' }}
                                            >
                                                {new Date(item.dateOnline || item.createdAt || '').toLocaleDateString()}
                                            </Typography>
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    {item.online && <CheckCircle fontSize="small" sx={{ color: 'primary.main' }} />}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    ))}
                </List>
            )}
        </Card>
    );
}

export default DocumentList;