import React, {useState, useEffect, useCallback} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    CircularProgress
} from "@mui/material";
import {Popup} from "../../../interfaces/popup_interface";
import {useToast} from "../../../hooks/useToast";
import {UserApi} from "../../../api/users_api";
import {ThematicDataApi} from "../../../api/thematic_data_api";
import {PopupApi} from "../../../api/popup_api";
import {AddPopupForm, FormData} from "./FormPopup";
import {ThematicData} from "../../../interfaces/thematicData_interface";
import {User} from "../../../interfaces/user_interface";

interface PopupModalProps {
    open: boolean;
    handleClose: () => void;
    popupToEdit?: Popup | null;
    onSuccess?: () => void;
}

const initialFormData: FormData = {
    thematic: '',
    titre: '',
    description: '',
};

export const PopupModal: React.FC<PopupModalProps> = ({
                                                          open,
                                                          handleClose,
                                                          popupToEdit = null,
                                                          onSuccess
                                                      }) => {
    const [currentUser, setCurrentUser] = useState<User>();
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [thematicData, setThematicData] = useState<ThematicData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {showSuccess, showError} = useToast();
    const [popupData, setPopupData] = useState<Popup | null>(null);

    const updateFormData = useCallback((data: Partial<FormData>) => {
        setFormData(prevState => ({
            ...prevState,
            ...data
        }));
    }, []);

    useEffect(() => {
        const fetchInitialData = async () => {
            setIsLoading(true);
            try {
                const [userData, thematicsData] = await Promise.all([
                    UserApi.getInfoCurrentUser(),
                    ThematicDataApi.getAllOnline()
                ]);

                setCurrentUser(userData);
                setThematicData(thematicsData);

                if (popupToEdit && popupToEdit.id) {
                    const fullPopupData = await PopupApi.getById(popupToEdit.id);
                    setPopupData(fullPopupData);

                    setFormData({
                        thematic: String(fullPopupData.thematicId || ''),
                        titre: fullPopupData.title || '',
                        description: fullPopupData.descriptif || ''
                    });
                } else {
                    setFormData(initialFormData);
                }

                setError(null);
            } catch (error) {
                setError("Erreur lors du chargement des données");
                console.error("Erreur API:", error);
            } finally {
                setIsLoading(false);
            }
        };
        if (open) {
            fetchInitialData();
        } else {
            setFormData(initialFormData);
            setPopupData(null);
        }
        // eslint-disable-next-line
    }, [open, popupToEdit?.id]);

    const handleCloseWithReset = () => {
        setFormData(initialFormData);
        setPopupData(null);
        handleClose();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        try {
            const currentDate = new Date().toISOString();

            const popupPayload: Popup = {
                id: popupData?.id || popupToEdit?.id,
                title: formData.titre,
                descriptif: formData.description,
                thematicId: parseInt(formData.thematic),
                online: popupData ? popupData.online : popupToEdit ? popupToEdit.online : true,
                createdBy: popupData?.createdBy || popupToEdit?.createdBy || currentUser?.id,
                updatedAt: popupData || popupToEdit ? currentDate : null,
                updatedBy: popupData || popupToEdit ? currentUser?.id : null,
            };

            if (popupData || popupToEdit) {
                await PopupApi.update(popupPayload);
                showSuccess('Le popup a été mis à jour avec succès');
            } else {
                await PopupApi.createPopup(popupPayload);
                showSuccess('Le popup a été créé avec succès');
            }

            handleClose();
            onSuccess?.();

            setFormData(initialFormData);
            setPopupData(null);
        } catch (error) {
            showError(popupData || popupToEdit ?
                'Erreur lors de la mise à jour du popup' :
                'Erreur lors de la création du popup'
            );
            console.error("Erreur:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseWithReset}
            maxWidth="md"
            fullWidth
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {popupData || popupToEdit ? 'Modifier le popup' : 'Ajouter un nouveau popup'}
                </DialogTitle>
                <DialogContent>
                    {isLoading ? (
                        <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{pt: 2}}>
                            <AddPopupForm
                                thematicData={thematicData}
                                popupThematic={formData.thematic}
                                initialTitle={formData.titre}
                                initialDescription={formData.description}
                                handleThematicChange={(event) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        thematic: event.target.value
                                    }));
                                }}
                                handleInputChange={(event) => {
                                    const {name, value} = event.target;
                                    setFormData(prev => ({
                                        ...prev,
                                        [name]: value
                                    }));
                                }}
                                handleEditorChange={(content) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        description: content
                                    }));
                                }}
                                updateFormData={updateFormData}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseWithReset}>Annuler</Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoading || !!error || isSubmitting}
                    >
                        {isSubmitting ? 'Enregistrement...' : 'Enregistrer'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};