import React, { createContext, useState, useEffect, useContext, ReactNode, useCallback } from 'react';
import {
    Dialog,
    DialogContent,
    Button,
    Paper,
    Divider,
    Box,
    Fade,
    FormControlLabel,
    Switch,
    Link as MuiLink,
    Typography,
    Stack,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import { useAuth } from "./AuthContext";
import { LUFApi } from "../api/luf_api";
import { PPDApi } from "../api/ppd_api";
import { User } from "../interfaces/user_interface";
import { LUF } from "../interfaces/luf_interface";
import { PPD } from "../interfaces/ppd_interface";

interface AuthContextType {
    currentUser: User | null;
}

interface ConsentContextType {
    hasConsented: boolean;
    openConsentDialog: boolean;
    checkConsents: () => Promise<void>;
    lufOnlineInfo: LUF | null;
    ppdOnlineInfo: PPD | null;
    isLoading: boolean;
}

const ConsentContext = createContext<ConsentContextType | null>(null);

export const useConsent = () => {
    const context = useContext(ConsentContext);
    if (!context) {
        throw new Error('useConsent must be used within a ConsentProvider');
    }
    return context;
};

interface ConsentProviderProps {
    children: ReactNode;
}

export const ConsentProvider: React.FC<ConsentProviderProps> = ({ children }) => {
    const { currentUser } = useAuth() as AuthContextType;
    const [openConsentDialog, setOpenConsentDialog] = useState<boolean>(false);
    const [hasConsented, setHasConsented] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const [lufOnlineInfo, setLufOnlineInfo] = useState<LUF | null>(null);
    const [ppdOnlineInfo, setPpdOnlineInfo] = useState<PPD | null>(null);

    const [lufConsentSwitch, setLufConsentSwitch] = useState<boolean>(false);
    const [privacyConsentSwitch, setPrivacyConsentSwitch] = useState<boolean>(false);

    const [showLufSection, setShowLufSection] = useState<boolean>(true);
    const [showPpdSection, setShowPpdSection] = useState<boolean>(true);

    const resetState = useCallback(() => {
        setIsInitialized(false);
        setHasConsented(false);
        setLufOnlineInfo(null);
        setPpdOnlineInfo(null);
        setLufConsentSwitch(false);
        setPrivacyConsentSwitch(false);
        setShowLufSection(true);
        setShowPpdSection(true);
    }, []);

    const checkUserConsents = useCallback(async (lufInfo: LUF | null, ppdInfo: PPD | null): Promise<void> => {
        if (!currentUser || !currentUser.id) {
            setHasConsented(false);
            return;
        }

        if (currentUser.isAdmin) {
            setHasConsented(true);
            return;
        }

        try {
            let lufHasConsent = false;
            let ppdHasConsent = false;

            if (lufInfo?.id) {
                try {
                    const lufResponse = await LUFApi.getUserLufConsent(lufInfo.id, currentUser.id);
                    lufHasConsent = lufResponse?.hasConsent || false;
                    setLufConsentSwitch(lufHasConsent);
                } catch (error) {
                    console.error("Erreur lors de la vérification du consentement LUF:", error);
                }
            }

            if (ppdInfo?.id) {
                try {
                    const ppdResponse = await PPDApi.getUserPpdConsent(ppdInfo.id, currentUser.id);
                    ppdHasConsent = ppdResponse?.hasConsent || false;
                    setPrivacyConsentSwitch(ppdHasConsent);
                } catch (error) {
                    console.error("Erreur lors de la vérification du consentement PPD:", error);
                }
            }

            const lufNeedsConsent = !!(lufInfo?.id && !lufHasConsent);
            const ppdNeedsConsent = !!(ppdInfo?.id && !ppdHasConsent);

            setShowLufSection(lufNeedsConsent);
            setShowPpdSection(ppdNeedsConsent);

            const needsDialog = !!(lufNeedsConsent || ppdNeedsConsent);
            setOpenConsentDialog(needsDialog);
            setHasConsented(!needsDialog);
        } catch (error) {
            console.error("Erreur lors de la vérification des consentements:", error);
            setHasConsented(false);
            setOpenConsentDialog(true);
        }
    }, [currentUser]);

    const fetchOnlineDocuments = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        try {
            let lufInfo = null;
            let ppdInfo = null;

            try {
                lufInfo = await LUFApi.getOnlineLUF();
                setLufOnlineInfo(lufInfo);
            } catch (lufError) {
                console.error("Erreur lors du chargement de la LUF:", lufError);
            }

            try {
                ppdInfo = await PPDApi.getOnlinePPD();
                setPpdOnlineInfo(ppdInfo);
            } catch (ppdError) {
                console.error("Erreur lors du chargement de la PPD:", ppdError);
            }

            if (currentUser && !currentUser.isAdmin) {
                await checkUserConsents(lufInfo, ppdInfo);
            }
        } catch (error) {
            console.error("Erreur générale lors du chargement des documents:", error);
            setHasConsented(false);
        } finally {
            setIsLoading(false);
        }
    }, [currentUser, checkUserConsents]);

    const checkConsents = useCallback(async (): Promise<void> => {
        if (!currentUser) {
            setHasConsented(false);
            return;
        }

        if (currentUser.isAdmin) {
            setHasConsented(true);
            return;
        }

        if (!lufOnlineInfo || !ppdOnlineInfo) {
            await fetchOnlineDocuments();
        } else {
            await checkUserConsents(lufOnlineInfo, ppdOnlineInfo);
        }
    }, [currentUser, lufOnlineInfo, ppdOnlineInfo, fetchOnlineDocuments, checkUserConsents]);

    useEffect(() => {
        if (currentUser && !isInitialized) {
            if (currentUser.isAdmin) {
                setHasConsented(true);
                setIsLoading(false);
                setIsInitialized(true);
            } else {
                fetchOnlineDocuments().then(() => {
                    setIsInitialized(true);
                });
            }
        } else if (!currentUser) {
            resetState();
        }
    }, [currentUser, isInitialized, fetchOnlineDocuments, resetState]);

    const handleAcceptConsent = async (): Promise<void> => {
        try {
            if (!currentUser || !currentUser.id) return;

            const updates = [];

            if (showLufSection && lufOnlineInfo?.id) {
                if (!lufConsentSwitch) {
                    await handleRejectConsent();
                    return;
                }
                updates.push(LUFApi.updateUserLufConsent(lufOnlineInfo.id, currentUser.id, true));
            }

            if (showPpdSection && ppdOnlineInfo?.id) {
                if (!privacyConsentSwitch) {
                    await handleRejectConsent();
                    return;
                }
                updates.push(PPDApi.updateUserPpdConsent(ppdOnlineInfo.id, currentUser.id, true));
            }

            if (updates.length > 0) {
                await Promise.all(updates);
            }

            setHasConsented(true);
            setOpenConsentDialog(false);

            if (window.location.pathname === '/login') {
                window.location.pathname = '/';
            }
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des consentements:", error);
        }
    };

    const handleRejectConsent = async (): Promise<void> => {
        try {
            if (!currentUser || !currentUser.id) return;

            const updates = [];

            if (showLufSection && lufOnlineInfo?.id) {
                updates.push(LUFApi.updateUserLufConsent(lufOnlineInfo.id, currentUser.id, lufConsentSwitch));
            }

            if (showPpdSection && ppdOnlineInfo?.id) {
                updates.push(PPDApi.updateUserPpdConsent(ppdOnlineInfo.id, currentUser.id, privacyConsentSwitch));
            }

            if (updates.length > 0) {
                await Promise.all(updates);
            }

            setOpenConsentDialog(false);
            localStorage.removeItem('userToken');
            window.location.pathname = '/';
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des refus:", error);
            localStorage.removeItem('userToken');
            window.location.pathname = '/';
        }
    };

    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank');
        newWindow?.focus();
    };

    return (
        <ConsentContext.Provider value={{
            hasConsented,
            openConsentDialog,
            checkConsents,
            lufOnlineInfo,
            ppdOnlineInfo,
            isLoading
        }}>
            {children}

            <Dialog
                open={openConsentDialog}
                onClose={() => {}}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        overflow: 'hidden',
                        boxShadow: '0 10px 40px rgba(0, 0, 0, 0.15)'
                    }
                }}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 400 }}
            >
                <Box sx={{
                    bgcolor: 'primary.main',
                    py: 2.5,
                    px: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5
                }}>
                    <SecurityOutlinedIcon sx={{ color: 'white', fontSize: '2rem' }} />
                    <Typography variant="h6" sx={{ color: 'white', fontWeight: 400 }}>
                        Paramètres de confidentialité
                    </Typography>
                </Box>

                <DialogContent sx={{ p: 0 }}>
                    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 500, fontSize: '1rem' }}>
                            Mise à jour des conditions
                        </Typography>

                        <Typography variant="body1" sx={{ mb: 4, fontSize: '0.72rem' }}>
                            {showLufSection && showPpdSection
                                ? "Nos conditions d'utilisation et notre politique de confidentialité ont été mises à jour. Veuillez les accepter pour continuer à utiliser l'application."
                                : showLufSection
                                    ? "Notre licence d'utilisation a été mise à jour. Veuillez l'accepter pour continuer à utiliser l'application."
                                    : "Notre politique de confidentialité a été mise à jour. Veuillez l'accepter pour continuer à utiliser l'application."
                            }
                        </Typography>

                        <Stack spacing={2.5} sx={{ mb: 4 }}>
                            {showLufSection && (
                                <Paper
                                    elevation={0}
                                    sx={{
                                        p: 3,
                                        borderRadius: 2,
                                        border: `1px solid gray`,
                                        position: 'relative',
                                        transition: 'background-color 0.3s ease'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                                        <GavelOutlinedIcon
                                            sx={{
                                                mr: 1.5,
                                                color: lufConsentSwitch ? 'primary.main' : 'text.primary',
                                                mt: 0.3
                                            }}
                                        />
                                        <Box sx={{ flex: 1 }}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                                                    Licence Utilisateur Final (LUF)
                                                    <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>
                                                </Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={lufConsentSwitch}
                                                            onChange={(e) => setLufConsentSwitch(e.target.checked)}
                                                            color="primary"
                                                            sx={{ ml: 'auto' }}
                                                        />
                                                    }
                                                    label=""
                                                />
                                            </Box>

                                            <Typography variant="body2" sx={{ mb: 1, fontSize: '0.72rem' }}>
                                                Cette licence définit les termes d'utilisation de notre application.
                                                Votre acceptation est requise pour accéder aux fonctionnalités de
                                                l'application.
                                            </Typography>

                                            <MuiLink
                                                component="button"
                                                onClick={() => openInNewTab('/luf')}
                                                underline="hover"
                                                sx={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    fontSize: '0.85rem',
                                                    color: 'primary.main',
                                                    fontWeight: 500,
                                                    border: 'none',
                                                    background: 'none',
                                                    cursor: 'pointer',
                                                    padding: 0
                                                }}
                                            >
                                                Consulter la licence complète
                                                <ArrowForwardIcon sx={{ fontSize: '0.85rem', ml: 0.5 }} />
                                            </MuiLink>
                                        </Box>
                                    </Box>
                                </Paper>
                            )}

                            {showPpdSection && (
                                <Paper
                                    elevation={0}
                                    sx={{
                                        p: 3,
                                        borderRadius: 2,
                                        border: `1px solid gray`,
                                        position: 'relative',
                                        transition: 'background-color 0.3s ease'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                                        <LockOutlinedIcon
                                            sx={{
                                                mr: 1.5,
                                                color: privacyConsentSwitch ? 'primary.main' : 'text.primary',
                                                mt: 0.3
                                            }}
                                        />
                                        <Box sx={{ flex: 1 }}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                                                    Politique de confidentialité
                                                    <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>
                                                </Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={privacyConsentSwitch}
                                                            onChange={(e) => setPrivacyConsentSwitch(e.target.checked)}
                                                            color="primary"
                                                            sx={{ ml: 'auto' }}
                                                        />
                                                    }
                                                    label=""
                                                />
                                            </Box>

                                            <Typography variant="body2" sx={{ mb: 1, fontSize: '0.72rem' }}>
                                                Notre politique de confidentialité explique comment nous collectons,
                                                utilisons et protégeons
                                                vos données personnelles. Votre acceptation est requise pour utiliser
                                                l'application.
                                            </Typography>

                                            <MuiLink
                                                component="button"
                                                onClick={() => openInNewTab('/ppd')}
                                                underline="hover"
                                                sx={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    fontSize: '0.85rem',
                                                    color: 'primary.main',
                                                    fontWeight: 500,
                                                    border: 'none',
                                                    background: 'none',
                                                    cursor: 'pointer',
                                                    padding: 0
                                                }}
                                            >
                                                Consulter la politique de confidentialité
                                                <ArrowForwardIcon sx={{ fontSize: '0.85rem', ml: 0.5 }} />
                                            </MuiLink>
                                        </Box>
                                    </Box>
                                </Paper>
                            )}
                        </Stack>

                        <Typography variant="caption" sx={{ display: 'block', mb: 3, color: 'text.secondary' }}>
                            <Box component="span" sx={{ color: 'error.main', mr: 0.5 }}>*</Box>
                            Consentement requis pour utiliser l'application
                        </Typography>

                        <Divider sx={{ my: 3 }} />

                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                onClick={handleRejectConsent}
                                variant="outlined"
                                color="error"
                                startIcon={<CancelOutlinedIcon />}
                                size="small"
                                sx={{
                                    borderRadius: 2,
                                    py: 1,
                                    width: { xs: '100%', sm: 'auto' },
                                    order: { xs: 2, sm: 1 },
                                    textTransform: 'none'
                                }}
                            >
                                Refuser et se déconnecter
                            </Button>

                            <Button
                                onClick={handleAcceptConsent}
                                variant="contained"
                                color="primary"
                                endIcon={<ArrowForwardIcon />}
                                size="small"
                                sx={{
                                    borderRadius: 2,
                                    py: 1,
                                    px: 3,
                                    width: { xs: '100%', sm: 'auto' },
                                    order: { xs: 1, sm: 2 },
                                    textTransform: 'none'
                                }}
                            >
                                Confirmer mes choix
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </ConsentContext.Provider>
    );
};