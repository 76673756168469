import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tabs,
    Tab,
    CircularProgress,
    IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProjectApi } from "../../api/projects_api";
import { Project } from "../../interfaces/project_interface";
import IBSTable from "./components/IBSTable";
import INBTable from "./components/INBTable";
import Peuplement from "./components/Peuplement";

interface DiagnosticDetailsModalProps {
    open: boolean;
    onClose: () => void;
    projectId: number;
}

export const DiagnosticDetailsModal: React.FC<DiagnosticDetailsModalProps> = ({
                                                                                  open,
                                                                                  onClose,
                                                                                  projectId
                                                                              }) => {
    const [diagnostic, setDiagnostic] = useState<Project | null>(null);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);

    React.useEffect(() => {
        const fetchProject = async () => {
            if (open) {
                try {
                    const project = await ProjectApi.getProject(projectId);
                    setDiagnostic(project);
                    setLoading(false);
                } catch (error) {
                    console.error("Erreur lors du chargement du projet:", error);
                    setLoading(false);
                }
            }
        };

        fetchProject();
    }, [open, projectId]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p:6,
    };

    if (loading) {
        return (
            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle} display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            </Modal>
        );
    }

    if (!diagnostic?.diagnostic?.boisement) {
        return (
            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle} display="flex" justifyContent="center" alignItems="center">
                    <Typography color="text.secondary">Diagnostic non disponible</Typography>
                    <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Modal>
        );
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Accordion
                    defaultExpanded
                    disableGutters
                    sx={{
                        boxShadow: 'none',
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{
                            backgroundColor: "background.default",
                            '& .MuiAccordionSummary-content': {
                                justifyContent: 'center'
                            },
                            '& .Mui-expanded': {
                                margin: '0'
                            }
                        }}
                    >
                        <Typography variant="h5" component="h1">
                            Diagnostic boisement
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{p: 0}}>
                        <Box sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                variant="fullWidth"
                                sx={{
                                    width: '100%',
                                    '& .MuiTab-root': {
                                        fontWeight: 500,
                                        fontSize: '16px',
                                        minWidth: '200px',
                                    }
                                }}
                            >
                                <Tab label="IBS"/>
                                <Tab label="INB"/>
                            </Tabs>
                        </Box>

                        {selectedTab === 0 ? (
                            <Box sx={{p: 3}}>
                                {diagnostic.diagnostic.boisement.diagnosticsIBS ? (
                                    <IBSTable diagnosticsEssenceIBS={diagnostic.diagnostic.boisement.diagnosticsIBS}/>
                                ) : (
                                    <Typography color="text.secondary">
                                        Aucune essence IBS disponible
                                    </Typography>
                                )}
                            </Box>
                        ) : (
                            <Box sx={{p: 3}}>
                                {diagnostic.diagnostic.boisement.diagnosticsNonIBS ? (
                                    <INBTable
                                        diagnosticsEssenceNonIBS={diagnostic.diagnostic.boisement.diagnosticsNonIBS}/>
                                ) : (
                                    <Typography color="text.secondary">
                                        Aucune essence INB disponible
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    disableGutters
                    sx={{
                        boxShadow: 'none',
                        mt: 2,
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{
                            backgroundColor: "background.default",
                            '& .MuiAccordionSummary-content': {
                                justifyContent: 'center'
                            }
                        }}
                    >
                        <Typography variant="h5" component="h1">
                            Diagnostic peuplement sur pied
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {diagnostic.diagnostic.peuplement ? (
                            <Peuplement diagnosticsPeuplement={diagnostic.diagnostic.peuplement}/>
                        ) : (
                            <Typography color="text.secondary">
                                Aucun diagnostic peuplement disponible
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Modal>
    );
};
export default DiagnosticDetailsModal;