import React from 'react';
import {Box, Typography, Button, TextField, Card, Divider} from '@mui/material';
import {Add} from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {LUF} from "../../interfaces/luf_interface";
import {PPD} from "../../interfaces/ppd_interface";

interface DocumentCreationProps<T extends LUF | PPD> {
    currentDocument: T | null;
    setCurrentDocument: (document: T) => void;
    newVersion: string;
    setNewVersion: (version: string) => void;
    setShowConfirmation: (show: boolean) => void;
    setCurrentTab: (tab: number) => void;
    placeholder: {
        title: string;
        content: string;
    };
    disabled?: boolean;
}

function DocumentCreation<T extends LUF | PPD>({
                                                  currentDocument,
                                                  setCurrentDocument,
                                                  newVersion,
                                                  setNewVersion,
                                                  setShowConfirmation,
                                                  setCurrentTab,
                                                  placeholder,
                                                  disabled = false
                                              }: DocumentCreationProps<T>) {
    const quillModules = {
        toolbar: [
            [{'font': []}],
            [{'header': [1, 2, 3, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'align': []}],
            ['clean']
        ],
        clipboard: {
            matchVisual: false
        },
    };

    const handleSubmit = () => {
        setShowConfirmation(true);
    };

    return (
        <Card elevation={3} sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexShrink: 0
            }}>
                <Typography variant="subtitle1" sx={{
                    fontWeight: 'medium',
                    fontSize: '0.95rem'
                }}>
                    Créer une nouvelle version
                </Typography>
            </Box>

            <Divider sx={{ flexShrink: 0 }} />

            <Box sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                flexShrink: 0
            }}>
                <TextField
                    label="Version"
                    variant="outlined"
                    value={newVersion}
                    onChange={(e) => setNewVersion(e.target.value)}
                    placeholder="ex: 1.0.0"
                    size="small"
                    sx={{ width: '200px' }}
                    disabled={disabled}
                    InputProps={{
                        style: { fontSize: '0.9rem' }
                    }}
                    InputLabelProps={{
                        style: { fontSize: '0.9rem' },
                        shrink: true
                    }}
                />
                <TextField
                    label="Titre"
                    variant="outlined"
                    value={currentDocument?.title || ''}
                    onChange={(e) => currentDocument && setCurrentDocument({...currentDocument, title: e.target.value} as T)}
                    fullWidth
                    placeholder={placeholder.title}
                    size="small"
                    disabled={disabled}
                    InputProps={{
                        style: { fontSize: '0.9rem' }
                    }}
                    InputLabelProps={{
                        style: { fontSize: '0.9rem' },
                        shrink: true
                    }}
                />
            </Box>

            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                position: 'relative',
                px: 2,
                pb: 10,
                overflow: 'hidden',
                minHeight: 0
            }}>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    '& .quill': {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    },
                    '& .ql-toolbar.ql-snow': {
                        border: '1px solid #e0e0e0',
                        borderBottom: 'none',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        zIndex: 1,
                        backgroundColor: '#ffffff',
                        flexShrink: 0
                    },
                    '& .ql-container.ql-snow': {
                        flexGrow: 1,
                        border: '1px solid #e0e0e0',
                        borderTop: 'none',
                        borderBottomLeftRadius: '4px',
                        borderBottomRightRadius: '4px',
                        overflow: 'auto',
                        height: 'auto'
                    },
                    '& .ql-editor': {
                        minHeight: '100%',
                        fontSize: '0.875rem'
                    }
                }}>
                    <ReactQuill
                        theme="snow"
                        modules={quillModules}
                        value={currentDocument?.content || ''}
                        onChange={(content) => currentDocument && setCurrentDocument({...currentDocument, content} as T)}
                        placeholder={placeholder.content}
                        readOnly={disabled}
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    />
                </Box>

                <Box sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: 2,
                    backgroundColor: 'white',
                    borderTop: '1px solid #e0e0e0',
                    zIndex: 2
                }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        startIcon={<Add/>}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.8rem',
                            py: 0.5,
                        }}
                        size="small"
                        disabled={disabled}
                    >
                        Créer la nouvelle version
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}

export default DocumentCreation;