import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {Login} from '../../routes/authentification/login_route'
import {OpenIdCallback} from '../../routes/authentification/openId_callback_route'
import {PublicLuf} from "../../routes/luf/public_luf_route";
import PublicPpd from "../../routes/ppd/public_ppd_route";
import {DashboardMiddleWare} from '../Dashboard/Dashboard'
import ProtectedRoute from "../../providers/ProtectedRoute";
import {ConsentProvider} from "../../Contexts/ConsentProvider";

/**
 * Routeur principal de l'application
 */
export function Router(): React.ReactElement {
    return (
        <BrowserRouter>
            <Routes>

                <Route path="/login" element={<Login/>}/>
                <Route path="/openid_callback" element={<OpenIdCallback/>}/>
                <Route path="/luf" element={<PublicLuf/>}/>
                <Route path="/ppd" element={<PublicPpd/>}/>
                <Route path="/*" element={
                    <ConsentProvider>
                        <ProtectedRoute>
                            <DashboardMiddleWare/>
                        </ProtectedRoute>
                    </ConsentProvider>}/>
            </Routes>
        </BrowserRouter>
    )
}
