import React from 'react';
import DocumentList from '../../../components/common/DocumentList';
import { LUF } from '../../../interfaces/luf_interface';

interface LufListProps {
    lufs: LUF[];
    currentLufId?: number;
    onSelectLuf: (luf: LUF) => void;
}

const LufList: React.FC<LufListProps> = ({ lufs, currentLufId, onSelectLuf }) => {
    return (
        <DocumentList<LUF>
            items={lufs}
            currentItemId={currentLufId}
            onSelectItem={onSelectLuf}
            title="Liste des LUF"
            emptyMessage="Aucune LUF disponible"
        />
    );
};

export default LufList;