import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    List,
    ListItem,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import Icon from "@mdi/react";
import {mdiPencil, mdiDelete} from "@mdi/js";
import {PopupApi} from "../../api/popup_api";
import {useToast} from "../../hooks/useToast";
import {Popup} from "../../interfaces/popup_interface";
import {ThematicData} from "../../interfaces/thematicData_interface";
import {ViewPopup} from "./components/ViewPopup";
import {PaginationControls, PaginationState} from "../../components/Pagination/Pagination";
import {ThematicDataApi} from "../../api/thematic_data_api";
import Areyousure from "../../components/Popup/Areyousure/Areyousure";

interface PopupTableProps {
    onEdit: (popup: Popup) => void;
}

export interface PopupTableHandle {
    refreshData: () => void;
}

const ActionButtons: React.FC<{
    popup: Popup;
    onEdit: (popup: Popup) => void;
    onDelete: (popupId: number) => void;
}> = ({popup, onEdit, onDelete}) => (
    <List sx={{p: 0}}>
        <ListItem sx={{p: 0, my: 1}}>
            <Tooltip title="Éditer" placement="right">
                <Button
                    variant="outlined"
                    sx={{p: 0, mr: 1}}
                    onClick={() => onEdit(popup)}
                >
                    <Icon path={mdiPencil} size={1}/>
                </Button>
            </Tooltip>
        </ListItem>

        <ListItem sx={{p: 0, my: 1}}>
            <Tooltip title="Supprimer" placement="right">
                <Button
                    variant="outlined"
                    color="error"
                    sx={{p: 0}}
                    onClick={() => popup.id && onDelete(popup.id)}
                >
                    <Icon path={mdiDelete} size={1}/>
                </Button>
            </Tooltip>
        </ListItem>
    </List>
);

export const PopupTable = forwardRef<PopupTableHandle, PopupTableProps>(({onEdit}, ref) => {
    const [popups, setPopups] = useState<Popup[]>([]);
    const [selectedPopupId, setSelectedPopupId] = useState<number | null>(null);
    const [thematics, setThematics] = useState<ThematicData[]>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        page: 1,
        limit: 15,
        totalItems: 0,
        totalPages: 1
    });

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [popupToDelete, setPopupToDelete] = useState<number | null>(null);

    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {showSuccess, showError} = useToast();

    const fetchData = useCallback(async (page: number = 1) => {
        setIsLoading(true);
        try {
            const [popupsData, thematicsData] = await Promise.all([
                PopupApi.getAll(page),
                ThematicDataApi.getAllOnline()
            ]);
            setPopups(popupsData.data);
            setPagination(popupsData.pagination);
            setThematics(thematicsData);
        } catch (error) {
            showError('Erreur lors du chargement des données');
            console.error('Erreur:', error);
        } finally {
            setIsLoading(false);
        }
    }, [showError]);

    const handlePageChange = (newPage: number) => {
        fetchData(newPage);
    };

    const handleOpenViewModal = (popupId: number) => {
        setSelectedPopupId(popupId);
        setIsViewModalOpen(true);
    };

    const handleCloseViewModal = () => {
        setSelectedPopupId(null);
        setIsViewModalOpen(false);
    };


    const getThematicTitle = (thematicId: number) => {
        const thematic = thematics.find(t => t.id === thematicId);
        return thematic ? thematic.title : 'Non défini';
    };

    const handleToggleOnline = async (popup: Popup) => {
        try {
            const updatedPopup = {
                ...popup,
                online: !popup.online,
            };
            await PopupApi.update(updatedPopup);
            showSuccess('Statut mis à jour avec succès');
            await fetchData();
        } catch (error) {
            showError('Erreur lors de la mise à jour du statut');
            console.error('Erreur:', error);
        }
    };

    const handleOpenDeleteConfirmation = (popupId: number) => {
        setPopupToDelete(popupId);
        setShowDeleteConfirmation(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setPopupToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const deletePopup = async (popupId: number) => {
        if (!popupToDelete) return;
        await Promise.all([
            PopupApi.delete(popupToDelete),
            ThematicDataApi.getAllOnline()
        ]);
        showSuccess('Popup supprimé avec succès');
        await fetchData();
    };

    useImperativeHandle(ref, () => ({
        refreshData: fetchData
    }));

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <>
            <TableContainer component={Paper} sx={{my: 4}}>
                <Table>
                    <TableHead
                        sx={{
                            backdropFilter: 2,
                            backgroundColor: 'primary.main',
                        }}
                    >
                        <TableRow>
                            <TableCell sx={{color: '#ffffff'}} width="25%">Thématique</TableCell>
                            <TableCell sx={{color: '#ffffff'}} width="25%">Titre</TableCell>
                            <TableCell sx={{color: '#ffffff'}} width="12.5%">Popup</TableCell>
                            <TableCell sx={{color: '#ffffff'}} width="12.5%">Actif</TableCell>
                            <TableCell sx={{color: '#ffffff'}} width="12.5%">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {popups.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Typography>Aucun popup trouvé</Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            popups.map((popup) => (
                                <TableRow
                                    key={popup.id}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'background.default',
                                        },
                                    }}
                                >
                                    <TableCell>{getThematicTitle(popup.thematicId)}</TableCell>
                                    <TableCell>{popup.title}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => popup.id && handleOpenViewModal(popup.id)}
                                            sx={{
                                                width: 'auto',
                                                height: '48px',
                                                borderRadius: '20%',
                                            }}
                                        >
                                            voir
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={popup.online}
                                            onChange={() => handleToggleOnline(popup)}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ActionButtons
                                            popup={popup}
                                            onEdit={onEdit}
                                            onDelete={handleOpenDeleteConfirmation}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationControls
                currentPage={pagination.page}
                totalPages={pagination.totalPages}
                onPageChange={handlePageChange}
            />
            {showDeleteConfirmation && (
                <Areyousure
                    message="supprimer ce popup"
                    action={deletePopup}
                    reload={fetchData}
                    close={handleCloseDeleteConfirmation}
                />
            )}
            <ViewPopup
                open={isViewModalOpen}
                popupId={selectedPopupId!}
                handleClose={handleCloseViewModal}
            />
        </>
    );
});