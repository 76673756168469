import {Project} from "../../interfaces/project_interface";
import React from "react";
import {
    Box, Button, CircularProgress, List, ListItem, Pagination, PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Icon from "@mdi/react";
import {mdiDelete, mdiEyeOutline} from "@mdi/js";
import {Link} from "react-router-dom";
import Areyousure from "../../components/Popup/Areyousure/Areyousure";
import {ProjectApi, ProjectResponse} from "../../api/projects_api";
import AppHeader from "../../components/common/AppHeader";
import {SortableTableHeader} from "../../components/Table/SortableTableHeader";
import {ProjectsFilter, ProjectFilter} from "../projets/ProjectsFilter";

const COLUMN_WIDTHS = {
    name: '180px',        // Nom du projet
    forestName: '180px',  // Nom de la forêt
    commune: '140px',     // Commune
    timeCreate: '120px',  // Date de création
    timeUpdate: '140px',  // Dernière mise à jour
    isClosed: '80px',     // État
    archive: '80px',     // Archivé
    actions: '100px'      // Actions
};

const bodyCellStyle = {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap' as const,
    overflow: 'hidden' as const,
    textOverflow: 'ellipsis' as const
};

export interface ProjectsProps {
    current_user: number
}

interface ProjectState {
    project: Project[]
    action_user_id: number
    delete: boolean
    filteredProjects: Project[]
    isLoading: boolean
    sort?: string
    sortDir?: 'asc' | 'desc'
    pagination?: {
        page: number
        limit: number
        totalItems: number
        totalPages: number
    }
    currentFilters: ProjectFilter
}

export class Projects extends React.Component<ProjectsProps, ProjectState> {
    constructor(props: ProjectsProps) {
        super(props)
        this.state = {
            project: [],
            action_user_id: NaN,
            delete: false,
            filteredProjects: [],
            isLoading: true,
            sort: 'timeCreate',
            sortDir: 'desc',
            pagination: {
                page: 1,
                limit: 15,
                totalItems: 0,
                totalPages: 0
            },
            currentFilters: {}
        }
    }

    componentDidMount(): void {
        this.load().then()
    }

    async load() {
        this.setState({isLoading: true});
        try {
            const response = await ProjectApi.listUserProjects({
                ...this.state.currentFilters,
                page: this.state.pagination?.page,
                sort: this.state.sort,
                sortDir: this.state.sortDir
            });
            this.setState({
                project: response.data,
                pagination: response.pagination,
                isLoading: false
            })
        } catch (e) {
            console.error(e)
            alert("Echec du chargement des projets")
            this.setState({isLoading: false})
        }
    }

    handleSearch = (response: ProjectResponse, filterCriteria?: ProjectFilter) => {
        this.setState({
            project: response.data,
            pagination: response.pagination,
            isLoading: false,
            currentFilters: filterCriteria || this.state.currentFilters
        });
    }

    handlePageChange = async (page: number) => {
        this.setState({isLoading: true});
        try {
            const response = await ProjectApi.listUserProjects({
                ...this.state.currentFilters,
                page: page,
                sort: this.state.sort,
                sortDir: this.state.sortDir
            });

            this.setState({
                project: response.data,
                pagination: response.pagination,
                isLoading: false
            });
        } catch (e) {
            console.error(e);
            alert("Erreur lors du changement de page");
            this.setState({isLoading: false});
        }
    };

    handleSort = async (field: string, direction: 'asc' | 'desc') => {
        this.setState({
            sort: field,
            sortDir: direction,
            isLoading: true
        });
        try {
            const response = await ProjectApi.listUserProjects({
                ...this.state.currentFilters,
                page: this.state.pagination?.page,
                sort: field,
                sortDir: direction
            });

            this.setState({
                project: response.data,
                pagination: response.pagination,
                isLoading: false
            });
        } catch (e) {
            console.error(e);
            alert("Erreur lors du tri des projets");
            this.setState({isLoading: false});
        }
    };

    render() {
        return (
            <Box sx={{p: 5}}>
                <AppHeader title="Mes projets"/>
                <ProjectsFilter onSearch={this.handleSearch}/>
                <Box>
                    <TableContainer
                        component={Paper}
                        sx={{
                            overflowX: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                            marginTop: 5
                        }}
                    >
                        <Table
                            sx={{
                                tableLayout: 'fixed',
                                width: '100%',
                                borderCollapse: 'collapse'
                            }}
                            size="small"
                        >
                            <TableHead sx={{backdropFilter: 2, backgroundColor: 'primary.main'}}>
                                <TableRow>
                                    <SortableTableHeader
                                        label="Nom du projet"
                                        field="name"
                                        currentSort={this.state.sort}
                                        currentDirection={this.state.sortDir}
                                        onSort={this.handleSort}
                                        sx={{
                                            color: '#ffffff',
                                            width: COLUMN_WIDTHS.name,
                                            minWidth: COLUMN_WIDTHS.name,
                                            maxWidth: COLUMN_WIDTHS.name
                                        }}
                                    />
                                    <SortableTableHeader
                                        label="Nom de la forêt"
                                        field="forestName"
                                        currentSort={this.state.sort}
                                        currentDirection={this.state.sortDir}
                                        onSort={this.handleSort}
                                        sx={{
                                            color: '#ffffff',
                                            width: COLUMN_WIDTHS.forestName,
                                            minWidth: COLUMN_WIDTHS.forestName,
                                            maxWidth: COLUMN_WIDTHS.forestName
                                        }}
                                    />
                                    <SortableTableHeader
                                        label="Commune"
                                        field="commune"
                                        currentSort={this.state.sort}
                                        currentDirection={this.state.sortDir}
                                        onSort={this.handleSort}
                                        sx={{
                                            color: '#ffffff',
                                            width: COLUMN_WIDTHS.commune,
                                            minWidth: COLUMN_WIDTHS.commune,
                                            maxWidth: COLUMN_WIDTHS.commune
                                        }}
                                    />
                                    <SortableTableHeader
                                        label="Date de création"
                                        field="timeCreate"
                                        currentSort={this.state.sort}
                                        currentDirection={this.state.sortDir}
                                        onSort={this.handleSort}
                                        sx={{
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            width: COLUMN_WIDTHS.timeCreate,
                                            minWidth: COLUMN_WIDTHS.timeCreate,
                                            maxWidth: COLUMN_WIDTHS.timeCreate
                                        }}
                                    />
                                    <SortableTableHeader
                                        label="Dernière mise à jour"
                                        field="timeUpdate"
                                        currentSort={this.state.sort}
                                        currentDirection={this.state.sortDir}
                                        onSort={this.handleSort}
                                        sx={{
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            width: COLUMN_WIDTHS.timeUpdate,
                                            minWidth: COLUMN_WIDTHS.timeUpdate,
                                            maxWidth: COLUMN_WIDTHS.timeUpdate
                                        }}
                                    />
                                    <SortableTableHeader
                                        label="État"
                                        field="isClosed"
                                        currentSort={this.state.sort}
                                        currentDirection={this.state.sortDir}
                                        onSort={this.handleSort}
                                        sx={{
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            width: COLUMN_WIDTHS.isClosed,
                                            minWidth: COLUMN_WIDTHS.isClosed,
                                            maxWidth: COLUMN_WIDTHS.isClosed
                                        }}
                                    />
                                    <SortableTableHeader
                                        label="Archivé"
                                        field="archive"
                                        currentSort={this.state.sort}
                                        currentDirection={this.state.sortDir}
                                        onSort={this.handleSort}
                                        sx={{
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            width: COLUMN_WIDTHS.archive,
                                            minWidth: COLUMN_WIDTHS.archive,
                                            maxWidth: COLUMN_WIDTHS.archive
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            width: COLUMN_WIDTHS.actions,
                                            minWidth: COLUMN_WIDTHS.actions,
                                            maxWidth: COLUMN_WIDTHS.actions
                                        }}
                                    >
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.isLoading ? (
                                    <TableRow>
                                        <TableCell sx={{py: 4}} colSpan={7} align="center">
                                            <CircularProgress/>
                                        </TableCell>
                                    </TableRow>
                                ) : !(this.state.project.length) ? (
                                    <TableRow>
                                        <TableCell sx={{py: 2}} colSpan={7}>
                                            Aucun projet trouvé !
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.project.map((project: any) => (
                                        <TableRow
                                            key={project.id}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: 'background.default',
                                                },
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                    ...bodyCellStyle,
                                                    py: 2,
                                                    width: COLUMN_WIDTHS.name,
                                                    minWidth: COLUMN_WIDTHS.name,
                                                    maxWidth: COLUMN_WIDTHS.name
                                                }}
                                            >
                                                {project.data.name}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...bodyCellStyle,
                                                    py: 2,
                                                    width: COLUMN_WIDTHS.forestName,
                                                    minWidth: COLUMN_WIDTHS.forestName,
                                                    maxWidth: COLUMN_WIDTHS.forestName
                                                }}
                                            >
                                                {project.data.forestName}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...bodyCellStyle,
                                                    py: 2,
                                                    width: COLUMN_WIDTHS.commune,
                                                    minWidth: COLUMN_WIDTHS.commune,
                                                    maxWidth: COLUMN_WIDTHS.commune
                                                }}
                                            >
                                                {project.data.commune === 'null' ? 'Non renseignée' : project.data.commune}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...bodyCellStyle,
                                                    py: 0,
                                                    textAlign: 'center',
                                                    width: COLUMN_WIDTHS.timeCreate,
                                                    minWidth: COLUMN_WIDTHS.timeCreate,
                                                    maxWidth: COLUMN_WIDTHS.timeCreate
                                                }}
                                            >
                                                {project.timeCreate && new Date(project.timeCreate * 1000).getTime() > 86400000
                                                    ? new Date(project.timeCreate * 1000).toLocaleDateString()
                                                    : ""}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...bodyCellStyle,
                                                    py: 0,
                                                    textAlign: 'center',
                                                    width: COLUMN_WIDTHS.timeUpdate,
                                                    minWidth: COLUMN_WIDTHS.timeUpdate,
                                                    maxWidth: COLUMN_WIDTHS.timeUpdate
                                                }}
                                            >
                                                {project.timeUpdate && new Date(project.timeUpdate * 1000).getTime() > 86400000
                                                    ? new Date(project.timeUpdate * 1000).toLocaleDateString()
                                                    : ""}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...bodyCellStyle,
                                                    py: 2,
                                                    textAlign: 'center',
                                                    width: COLUMN_WIDTHS.isClosed,
                                                    minWidth: COLUMN_WIDTHS.isClosed,
                                                    maxWidth: COLUMN_WIDTHS.isClosed
                                                }}
                                            >
                                                {project.data.isClosed ? 'Fermé' : 'Ouvert'}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...bodyCellStyle,
                                                    py: 2,
                                                    textAlign: 'center',
                                                    width: COLUMN_WIDTHS.archive,
                                                    minWidth: COLUMN_WIDTHS.archive,
                                                    maxWidth: COLUMN_WIDTHS.archive
                                                }}
                                            >
                                                {project.archive ? 'Oui' : 'Non'}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    py: 0,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: COLUMN_WIDTHS.actions,
                                                    minWidth: COLUMN_WIDTHS.actions,
                                                    maxWidth: COLUMN_WIDTHS.actions
                                                }}
                                            >
                                                <Box>
                                                    <List>
                                                        <ListItem sx={{p: 0, my: 1}}>
                                                            <Tooltip
                                                                title="Supprimer"
                                                                placement="right"
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            action_user_id: project.id,
                                                                            delete: true,
                                                                        })
                                                                    }}
                                                                    sx={{p: 0, px: 1}}
                                                                    color="error"
                                                                >
                                                                    <Icon path={mdiDelete} />
                                                                </Button>
                                                            </Tooltip>
                                                        </ListItem>
                                                        <ListItem sx={{p: 0, my: 1}}>
                                                            <Link
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                to={`${project.id}`}
                                                            >
                                                                <Tooltip
                                                                    title="Détails"
                                                                    placement="right"
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        sx={{p: 0}}
                                                                    >
                                                                        <Icon path={mdiEyeOutline} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Link>
                                                        </ListItem>
                                                        <ListItem sx={{p: 0}}>
                                                            <Link to={`${project.id}/pdf`}>
                                                                <Tooltip title="PDF" placement="right">
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        sx={{
                                                                            textTransform: 'none',
                                                                            p: 0
                                                                        }}
                                                                    >
                                                                        PDF
                                                                    </Button>
                                                                </Tooltip>
                                                            </Link>
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            </TableCell>
                                        </TableRow>)))}
                            </TableBody>
                        </Table>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 2,
                            backgroundColor: 'background.paper',
                            position: 'sticky',
                            bottom: 0,
                            zIndex: 1
                        }}>
                            <Pagination
                                count={this.state.pagination?.totalPages || 1}
                                page={this.state.pagination?.page || 1}
                                onChange={(event, page) => this.handlePageChange(page)}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{
                                            previous: ArrowBackIcon,
                                            next: ArrowForwardIcon
                                        }}
                                        {...item}
                                    />
                                )}
                            />
                        </Box>
                    </TableContainer>
                    {this.state.delete ? (
                        <Areyousure
                            message="supprimer un projet"
                            action={async () => {
                                await ProjectApi.deleteProjetUser(this.state.action_user_id)
                            }}
                            close={() => {
                                this.setState({delete: false})
                            }}
                            reload={() => this.load()}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        )
    }
}