import {Box, CircularProgress} from '@mui/material'
import React from 'react'
import {UserProjects} from './user_projects'
import {ProjectApi} from '../../api/projects_api'
import {Project} from '../../interfaces/project_interface'
import {User} from '../../interfaces/user_interface'
import {UserApi} from '../../api/users_api'
import {AllProjects} from './all_projects'
import AppHeader from "../../components/common/AppHeader";

export interface ExportProps {
}

interface ExportState {
    userProjects: {
        projects: Project[],
        pagination?: {
            page: number,
            limit: number,
            totalItems: number,
            totalPages: number
        }
    }
    adminProjects?: {
        projects: Project[],
        pagination?: {
            page: number,
            limit: number,
            totalItems: number,
            totalPages: number
        }
    }
    user?: User
    loading: boolean
}

export class Export extends React.Component<ExportProps, ExportState> {
    constructor(props: ExportProps) {
        super(props)
        this.state = {
            userProjects: {
                projects: []
            },
            adminProjects: {
                projects: []
            },
            loading: true
        }
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            this.setState({ loading: true });
            await this.getRoute();
            this.setState({ loading: false });
        } catch (e) {
            this.setState({ loading: false });
            throw new Error('Echec du chargement du composant Export')
        }
    }

    async getRoute() {
        try {
            const userProjectsResponse = await ProjectApi.listUserProjects()
            const user = await UserApi.getInfoCurrentUser()

            this.setState({
                userProjects: {
                    projects: userProjectsResponse.data,
                    pagination: userProjectsResponse.pagination
                },
                user: user,
            })

            if (user.isAdmin) {
                const adminProjectsResponse = await ProjectApi.allProjects()
                this.setState({
                    adminProjects: {
                        projects: adminProjectsResponse.data,
                        pagination: adminProjectsResponse.pagination
                    }
                })
            }
        } catch (e) {
            throw new Error(
                "échec du téléchargement de la liste des projets de l'utilisateur"
            )
        }
    }

    render() {
        const { loading } = this.state;

        if (loading) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}
                >
                    <CircularProgress />
                </Box>
            );
        }

        return (
            <Box sx={{p: 5}}>
                <AppHeader title="Export des Projets" />
                <UserProjects
                    projects={this.state.userProjects.projects}
                    pagination={this.state.userProjects.pagination}
                />
                {this.state.user?.isAdmin ? (
                    <AllProjects
                        projects={this.state.adminProjects!.projects}
                        pagination={this.state.adminProjects!.pagination}
                    />
                ) : (
                    <></>
                )}
            </Box>
        )
    }
}