import React from 'react';
import {Box, Pagination, PaginationItem} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export interface PaginationState {
    page: number;
    limit: number;
    totalItems: number;
    totalPages: number;
}

interface PaginationControlsProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

export const PaginationControls: React.FC<PaginationControlsProps> = ({
                                                                          currentPage,
                                                                          totalPages,
                                                                          onPageChange,
                                                                      }) => {
    if (totalPages <= 1) return null;

    const handleChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        onPageChange(page);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '20px 0',
            }}
        >
            <Pagination
                page={currentPage}
                count={totalPages}
                onChange={handleChange}
                color="primary"
                size="large"
                renderItem={(item) => (
                    <PaginationItem
                        slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon
                        }}
                        {...item}
                    />
                )}
            />
        </Box>
    );
};
