import React from 'react';
import {Dialog, DialogContent, DialogContentText, DialogActions, Button, Typography, CircularProgress} from '@mui/material';
import {LUF} from "../../interfaces/luf_interface";
import {PPD} from "../../interfaces/ppd_interface";

interface DocumentConfirmationDialogsProps<T extends LUF | PPD> {
    showConfirmation: boolean;
    showOnlineConfirmation: boolean;
    showDeleteConfirmation: boolean;
    setShowConfirmation: (show: boolean) => void;
    setShowOnlineConfirmation: (show: boolean) => void;
    setShowDeleteConfirmation: (show: boolean) => void;
    currentDocument: T | null;
    creating: boolean;
    createDocument: () => Promise<void>;
    updateDocument: () => Promise<void>;
    setDocumentOnline: () => Promise<void>;
    deleteDocument: () => Promise<void>;
    documentType: string;
    disabled?: boolean;
}

function DocumentConfirmationDialogs<T extends LUF | PPD>({
                                                              showConfirmation,
                                                              showOnlineConfirmation,
                                                              showDeleteConfirmation,
                                                              setShowConfirmation,
                                                              setShowOnlineConfirmation,
                                                              setShowDeleteConfirmation,
                                                              currentDocument,
                                                              creating,
                                                              createDocument,
                                                              updateDocument,
                                                              setDocumentOnline,
                                                              deleteDocument,
                                                              documentType,
                                                              disabled = false
                                                          }: DocumentConfirmationDialogsProps<T>) {
    return (
        <>
            <Dialog
                open={showConfirmation}
                onClose={() => !disabled && setShowConfirmation(false)}
                PaperProps={{
                    sx: { borderRadius: 1, maxWidth: '450px' }
                }}
            >
                <DialogContent>
                    <DialogContentText sx={{ fontSize: '0.9rem' }}>
                        {creating
                            ? `Êtes-vous sûr de vouloir créer cette nouvelle version de ${documentType} ?`
                            : 'Êtes-vous sûr de vouloir enregistrer les modifications ?'
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button
                        onClick={() => setShowConfirmation(false)}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.85rem'
                        }}
                        disabled={disabled}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={creating ? createDocument : updateDocument}
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.85rem',
                            backgroundColor: '#2b603e',
                            '&:hover': {
                                backgroundColor: '#1e4a2e'
                            }
                        }}
                        disabled={disabled}
                    >
                        {disabled ? (
                            <CircularProgress size={20} sx={{ color: 'white' }} />
                        ) : (
                            'Confirmer'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showOnlineConfirmation}
                onClose={() => !disabled && setShowOnlineConfirmation(false)}
                PaperProps={{
                    sx: { borderRadius: 1, maxWidth: '450px' }
                }}
            >
                <DialogContent>
                    <DialogContentText sx={{ fontSize: '0.9rem' }}>
                        Êtes-vous sûr de vouloir mettre cette version en ligne ?
                        Cette action désactivera la version actuellement en ligne.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button
                        onClick={() => setShowOnlineConfirmation(false)}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.85rem'
                        }}
                        disabled={disabled}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={setDocumentOnline}
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.85rem',
                            backgroundColor: '#2b603e',
                            '&:hover': {
                                backgroundColor: '#1e4a2e'
                            }
                        }}
                        disabled={disabled}
                    >
                        {disabled ? (
                            <CircularProgress size={20} sx={{ color: 'white' }} />
                        ) : (
                            'Mettre en ligne'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showDeleteConfirmation}
                onClose={() => !disabled && setShowDeleteConfirmation(false)}
                PaperProps={{
                    sx: { borderRadius: 1, maxWidth: '450px' }
                }}
            >
                <DialogContent>
                    <DialogContentText sx={{ fontSize: '0.9rem' }}>
                        Êtes-vous sûr de vouloir supprimer définitivement cette version de {documentType} ?
                        {currentDocument?.online && (
                            <Typography color="error" sx={{ mt: 1.5, fontWeight: 'bold', fontSize: '0.9rem' }}>
                                Attention : Cette version est actuellement en ligne !
                            </Typography>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button
                        onClick={() => setShowDeleteConfirmation(false)}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.85rem'
                        }}
                        disabled={disabled}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={deleteDocument}
                        variant="contained"
                        color="error"
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.85rem'
                        }}
                        disabled={disabled}
                    >
                        {disabled ? (
                            <CircularProgress size={20} sx={{ color: 'white' }} />
                        ) : (
                            'Supprimer'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DocumentConfirmationDialogs;