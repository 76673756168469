import React from "react";
import {Link, useParams} from "react-router-dom";
import {
    ListeEssences,
    ProblemeSanitaire,
    Project
} from "../../interfaces/project_interface";
import {ProjectApi} from "../../api/projects_api";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Button, Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {ReactComponent as IconDeperis} from "../../assets/images/ic_deperissement.svg";
import {ReactComponent as IconGeneralInfo} from "../../assets/images/ic_renseignements_projet.svg";
import {ReactComponent as IconSanitary} from "../../assets/images/ic_observations_sanitaires.svg";
import {ReactComponent as IconPedology} from "../../assets/images/ic_description_pedologique.svg";
import {ReactComponent as IconSylvicole} from "../../assets/images/ic_description_sylvicole.svg";
import {ReactComponent as IconTopographic} from "../../assets/images/ic_contexte_topographique.svg";
import {ReactComponent as IconClimatic} from "../../assets/images/ic_donnees_climatiques.svg";
import {SlopeUnitMapping} from "../../enums/SlopeUnit";
import {ConfinementUnitMapping} from "../../enums/ConfinementUnit";
import {TopographicPositionMapping} from "../../enums/TopographicPosition";
import {WaterSupplyMapping} from "../../enums/WaterSupply";
import {ReasonStopAugerMapping} from "../../enums/ReasonStopAuger";
import {CompactnessMapping} from "../../enums/CompactnessValues";
import {HumusMapping} from "../../enums/Humus";
import {PropDeperisTreeMapping} from "../../enums/PropDeperisTree";
import {ResilienceMapping} from "../../enums/Resilience";
import {MajorIssueMapping} from "../../enums/MajorIssue";
import {SeverityEnumMapping} from "../../enums/Severity";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getSeverityPercentageLabel} from "../../utils/sanitaryLabels";
import {ClimateData} from "../../interfaces/climateData_interface";
import {TextureEnum, TextureKey} from "../../enums/Texture";
import DeperissementDiagram from "./components/DeperissementDiagram";
import AppHeader from "../../components/common/AppHeader";
import DiagnosticDetailsModal from "./diagnosticDetailsModal";
import {InfoRow} from "../../components/common/InfoRow";


export function ViewProjectMiddleWare(): React.ReactElement {
    return <ViewProject params={useParams()}/>
}

export interface ViewProjectProps {
    params?: any
}

interface ViewProjectState {
    project: Project;
    climateData: ClimateData | null;
    isModalOpen: boolean;
}

export class ViewProject extends React.Component<ViewProjectProps, ViewProjectState> {
    constructor(props: ViewProjectProps) {
        super(props)
        this.state = {
            project: {
                id: -1,
                userId: -1,
                data: {
                    name: '',
                    lat: 0,
                    lon: 0,
                    commune: '',
                    comment: '',
                    date: '',
                    creationDate: '',
                    operator: '',
                    forestName: '',
                    pedology: {
                        causeArretTariere: '',
                        comment: '',
                        compactness: '',
                        groundType: '',
                        horizons: [],
                        humus: '',
                        ph20: 0,
                        phMeasured: false,
                        rockType: '',
                        ucs: 0,
                        usefulReserve: 0,
                        usefulReserve200: 0
                    },
                    dieback: {
                        comment: '',
                        environmentFrequency: '',
                        environmentIntensity: '',
                        hasMandatoryComplete: '',
                        parcelFrequency: '',
                        parcelIntensity: '',
                        tree: []
                    },
                    populationCharacteristics: {
                        woodSpecies: '',
                        populationType: '',
                        recentInterventions: '',
                        averageDiameter: '',
                        averageDiameterMeasured: false,
                        dominantHeight: 0,
                        dominantHeightMeasured: false,
                        basalArea: 0,
                        basalAreaMesured: false,
                        age: 0,
                        ageMeasured: 0,
                        comment: ''
                    },
                    sanitaryIssues: {
                        guiSeverity: '',
                        guiAffectedStems: '',
                        defoliateurSeverity: '',
                        defoliateurAffectedStems: '',
                        scolyteSeverity: '',
                        scolyteAffectedStems: '',
                        cynipsSeverity: '',
                        cynipsAffectedStems: '',
                        otherInsectsSeverity: '',
                        otherInsectsAffectedStems: '',
                        chancreSeverity: '',
                        chancreAffectedStems: '',
                        encreSeverity: '',
                        encreAffectedStems: '',
                        oidiumSeverity: '',
                        oidiumAffectedStems: '',
                        otherFungiSeverity: '',
                        otherFungiAffectedStems: '',
                        decolorationSeverity: '',
                        decolorationAffectedStems: '',
                        necrosesSeverity: '',
                        necrosesAffectedStems: '',
                        comment: ''
                    },
                    stationaryContext: {
                        altitude: 0,
                        exposureUnit: '',
                        exposureValue: 0,
                        slopeUnit: '',
                        slopeValue: 0,
                        containmentUnit: '',
                        containmentValue: 0,
                        topographicPosition: '',
                        tpi: 0,
                        watterSupply: '',
                        rockyOutcrops: 0,
                        terracements: false,
                        comment: ''
                    }
                },
                timeCreate: 0,
                timeUpdate: 0,
                archive: false,
                shareWithCnpf: false,
                isClosed: false
            },
            climateData: null,
            isModalOpen: false,
        }
    }

    openProjectModal = () => {
        this.setState({isModalOpen: true});
    }

    closeProjectModal = () => {
        this.setState({isModalOpen: false});
    }

    componentDidMount(): void {
        this.load().then()
    }

    async load() {
        try {
            if (this.props.params) {
                const params = this.props.params['*'].split('/')
                const id = params[1]
                try {
                    const project = await ProjectApi.getProject(id);
                    this.setState({
                        project: {
                            id: project.id,
                            userId: project.userId,
                            data: project.data,
                            timeCreate: project.timeCreate,
                            timeUpdate: project.timeUpdate,
                            archive: project.archive,
                            shareWithCnpf: project.shareWithCnpf,
                            isClosed: project.isClosed
                        }
                    })
                } catch (projectError) {
                    console.error("Erreur lors du chargement du projet:", projectError)
                    alert("Échec du chargement des données du projet")
                    return
                }

                try {
                    const climateData = await ProjectApi.getClimateData(id);
                    if (climateData) {
                        this.setState({climateData});
                    }
                } catch (climateError) {
                    console.error("Erreur données climatiques:", climateError);
                }
            }
        } catch (e) {
            console.error("Erreur générale:", e)
            alert("Une erreur est survenue lors du chargement des données")
        }
    }


    render() {
        return (
            <Box sx={{p: 5}}>
                <AppHeader title={`Détails du projet ${this.state.project.data.name}`}/>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Accordion defaultExpanded={false} sx={{
                            width: '100%',
                            mb: 3,
                            p: 2,
                            pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="renseignements-projet-content"
                                id="renseignements-projet-header"
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: {xs: 0, sm: 1}
                                }}>
                                    <IconGeneralInfo
                                        style={{
                                            width: 100,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            mx: {xs: 2, sm: 4, md: 7},
                                            fontSize: {xs: '1.1rem', sm: '1.3rem', md: '1.5rem'},
                                            color: 'primary.main',
                                        }}
                                    >
                                        Renseignements projet
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{p: 3}}>
                                    <InfoRow label="Opérateur" value={this.state.project.data.operator}/>
                                    <InfoRow label="Forêt" value={this.state.project.data.forestName}/>
                                    <InfoRow label="Commune"
                                             value={this.state.project.data.commune === 'null' ? 'Non renseignée' : this.state.project.data.commune}
                                    />
                                    <InfoRow label="Date de création"
                                             value={new Date(this.state.project.timeCreate * 1000).toLocaleDateString()}/>
                                    <InfoRow label="Date de mise à jour"
                                             value={new Date(this.state.project.timeUpdate * 1000).toLocaleDateString()}/>
                                    <InfoRow label="Latitude" value={this.state.project.data.lat}/>
                                    <InfoRow label="Longitude" value={this.state.project.data.lon}/>
                                    <InfoRow label="État du projet"
                                             value={this.state.project.isClosed ? 'Fermé' : 'Ouvert'}/>
                                    <InfoRow label="Archivage"
                                             value={this.state.project.archive ? 'Archivé' : 'Non archivé'}/>
                                    <InfoRow label="Projet partagé avec le CNPF"
                                             value={this.state.project.shareWithCnpf ? 'Oui' : 'Non'}/>
                                    <InfoRow label="Commentaire" value={this.state.project.data.comment}/>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Accordion defaultExpanded={false} sx={{
                            width: '100%',
                            mb: 3,
                            p: 2,
                            pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="contexte-topographique-content"
                                id="contexte-topographique-header"
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: {xs: 0, sm: 1}
                                }}>
                                    <IconTopographic
                                        style={{
                                            width: 100,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(14%) saturate(2794%) hue-rotate(191deg) brightness(93%) contrast(98%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: 'contexteTopo.main',
                                            mx: {xs: 2, sm: 4, md: 7},
                                            fontSize: {xs: '1.1rem', sm: '1.3rem', md: '1.5rem'},
                                        }}
                                    >
                                        Contexte topographique
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{p: 3}}>
                                    <InfoRow
                                        label="Altitude"
                                        value={this.state.project.data.stationaryContext.altitude}
                                        unit="m"
                                    />
                                    <InfoRow
                                        label="Exposition"
                                        value={this.state.project.data.stationaryContext.exposureValue || 'Aucune'}
                                        unit={this.state.project.data.stationaryContext.exposureValue && this.state.project.data.stationaryContext.exposureUnit
                                            ? this.state.project.data.stationaryContext.exposureUnit
                                            : ''}
                                    />
                                    <InfoRow
                                        label="Pente"
                                        value={this.state.project.data.stationaryContext.slopeValue}
                                        unit={SlopeUnitMapping[this.state.project.data.stationaryContext.slopeUnit as string]}
                                    />
                                    <InfoRow
                                        label="Confinement"
                                        value={this.state.project.data.stationaryContext.containmentValue}
                                        unit={ConfinementUnitMapping[this.state.project.data.stationaryContext.containmentUnit as string]}
                                    />
                                    <InfoRow
                                        label="Position topographique"
                                        value={TopographicPositionMapping[this.state.project.data.stationaryContext.topographicPosition as string]}
                                    />
                                    <InfoRow
                                        label="TPI"
                                        value={this.state.project.data.stationaryContext.tpi}
                                    />
                                    <InfoRow
                                        label="Alimentation en eau"
                                        value={WaterSupplyMapping[this.state.project.data.stationaryContext.watterSupply as string]}
                                    />
                                    <InfoRow
                                        label="Affleurements rocheux"
                                        value={this.state.project.data.stationaryContext.rockyOutcrops}
                                        unit='  %'
                                    />
                                    <InfoRow
                                        label="Présence de terrasse"
                                        value={this.state.project.data.stationaryContext.terracements ? 'Oui' : 'Non'}
                                    />
                                    <InfoRow
                                        label="Commentaire"
                                        value={this.state.project.data.stationaryContext.comment}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Accordion defaultExpanded={false} sx={{
                            width: '100%',
                            mb: 3,
                            p: 2,
                            pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="description-sylvicole-content"
                                id="description-sylvicole-header"
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: {xs: 0, sm: 1}
                                }}>
                                    <IconSylvicole
                                        style={{
                                            width: 100,
                                            height: 'auto',
                                            filter: 'invert(37%) sepia(68%) saturate(424%) hue-rotate(66deg) brightness(94%) contrast(90%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            mx: {xs: 2, sm: 4, md: 7},
                                            fontSize: {xs: '1.1rem', sm: '1.3rem', md: '1.5rem'},
                                            color: 'descSylvicole.main',
                                        }}
                                    >
                                        Description sylvicole
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{p: 3}}>
                                    <InfoRow
                                        label="Essence"
                                        value={
                                            this.state.project.data.populationCharacteristics?.woodSpecies
                                                ?.replace(/_/g, ' ')
                                                ?.replace(/^./, char => char.toUpperCase()) ?? ''
                                        }
                                    />
                                    <InfoRow
                                        label="Type de peuplement"
                                        value={
                                            this.state.project.data.populationCharacteristics?.populationType
                                                ?.replace(/_/g, ' ')
                                                ?.replace(/^./, char => char.toUpperCase()) ?? ''
                                        }
                                    />
                                    <InfoRow
                                        label="Intervention récente"
                                        value={
                                            this.state.project.data.populationCharacteristics?.recentInterventions
                                                ?.replace(/_/g, ' ')
                                                ?.replace(/^./, char => char.toUpperCase()) ?? ''
                                        }
                                    />
                                    <InfoRow
                                        label="Diamètre moyen"
                                        value={this.state.project.data.populationCharacteristics.averageDiameter}
                                        unit={' cm'}
                                    />
                                    <InfoRow
                                        label="Diamètre moyen mesurée"
                                        value={this.state.project.data.populationCharacteristics.averageDiameterMeasured ? 'Oui' : 'Non'}
                                    />
                                    <InfoRow
                                        label="Hauteur dominante"
                                        value={this.state.project.data.populationCharacteristics.dominantHeight}
                                        unit={' m'}
                                    />
                                    <InfoRow
                                        label="Hauteur dominante mesurée"
                                        value={this.state.project.data.populationCharacteristics.dominantHeightMeasured ? 'Oui' : 'Non'}
                                    />
                                    <InfoRow
                                        label="Surface Terrière"
                                        value={this.state.project.data.populationCharacteristics.basalArea}
                                        unit={' m²/ha'}
                                    />
                                    <InfoRow
                                        label="Surface Terrière mesurée"
                                        value={this.state.project.data.populationCharacteristics.basalAreaMesured ? 'Oui' : 'Non'}
                                    />
                                    <InfoRow
                                        label="Age"
                                        value={this.state.project.data.populationCharacteristics.age}
                                        unit={' ans'}
                                    />
                                    <InfoRow
                                        label="Age connu"
                                        value={this.state.project.data.populationCharacteristics.ageMeasured ? 'Oui' : 'Non'}
                                    />

                                    {this.state.project.data.populationCharacteristics?.listeEssences ? (
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead sx={{backdropFilter: 2, backgroundColor: 'secondary.main'}}>
                                                    <TableRow>
                                                        <TableCell sx={{color: "#ffffff"}}>Essence</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>Diamètre moyen
                                                            (cm)</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>Hauteur dominante
                                                            (m)</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>Age (ans)</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>G (m²/ha)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.project.data.populationCharacteristics?.listeEssences.map((essence: ListeEssences) => (
                                                        <TableRow
                                                            key={essence.id}
                                                        >
                                                            <TableCell sx={{py: 2}}>
                                                                {essence.essenceLabel}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {essence.diametreMoyen}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {essence.hauteurDominante}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {essence.ageMoyen}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {essence.surfaceTerriere}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : (
                                        <Typography
                                            sx={{fontSize: '0.9rem', py: 2}}>Aucune essence enregistrée</Typography>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Accordion defaultExpanded={false} sx={{
                            width: '100%',
                            mb: 3,
                            p: 2,
                            pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="description-sol-content"
                                id="description-sol-header"
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: {xs: 0, sm: 1}
                                }}>
                                    <IconPedology
                                        style={{
                                            width: 100,
                                            height: 'auto',
                                            filter: 'invert(46%) sepia(4%) saturate(3772%) hue-rotate(15deg) brightness(97%) contrast(92%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: 'descSol.main',
                                            mx: {xs: 2, sm: 4, md: 7},
                                            fontSize: {xs: '1.1rem', sm: '1.3rem', md: '1.5rem'},
                                        }}
                                    >
                                        Description du sol
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{p: 3}}>
                                    <InfoRow
                                        label="Cause arrêt tarière"
                                        value={ReasonStopAugerMapping[this.state.project.data.pedology.causeArretTariere as string]}
                                    />
                                    <InfoRow
                                        label="Compacité"
                                        value={CompactnessMapping[this.state.project.data.pedology.compactness as string]}
                                    />
                                    <InfoRow
                                        label="Humus"
                                        value={HumusMapping[this.state.project.data.pedology.humus as string]}
                                    />
                                    <InfoRow
                                        label="PH à 20 cm"
                                        value={this.state.project.data.pedology.ph20}
                                    />
                                    <InfoRow
                                        label="PH mesuré"
                                        value={this.state.project.data.pedology.phMeasured ? 'Oui' : 'Non'}
                                    />
                                    <InfoRow
                                        label="Réservoir utile en eau"
                                        value={this.state.project.data.pedology.usefulReserve != null ?
                                            this.state.project.data.pedology.usefulReserve.toFixed(2) :
                                            ''}
                                        unit={'mm'}
                                    />
                                    <InfoRow
                                        label="UCS"
                                        value={this.state.project.data.pedology.ucs}
                                    />
                                    <InfoRow
                                        label="Type de sol"
                                        value={this.state.project.data.pedology.groundType}
                                    />
                                    <InfoRow
                                        label="Nature de la roche"
                                        value={this.state.project.data.pedology.rockType}
                                    />
                                    <InfoRow
                                        label="Commentaire"
                                        value={this.state.project.data.pedology.comment}

                                    />
                                </Box>


                                {this.state.project.data.pedology.horizons.length > 0 && (
                                    <Box>
                                        <Box sx={{p: 2}}>
                                            <Typography fontWeight="bold" sx={{fontSize: '1rem'}}>
                                                Horizons
                                            </Typography>
                                        </Box>
                                        <TableContainer component={Paper} sx={{marginTop: 1, boxShadow: 'none'}}>
                                            <Table>
                                                <TableHead
                                                    sx={{
                                                        backdropFilter: 2,
                                                        backgroundColor: 'secondary.main',
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            #
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            Épaisseur
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            Texture
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            EG
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            Calcaire actif
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            Hydromorphie
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.project.data.pedology.horizons
                                                        .map((horizons: any) => (
                                                            <TableRow
                                                                key={horizons.num}
                                                            >
                                                                <TableCell sx={{py: 2, textAlign: 'center'}}>
                                                                    {horizons.num}
                                                                </TableCell>
                                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                    {horizons.thickness}cm
                                                                </TableCell>
                                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                    {TextureEnum[horizons.texture as TextureKey]}
                                                                </TableCell>
                                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                    {horizons.eg}%
                                                                </TableCell>
                                                                {horizons.effervescence ? (
                                                                    <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                        OUI
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                        NON
                                                                    </TableCell>
                                                                )}
                                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                    {horizons.hydromorphy}
                                                                </TableCell>
                                                            </TableRow>))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Accordion defaultExpanded={false} sx={{
                            width: '100%',
                            mb: 3,
                            p: 2,
                            pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="sanitary-observations-content"
                                id="sanitary-observations-header"
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: {xs: 0, sm: 1}
                                }}>
                                    <IconSanitary
                                        style={{
                                            width: 100,
                                            height: 'auto',
                                            filter: 'invert(41%) sepia(5%) saturate(4912%) hue-rotate(201deg) brightness(97%) contrast(85%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: 'obsSanitaire.main',
                                            mx: {xs: 2, sm: 4, md: 7},
                                            fontSize: {xs: '1.1rem', sm: '1.3rem', md: '1.5rem'},
                                        }}
                                    >
                                        Observations sanitaires
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{p: 3}}>
                                    {this.state.project.data.sanitaryIssues?.listeProblemes && this.state.project.data.sanitaryIssues.listeProblemes.length > 0 ? (
                                        <TableContainer component={Paper}
                                                        sx={{boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 5px 0px"}}>
                                            <Table>
                                                <TableHead
                                                    sx={{backdropFilter: 2, backgroundColor: 'secondary.main'}}>
                                                    <TableRow>
                                                        <TableCell sx={{color: "#ffffff"}}>Problème</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>Sévérité</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>% de tiges
                                                            atteintes</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.project.data.sanitaryIssues.listeProblemes
                                                        .map((problem: ProblemeSanitaire) => (
                                                            <TableRow
                                                                key={problem.problemeId}
                                                            >
                                                                <TableCell sx={{py: 2}}>
                                                                    {problem.problemeLabel}s
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    {getSeverityPercentageLabel(problem.severite)}
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    {problem.proportion}%
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : (
                                        <Typography sx={{
                                            fontSize: '0.9rem',
                                            fontStyle: 'italic',
                                            py: 2,
                                            textAlign: 'center'
                                        }}>
                                            Aucun problème sanitaire n'est enregistré
                                        </Typography>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Accordion defaultExpanded={false} sx={{
                            width: '100%',
                            mb: 3,
                            p: 2,
                            pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="deperissement-content"
                                id="deperissement-header"
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: {xs: 0, sm: 1}
                                }}>
                                    <IconDeperis
                                        style={{
                                            width: 100,
                                            height: 'auto',
                                            filter: 'invert(14%) sepia(98%) saturate(7228%) hue-rotate(355deg) brightness(95%) contrast(101%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: 'deperis.main',
                                            mx: {xs: 2, sm: 4, md: 7},
                                            fontSize: {xs: '1.1rem', sm: '1.3rem', md: '1.5rem'},
                                        }}
                                    >
                                        Dépérissement
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{p: 3}}>
                                    <InfoRow
                                        label="Proportion d'arbres dépérissants"
                                        value={PropDeperisTreeMapping[this.state.project.data.dieback.parcelFrequency as string]}
                                    />
                                    <InfoRow
                                        label="Présence de résilience"
                                        value={ResilienceMapping[this.state.project.data.dieback.parcelIntensity as string]}
                                    />
                                    <InfoRow
                                        label="Problème majeur récent (5 ans)"
                                        value={MajorIssueMapping[this.state.project.data.dieback.environmentFrequency as string]}
                                    />
                                    <InfoRow
                                        label="Sévérité du problème"
                                        value={SeverityEnumMapping[this.state.project.data.dieback.environmentIntensity as string]}
                                    />
                                    <InfoRow
                                        label="Commentaire"
                                        value={this.state.project.data.dieback.comment}
                                    />
                                </Box>
                                {this.state.project.data.dieback.tree?.length ? (
                                    <>
                                        <Box sx={{p: 2}}>
                                            <Typography fontWeight="bold" sx={{fontSize: '1rem'}}>
                                                Notations arbres échantillons
                                            </Typography>
                                        </Box>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead
                                                    sx={{
                                                        backdropFilter: 2,
                                                        backgroundColor: 'secondary.main',
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell sx={{color: '#ffffff'}}>#</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Ramif.</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Mort.</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Archi</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Deper.</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Essence</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Diam.</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.project.data.dieback.tree.map((arbres: any) => (
                                                        <TableRow key={arbres.treenumber}>
                                                            <TableCell sx={{py: 2}}>{arbres.treenumber}</TableCell>
                                                            <TableCell
                                                                sx={{py: 0}}>{arbres.protocolDEPRISLackRamifications}</TableCell>
                                                            <TableCell
                                                                sx={{py: 0}}>{arbres.protocolDEPERISBranchMortalities}</TableCell>
                                                            <TableCell
                                                                sx={{py: 0}}>{arbres.protocolARCHI}</TableCell>
                                                            <TableCell
                                                                sx={{py: 0}}>{arbres.protocolDEPERISNote}</TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {
                                                                    arbres.essence
                                                                        ?.replace(/_/g, ' ')
                                                                        ?.replace(/^./, (char: string) => char.toUpperCase())
                                                                    ?? ''
                                                                }
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>{arbres.diameter}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <DeperissementDiagram data={this.state.project.data.dieback.tree}/>
                                    </>
                                ) : null}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Accordion defaultExpanded={false} sx={{
                            width: '100%',
                            mb: 3,
                            p: 2,
                            pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="climate-data-content"
                                id="climate-data-header"
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: {xs: 0, sm: 1}
                                }}>
                                    <IconClimatic
                                        style={{
                                            width: 100,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: 'primary.main',
                                            mx: {xs: 2, sm: 4, md: 7},
                                            fontSize: {xs: '1.1rem', sm: '1.3rem', md: '1.5rem'},
                                        }}
                                    >
                                        Données climatiques
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{
                                    p: 3,
                                    textAlign: 'center',
                                    backgroundColor: 'rgba(182, 193, 151, 0.2)',
                                }}>
                                    <Typography variant="h4" sx={{fontSize: '1.1rem', fontWeight: 'bold', m: 2}}>
                                        Valeurs Moyennes sur la période 1981-2010
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        Issues du modèle NorCliS@CNPF construit à partir des
                                        données Météo-France et AgroParisTech
                                    </Typography>

                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            p: 2,
                                            m: 2,
                                            borderRadius: 1,
                                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 5px 0px"
                                        }}
                                    >
                                        <Typography variant="h4" sx={{
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            mb: 1
                                        }}>Températures</Typography>
                                        <Divider/>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                Moyenne des annuelles (TMAN)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.TMAN ? `${this.state.climateData.TMAN} °C` : 'Non disponible'}
                                            </Typography>
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                Moyenne des maximales juin-août (TX0608)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.TX0608 ? `${this.state.climateData.TX0608} °C` : 'Non disponible'}
                                            </Typography>
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                Moyenne des minimales de janvier (TN01)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.TN01 ? `${this.state.climateData.TN01} °C` : 'Non disponible'}
                                            </Typography>
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                Température minimale absolue (TNABS)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.TNABS ? `${this.state.climateData.TNABS} °C` : 'Non disponible'}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            p: 2,
                                            m: 2,
                                            borderRadius: 1,
                                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 5px 0px"
                                        }}
                                    >
                                        <Typography variant="h4" sx={{
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            mb: 1
                                        }}>Précipitations</Typography>
                                        <Divider/>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                Précipitations annuelles (Pan)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.Pan ? `${this.state.climateData.Pan} mm` : 'Non disponible'}
                                            </Typography>
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                Précipitations avril-octobre (P0410)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.P0410 ? `${this.state.climateData.P0410} mm` : 'Non disponible'}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            p: 2,
                                            m: 2,
                                            borderRadius: 1,
                                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 5px 0px"
                                        }}
                                    >
                                        <Typography variant="h4" sx={{
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            mb: 1
                                        }}>ETP et bilans hydriques climatiques</Typography>
                                        <Divider/>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                ETP (Turc) annuelle (ETPan)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.ETPan ? `${this.state.climateData.ETPan} mm` : 'Non disponible'}
                                            </Typography>
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                P-ETP (Turc) juin-août (PETP0608)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.PETP0608 ? `${this.state.climateData.PETP0608} mm` : 'Non disponible'}
                                            </Typography>
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            m: 1
                                        }}>
                                            <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                                P-ETP (Turc) mai-septembre (PETP0509)
                                            </Typography>
                                            <Typography sx={{fontSize: '0.9rem'}}>
                                                {this.state.climateData?.PETP0509 ? `${this.state.climateData.PETP0509} mm` : 'Non disponible'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: 'center',
                            gap: 2,
                            mt: 2
                        }}
                    >
                        <Link to={`/mesprojets/${this.state.project.id}/pdf`}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    textTransform: 'none',
                                    width: '200px',
                                    height: '40px',
                                }}
                            >
                                Voir le PDF
                            </Button>
                        </Link>
                        <Button
                            variant="contained"
                            onClick={this.openProjectModal}
                            sx={{
                                textTransform: 'none',
                                width: '200px',
                                height: '40px',
                            }}
                        >
                            Voir le diagnostic
                        </Button>
                        <DiagnosticDetailsModal
                            open={this.state.isModalOpen}
                            onClose={this.closeProjectModal}
                            projectId={this.state.project.id}
                        />
                    </Box>

                </Box>
            </Box>
        )
    }
}