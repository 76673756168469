import React from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import AppHeader from "./AppHeader";

interface DocumentDisplayProps {
    title: string;
    isLoaded: boolean;
    documentContent: string;
}

const DocumentDisplay: React.FC<DocumentDisplayProps> = ({
                                                             title,
                                                             isLoaded,
                                                             documentContent,
                                                         }) => {

    const renderContent = () => {
        if (!documentContent) return null;

        return (
            <Box
                sx={{
                    '& h1': { fontSize: '1.8rem', fontWeight: 'bold', mt: 3, mb: 2 },
                    '& h2': { fontSize: '1.5rem', fontWeight: 'bold', mt: 2.5, mb: 1.5 },
                    '& h3': { fontSize: '1.3rem', fontWeight: 'bold', mt: 2, mb: 1 },
                    '& p': { textAlign: 'justify', mb: 1.5, lineHeight: 1.5 },
                    '& ul, & ol': { mb: 1.5, pl: 3 },
                    '& li': { mb: 0.5 },
                    '& a': { color: 'primary.main', textDecoration: 'underline' },
                    '& strong, & b': { fontWeight: 'bold' },
                    '& em, & i': { fontStyle: 'italic' }
                }}
                dangerouslySetInnerHTML={{ __html: documentContent }}
            />
        );
    };

    return (
        <Container maxWidth="lg">
            {isLoaded ? (
                <Box sx={{ py: 5 }}>
                    <AppHeader title={title} />
                    {renderContent()}
                </Box>
            ) : (
                <Box
                    sx={{
                        width: 1,
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Container>
    );
};

export default DocumentDisplay;