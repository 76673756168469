import React from 'react';
import {
    Alert,
    Box,
    Button,
    TextField,
    Typography,
    InputAdornment,
    IconButton
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { get_unrestricted_url } from '../../global_variables/backend_url_api';
import { AuthToken } from '../../api/token_api';
import { UserApi } from '../../api/users_api';
import AuthLayout from "../../components/Layouts/AuthLayout";
import {toast} from "react-toastify";
import success = toast.success;

export interface ComponentProps {
    user: any;
}

interface ComponentState {
    password: string;
    password_confirmation: string;
    stringRegex: string[];
    minLength: number;
    minComplexity: number;
    showPassword: boolean;
    showConfirmPassword: boolean;
    error_message: string;
}

class NeedPasswordChange extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            password: '',
            password_confirmation: '',
            stringRegex: [],
            minLength: 0,
            minComplexity: 0,
            showPassword: false,
            showConfirmPassword: false,
            error_message: '',
        };

        this.performChange = this.performChange.bind(this);
    }

    async componentDidMount(): Promise<void> {
        await this.load();
    }

    async load() {
        try {
            const data = await this.getRegex();
            this.setState({
                stringRegex: ['[a-z]', '[A-Z]', '[0-9]', '[^a-zA-Z0-9]'],
                minLength: data.minLength,
                minComplexity: data.minComplexity
            });
        } catch (e) {
            console.error(e);
            this.setState({
                error_message: "Impossible d'obtenir le schéma du mot de passe"
            });
        }
    }

    async getRegex() {
        const response = await fetch(
            `${get_unrestricted_url()}/config/password_policy`,
            {
                headers: { Authorization: AuthToken.get() },
            }
        );
        return response.json();
    }

    get passwordsMatch() {
        return this.state.password === this.state.password_confirmation;
    }

    get isPasswordValid() {
        let count = 0;
        for (let i = 0; i < this.state.stringRegex.length; i++) {
            const finalRegex = new RegExp(this.state.stringRegex[i]);
            if (this.state.password.match(finalRegex) != null) {
                count++;
            }
        }
        return count >= this.state.minComplexity && this.state.password.length >= this.state.minLength;
    }

    disconnect = () => {
        localStorage.removeItem('userToken');
        window.location.pathname = '/';
    }

    get isPasswordEmpty() {
        return this.state.password.length === 0;
    }

    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password: event.target.value });
    };

    handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password_confirmation: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    handleClickShowConfirmPassword = () => {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    };

    handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    async performChange() {
        try {
            await UserApi.changePassword(this.state.password);
            success('Mot de passe enregistré !');

            localStorage.removeItem('userToken');
            localStorage.setItem('updated', 'true');

            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        } catch (e) {
            console.error(e);
            this.setState({
                error_message: 'Un problème a empêché la modification du mot de passe.'
            });
        }
    }

    render() {
        const { showPassword, showConfirmPassword } = this.state;
        const canSubmit = this.isPasswordValid && this.passwordsMatch && !this.isPasswordEmpty;

        return (
            <AuthLayout>
                <Typography
                    sx={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        mb: 1,
                        mt: 2,
                    }}
                >
                    Changez votre mot de passe
                </Typography>

                <Typography
                    sx={{
                        color: 'gray',
                        fontSize: '0.9rem',
                        mb: 3,
                    }}
                >
                    Veuillez définir un nouveau mot de passe pour votre compte
                </Typography>

                <Box
                    component="form"
                    onSubmit={(event) => {
                        event.preventDefault();
                        if (canSubmit) {
                            this.performChange();
                        }
                    }}
                    noValidate
                    sx={{
                        mt: 3,
                        width: { xs: '90%', sm: '70%' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {this.state.error_message && (
                        <Alert
                            severity="error"
                            sx={{
                                backgroundColor: 'transparent',
                                color: 'secondary.main',
                                '& .MuiAlert-icon': {
                                    color: 'secondary.main',
                                },
                                mb: 2,
                                width: '100%'
                            }}
                        >
                            {this.state.error_message}
                        </Alert>
                    )}

                    <TextField
                        margin="normal"
                        variant="standard"
                        required
                        fullWidth
                        id="password"
                        label="Nouveau mot de passe"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        sx={{ mb: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        margin="normal"
                        variant="standard"
                        required
                        fullWidth
                        name="password_confirmation"
                        label="Confirmer le mot de passe"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={this.state.password_confirmation}
                        onChange={this.handleConfirmPasswordChange}
                        sx={{ mb: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirm password visibility"
                                        onClick={this.handleClickShowConfirmPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {!this.passwordsMatch && this.state.password_confirmation.length > 0 && (
                        <Typography variant="caption" color="error" sx={{ alignSelf: 'flex-start', mb: 1 }}>
                            Les mots de passe doivent être identiques
                        </Typography>
                    )}

                    {!this.isPasswordValid && this.state.password.length > 0 && (
                        <Typography variant="caption" color="error" sx={{ alignSelf: 'flex-start', mb: 1 }}>
                            Le mot de passe doit faire <b>{this.state.minLength}</b> caractères ou plus,
                            contenir au moins un caractère spécial, une lettre majuscule,
                            une lettre minuscule et un chiffre.
                        </Typography>
                    )}

                    <Box sx={{ mt: 3, display: 'flex', gap: 2, width: '100%' }}>
                        <Button
                            variant="outlined"
                            onClick={this.disconnect}
                            sx={{
                                width: '50%',
                                textTransform: 'none'
                            }}
                        >
                            Déconnexion
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!canSubmit}
                            sx={{
                                width: '50%',
                                textTransform: 'none'
                            }}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </AuthLayout>
        );
    }
}

export default NeedPasswordChange;