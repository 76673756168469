import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Project } from "../../../interfaces/project_interface";
import { ProjectApi } from "../../../api/projects_api";
import ProjectPDF from "./pdf_projets";
import { ClimateData } from "../../../interfaces/climateData_interface";
import {CircularProgress} from "@mui/material";

const PDFViewerPage = () => {
    const { id } = useParams<{ id: string }>();
    const [project, setProject] = useState<Project | null>(null);
    const [climateProject, setClimateProject] = useState<ClimateData | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    setLoading(true);
                    const projectData = await ProjectApi.getProject(parseInt(id));
                    setProject(projectData);

                    if (projectData) {
                        try {
                            const climateData = await ProjectApi.getClimateData(parseInt(id));
                            setClimateProject(climateData);
                        } catch (climateError) {
                            console.error("Erreur données climatiques:", climateError);
                        }
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération du projet:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [id]);

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            {loading ? (
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f5f5f5'
                }}>
                    <CircularProgress />
                    <p style={{ marginTop: '1rem', color: '#666' }}>Chargement en cours...</p>
                </div>
            ) : (
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <ProjectPDF project={project} climateData={climateProject} />
                </PDFViewer>
            )}
        </div>
    );
};

export default PDFViewerPage;