
import {Project} from '../../interfaces/project_interface'
import React, {useCallback, useEffect, useState} from 'react'
import {ProjectApi} from '../../api/projects_api'
import {ProjectsTable} from "./ProjectsTable";


export interface ProjectsListProps {
    projects: Project[],
    pagination?: {
        page: number
        limit: number
        totalItems: number
        totalPages: number
    }
}

export const UserProjects: React.FC<ProjectsListProps> = (props) => {
    const [projects, setProjects] = useState<Project[]>(props.projects || []);
    const [pagination, setPagination] = useState(props.pagination || {
        page: 1,
        limit: 15,
        totalItems: 0,
        totalPages: 1
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showErrorFlash, setShowErrorFlash] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const loadProjects = useCallback(async (page: number) => {
        setLoading(true);
        try {
            const [response] = await Promise.all([
                ProjectApi.listUserProjects({page: page}),
                new Promise(resolve => setTimeout(resolve, 300))
            ]);

            setProjects(response.data);
            setPagination(response.pagination);
        } catch (err) {
            console.error("Erreur lors du chargement des projets:", err);
            setError("Erreur lors du chargement des projets");
            setShowErrorFlash(true);
        } finally {
            setLoading(false);
        }
    }, []);

    const downloadProjects = useCallback(async () => {
        setDownloading(true);
        try {
            const blob = await ProjectApi.downloadProjectsCsv();
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = "projects.zip";
            link.click();
            URL.revokeObjectURL(blobUrl);
        } catch (err) {
            console.error("Erreur lors du téléchargement:", err);
            setError("Erreur lors du téléchargement des projets");
            setShowErrorFlash(true);
        } finally {
            setDownloading(false);
        }
    }, []);

    const handleCloseError = () => {
        setShowErrorFlash(false);
    };

    useEffect(() => {
        if (!props.projects || props.projects.length === 0) {
            loadProjects(1);
        }
    }, [props.projects, loadProjects]);

    return (
        <ProjectsTable
            projects={projects}
            loading={loading}
            error={error}
            showErrorFlash={showErrorFlash}
            pagination={pagination}
            title="Liste des projets de l'utilisateur"
            downloadButtonText="Télécharger tous mes projets"
            onPageChange={loadProjects}
            onDownload={downloadProjects}
            onErrorClose={handleCloseError}
            downloading={downloading}
        />
    );
}