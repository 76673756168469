import React, {useEffect, useState} from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
} from "@mui/material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../assets/styles/editorHtml.css'


export interface FormData {
    thematic: string;
    titre: string;
    description: string;
}

interface AddPopupFormProps {
    popupThematic: string;
    thematicData: any[];
    initialTitle: string;
    initialDescription: string;
    handleThematicChange: (event: SelectChangeEvent<string>) => void;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleEditorChange: (content: string) => void;
    updateFormData: (data: Partial<FormData>) => void;
}

export const AddPopupForm: React.FC<AddPopupFormProps> = ({
                                                              popupThematic,
                                                              thematicData,
                                                              initialTitle = '',
                                                              initialDescription = '',
                                                              handleThematicChange,
                                                              handleInputChange,
                                                              handleEditorChange,
                                                              updateFormData,
                                                          }) => {
    const [titre, setTitre] = useState(initialTitle);
    const [description, setDescription] = useState(initialDescription);

    useEffect(() => {
        setTitre(initialTitle);
    }, [initialTitle]);

    useEffect(() => {
        setDescription(initialDescription);
    }, [initialDescription]);

    const modules = {
        toolbar: [
            [{'font': []}],
            [{'header': [1, 2, 3, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'align': []}],
            ['clean']
        ],
        clipboard: {
            matchVisual: false
        },
    };

    const formats = [
        'font',
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'link', 'image',
        'align'
    ];

    const handleTitreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTitre = e.target.value;
        setTitre(newTitre);
        handleInputChange(e);
    };

    const handleQuillChange = (content: string) => {
        setDescription(content);
        handleEditorChange(content);
    };

    return (
        <>
            <FormControl fullWidth margin="dense">
                <InputLabel id="thematic-label">Thématique</InputLabel>
                <Select
                    labelId="thematic-label"
                    value={popupThematic}
                    label="Thématique"
                    onChange={handleThematicChange}
                    name="thematic"
                    required
                >
                    {thematicData.map((thematic) => (
                        <MenuItem key={thematic.id} value={thematic.id}>
                            {thematic.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                fullWidth
                margin="dense"
                name="titre"
                label="Titre"
                value={titre}
                onChange={handleTitreChange}
                required
            />

            <Box sx={{mt: 2, mb: 2}}>
                <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={handleQuillChange}
                    modules={modules}
                    formats={formats}
                    style={{height: '200px', marginBottom: '80px'}}
                />
            </Box>
        </>
    );
};