import React from 'react'
import DocumentDisplay from "../../components/common/DocumentDisplay";
import {toast} from "react-toastify";
import error = toast.error;
import {PPDApi} from "../../api/ppd_api";
import {PPD} from "../../interfaces/ppd_interface";

export interface PublicPPDProps {
}

export interface PublicPPDState {
    loaded: boolean
    ppd: PPD
}

export class PublicPpd extends React.Component<PublicPPDProps, PublicPPDState> {
    constructor(props: PublicPPDProps) {
        super(props)

        this.state = {
            loaded: false,
            ppd: {
                content: '',
                title: '',
                version: '',
                online: true
            },
        }
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const onlinePpd = await PPDApi.getOnlinePPD()
            this.setState({
                ppd: onlinePpd,
                loaded: true
            })
        } catch (e) {
            console.error('Erreur lors du chargement de la PPD:', e)
            error('Échec du chargement de la politique de confidentialité')
            this.setState({loaded: true})
        }
    }

    render() {
        return (
            <DocumentDisplay
                title={this.state.ppd.title}
                isLoaded={this.state.loaded}
                documentContent={this.state.ppd.content}
            />
        )
    }
}

export default PublicPpd