import React, { useState, useEffect } from 'react';
import {
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { LUFApi } from '../../api/luf_api';
import { PPDApi } from '../../api/ppd_api';

interface UserConsent {
    userId?: number;
    lufId?: number;
    ppdId?: number;
    hasConsent: boolean;
    consentDate?: string;
    version?: string;
}

interface UserConsentsProps {
    userId: number;
}

const UserConsents: React.FC<UserConsentsProps> = ({ userId }) => {
    const [lufConsents, setLufConsents] = useState<UserConsent[]>([]);
    const [ppdConsents, setPpdConsents] = useState<UserConsent[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchConsentDetails = async <T extends { lufId?: number, ppdId?: number }>(
        consents: T[],
        fetchDetailsFunc: (id: number) => Promise<{ version?: string }>,
        idKey: 'lufId' | 'ppdId'
    ): Promise<(T & { version: string })[]> => {
        return Promise.all(
            consents.map(async (consent) => {
                const id = consent[idKey];
                if (id) {
                    try {
                        const details = await fetchDetailsFunc(id);
                        return { ...consent, version: details.version || 'N/A' };
                    } catch (error) {
                        console.error(`Erreur lors de la récupération des détails:`, error);
                        return { ...consent, version: 'Non disponible' };
                    }
                }
                return { ...consent, version: 'Non disponible' };
            })
        );
    };

    const loadConsents = React.useCallback(async () => {
        if (!userId) return;

        try {
            setIsLoading(true);

            const [lufResponse, ppdResponse] = await Promise.all([
                LUFApi.getUserLufConsents(userId),
                PPDApi.getUserPpdConsents(userId)
            ]);

            const normalizeLufConsents = Array.isArray(lufResponse)
                ? lufResponse
                : (lufResponse.hasConsent
                    ? (Array.isArray(lufResponse.hasConsent) ? lufResponse.hasConsent : [lufResponse.hasConsent])
                    : []);

            const normalizePpdConsents = Array.isArray(ppdResponse)
                ? ppdResponse
                : (ppdResponse.hasConsent
                    ? (Array.isArray(ppdResponse.hasConsent) ? ppdResponse.hasConsent : [ppdResponse.hasConsent])
                    : []);

            const [enrichedLufConsents, enrichedPpdConsents] = await Promise.all([
                fetchConsentDetails(normalizeLufConsents, LUFApi.getById, 'lufId'),
                fetchConsentDetails(normalizePpdConsents, PPDApi.getById, 'ppdId')
            ]);

            setLufConsents(enrichedLufConsents);
            setPpdConsents(enrichedPpdConsents);
        } catch (error) {
            console.error("Erreur lors du chargement des consentements:", error);
        } finally {
            setIsLoading(false);
        }
    }, [userId]);

    useEffect(() => {
        loadConsents();
    }, [loadConsents]);

    const formatConsentDate = (timestamp?: string) =>
        timestamp ? new Date(timestamp).toLocaleString() : 'Non disponible';

    const renderConsentTable = (
        consents: UserConsent[],
        title: string,
        emptyMessage: string
    ) => (
        <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary">
                {title}
            </Typography>
            <TableContainer component={Paper} sx={{ mb: { xs: 3, md: 0 } }}>
                <Table>
                    <TableHead
                        sx={{
                            backdropFilter: 2,
                            backgroundColor: 'primary.main',
                        }}
                    >
                        <TableRow>
                            <TableCell sx={{ color: '#ffffff' }}>Version</TableCell>
                            <TableCell sx={{ color: '#ffffff' }}>Date</TableCell>
                            <TableCell sx={{ color: '#ffffff' }}>Statut</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <CircularProgress size={20} />
                                </TableCell>
                            </TableRow>
                        ) : consents.length > 0 ? (
                            consents.map((consent, index) => (
                                <TableRow key={index}>
                                    <TableCell>{consent.version}</TableCell>
                                    <TableCell>{formatConsentDate(consent.consentDate)}</TableCell>
                                    <TableCell>
                                        {consent.hasConsent ? (
                                            <span style={{ color: 'green' }}>Accepté</span>
                                        ) : (
                                            <span style={{ color: 'red' }}>Refusé</span>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    {emptyMessage}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );

    return (
        <Grid item lg={4}>
            <p style={{ padding: '30px 0 10px 0', fontWeight: 800 }}>
                Acceptation des conditions
            </p>

            <Grid container spacing={2}>
                {renderConsentTable(
                    lufConsents,
                    'Licence d\'Utilisation Finale (LUF)',
                    'Aucun consentement LUF trouvé'
                )}
                {renderConsentTable(
                    ppdConsents,
                    'Politique de Protection des Données (PPD)',
                    'Aucun consentement PPD trouvé'
                )}
            </Grid>
        </Grid>
    );
};

export default UserConsents;