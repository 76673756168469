import {User} from "../../interfaces/user_interface";
import React, {useCallback, useEffect, useState} from "react";
import {
    Box, Button, Divider, List, ListItem,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";
import {UserApi} from "../../api/users_api";
import {GeoRestrictionApi} from "../../api/geo_restrictions_api";
import Icon from "@mdi/react";
import {mdiLogout} from "@mdi/js";
import Areyousure from "../../components/Popup/Areyousure/Areyousure";
import AppHeader from "../../components/common/AppHeader";
import {LUFApi} from "../../api/luf_api";
import {PPDApi} from "../../api/ppd_api";

export interface ProfilProps {
    current_user: number
}

type StructureRole = "UNIQUE" | "NATIONAL" | "GESTION_PROJET";

const DEFAULT_USER = {
    id: 0,
    firstname: '',
    lastname: '',
    email: '',
    maillingAddress: '',
    phoneNumber: '',
    isAdmin: false,
    isBetaTest: false,
    isCnpf: false,
    isActive: true,
    numDevicesAuthorized: 0,
    structureRole: 'UNIQUE' as StructureRole,
    expireOn: new Date().getTime() / 1000,
    structureId: 1,
    devices: [],
};

export const Profil = ({current_user}: ProfilProps) => {
    const [user, setUser] = useState<User>(DEFAULT_USER);
    const [zones, setZones] = useState<any[]>([]);
    const [actionUserId, setActionUserId] = useState<number>(NaN);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [onlineLufConsent, setOnlineLufConsent] = useState<boolean | null>(null);
    const [onlinePpdConsent, setOnlinePpdConsent] = useState<boolean | null>(null);

    const loadData = useCallback(async () => {
        try {
            const userData = await UserApi.getUserById(current_user);
            const zoneList = await GeoRestrictionApi.listGeoRestrictions();

            setUser(userData);
            setZones(zoneList);
        } catch (e) {
            console.error(e);
            alert("Echec du chargement de l'utilisateur");
        }
    }, [current_user]);

    const fetchOnlineConsents = useCallback(async () => {
        try {
            const onlineLuf = await LUFApi.getOnlineLUF();
            const onlinePpd = await PPDApi.getOnlinePPD();

            if (onlineLuf?.id) {
                try {
                    const lufConsentResponse = await LUFApi.getUserLufConsent(onlineLuf.id, current_user);
                    setOnlineLufConsent(lufConsentResponse.hasConsent);
                } catch (err) {
                    console.error('Erreur lors de la récupération du consentement LUF:', err);
                    setOnlineLufConsent(null);
                }
            }

            if (onlinePpd?.id) {
                try {
                    const ppdConsentResponse = await PPDApi.getUserPpdConsent(onlinePpd.id, current_user);
                    setOnlinePpdConsent(ppdConsentResponse.hasConsent);
                } catch (err) {
                    console.error('Erreur lors de la récupération du consentement PPD:', err);
                    setOnlinePpdConsent(null);
                }
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des consentements:', error);
        }
    }, [current_user]);

    const handleDeviceDisconnect = async () => {
        try {
            await UserApi.deleteUserDevice(actionUserId);
            window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la déconnexion de l\'appareil:', error);
            alert('Erreur lors de la déconnexion de l\'appareil');
        } finally {
            setShowDeleteConfirmation(false);
        }
    };

    useEffect(() => {
        loadData();
        fetchOnlineConsents();
    }, [current_user, loadData, fetchOnlineConsents]);

    const renderInfoItem = (label: string, value: React.ReactNode) => (
        <>
            <Box sx={{
                display: 'flex',
                gap: 2,
                mt: 2,
                mb: 2
            }}>
                <Box sx={{flexBasis: '20%'}}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: '0.8rem',
                        color: 'text.secondary'
                    }}>{label}</Typography>
                </Box>
                <Box sx={{flexBasis: '80%'}}>
                    <Typography sx={{
                        fontWeight: '400',
                        fontSize: '0.9rem',
                    }}>{value}</Typography>
                </Box>
            </Box>
            <Divider/>
        </>
    );

    const renderYesNo = (condition: boolean) => condition ? 'Oui' : 'Non';

    return (
        <Box sx={{p: 4}}>
            <AppHeader title="Mon profil"/>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {/* General Information */}
                <Box
                    sx={{
                        p: 1,
                        width: '100%',
                        maxWidth: {xs: '100%', sm: '800px'},
                    }}
                >
                    <Box sx={{
                        p: 2, pt: 1, pb: 6,
                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                        borderRadius: 2
                    }}>
                        <Box sx={{pb: 2, pt: 2}}>
                            <h3>Informations générales</h3>
                        </Box>
                        {renderInfoItem('Nom', user.lastname.toUpperCase())}
                        {renderInfoItem('Prénom', user.firstname)}
                        {renderInfoItem('Email:', user.email)}
                        {renderInfoItem('N° de Téléphone', user.phoneNumber)}
                        {renderInfoItem('Adresse Postale', user.maillingAddress)}
                        {renderInfoItem('Membre CNPF', renderYesNo(user.isCnpf))}
                        {renderInfoItem('Compte Actif', renderYesNo(user.isActive))}
                        {renderInfoItem('Rôle:', user.isAdmin ? 'Administrateur' : 'Utilisateur')}
                    </Box>
                </Box>

                {/* License */}
                <Box
                    sx={{
                        p: 1,
                        width: '100%',
                        maxWidth: {xs: '100%', sm: '800px'},
                    }}
                >
                    <Box sx={{
                        p: 2, pt: 1,
                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                        borderRadius: 2
                    }}>
                        <Box sx={{pb: 2, pt: 2}}>
                            <h3>Licence</h3>
                        </Box>

                        {renderInfoItem('Date d\'expiration',
                            new Date(user.expireOn * 1000).toLocaleDateString()
                        )}

                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            mt: 2,
                            mb: 2
                        }}>
                            <Box sx={{flexBasis: '20%'}}>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    fontSize: '0.8rem',
                                    color: 'text.secondary'
                                }}>Nombre d'appareils autorisés</Typography>
                            </Box>
                            <Box sx={{flexBasis: '80%'}}>
                                <Typography sx={{
                                    fontWeight: '400',
                                    fontSize: '0.9rem',
                                    color: (user.devices?.length && (user.devices?.length > user.numDevicesAuthorized)) ? 'red' : 'inherit'
                                }}>
                                    {user.numDevicesAuthorized}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider/>

                        {renderInfoItem('Nombre d\'appareils connectés', user.devices?.length || 0)}

                        <Box>
                            <TableContainer component={Paper} sx={{marginTop: 5}}>
                                <Table>
                                    <TableHead sx={{backdropFilter: 2, backgroundColor: 'primary.main'}}>
                                        <TableRow>
                                            <TableCell sx={{color: '#ffffff'}}>
                                                Nom de l'appareil
                                            </TableCell>
                                            <TableCell sx={{color: '#ffffff'}}>
                                                Dernière utilisation
                                            </TableCell>
                                            <TableCell sx={{color: '#ffffff'}}>
                                                Déconnexion
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!(user.devices?.length) ? (
                                            <TableRow>
                                                <TableCell sx={{py: 2}} colSpan={3}>
                                                    Aucun appareil connecté à cet utilisateur
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            user.devices
                                                .sort((a, b) => a.lastUsed < b.lastUsed ? 1 : -1)
                                                .map((device: any) => (
                                                    <TableRow
                                                        key={device.id}
                                                        sx={{
                                                            '&:hover': {
                                                                backgroundColor: 'background.default',
                                                            },
                                                        }}
                                                    >
                                                        <TableCell sx={{py: 2}}>
                                                            {device.name}
                                                        </TableCell>
                                                        <TableCell sx={{py: 0}}>
                                                            {new Date(device.lastUsed * 1000).toLocaleDateString()}
                                                        </TableCell>
                                                        <TableCell sx={{py: 0}}>
                                                            <Box>
                                                                <List>
                                                                    <ListItem sx={{p: 0, my: 1}}>
                                                                        <Tooltip
                                                                            title="Déconnecter"
                                                                            placement="right"
                                                                        >
                                                                            <Button
                                                                                variant="outlined"
                                                                                onClick={() => {
                                                                                    setActionUserId(device.id);
                                                                                    setShowDeleteConfirmation(true);
                                                                                }}
                                                                                sx={{p: 0, px: 1}}
                                                                                color="error"
                                                                            >
                                                                                <Icon path={mdiLogout}/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {showDeleteConfirmation && (
                                <Areyousure
                                    message="supprimer un appareil"
                                    action={handleDeviceDisconnect}
                                    close={() => setShowDeleteConfirmation(false)}
                                    reload={() => {
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>

                {/* Consentement */}
                <Box
                    sx={{
                        p: 1,
                        width: '100%',
                        maxWidth: {xs: '100%', sm: '800px'},
                    }}
                >
                    <Box sx={{
                        p: 2, pt: 1,
                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                        borderRadius: 2
                    }}>
                        <Box sx={{pb: 2, pt: 2}}>
                            <h3>Consentements en ligne</h3>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            mt: 2,
                            mb: 2
                        }}>
                            <Box sx={{flexBasis: '20%'}}>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    fontSize: '0.8rem',
                                    color: 'text.secondary'
                                }}>Licence d'Utilisation Finale</Typography>
                            </Box>
                            <Box sx={{flexBasis: '80%'}}>
                                <Typography sx={{
                                    fontWeight: '400',
                                    fontSize: '0.9rem',
                                }}>
                                    {onlineLufConsent === true ? 'Accepté' :
                                        onlineLufConsent === false ? 'Refusé' :
                                            'Information non disponible'}
                                </Typography>
                            </Box>
                        </Box>

                        <Divider sx={{my: 1}}/>

                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            mt: 2,
                            mb: 2
                        }}>
                            <Box sx={{flexBasis: '20%'}}>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    fontSize: '0.8rem',
                                    color: 'text.secondary'
                                }}>Politique de Protection des Données</Typography>
                            </Box>
                            <Box sx={{flexBasis: '80%'}}>
                                <Typography sx={{
                                    fontWeight: '400',
                                    fontSize: '0.9rem',
                                }}>
                                    {onlinePpdConsent === true ? 'Accepté' :
                                        onlinePpdConsent === false ? 'Refusé' :
                                            'Information non disponible'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Zones */}
                {zones?.length > 0 && (
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Box sx={{
                            p: 2, pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <Box sx={{pb: 2, pt: 2}}>
                                <h3>Zones autorisées</h3>
                            </Box>
                            <Box>
                                {zones.map((zone: any) => (
                                    <Box sx={{mt: 1}} key={zone.id}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>
                                            {zone.name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};