import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export const PageContainer: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => (
    <Box sx={{ p: 5 }}>
        {children}
    </Box>
);

export const LoadingSpinner: React.FC = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <CircularProgress sx={{ color: 'primary.main' }} />
    </Box>
);

export const ActionSpinner: React.FC = () => (
    <CircularProgress
        size={20}
        sx={{
            color: 'primary.main',
            position: 'absolute',
            right: 16,
            top: '50%',
            transform: 'translateY(-50%)'
        }}
    />
);

export const TwoColumnLayout: React.FC<{
    leftColumn: React.ReactNode;
    rightColumn: React.ReactNode;
}> = ({ leftColumn, rightColumn }) => (
    <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        p: 2,
        flexGrow: 1,
        position: 'relative',
        width: '100%',
        height: { xs: 'auto', md: 'calc(100vh - 180px)' },
    }}>
        {leftColumn}
        <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            mt: { xs: 2, md: 0 }
        }}>
            {rightColumn}
        </Box>
    </Box>
);

export const TabBar: React.FC<{
    currentTab: number;
    onTabChange: (index: number) => void;
    tabs: string[];
    loading?: boolean;
}> = ({ currentTab, onTabChange, tabs, loading }) => (
    <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
        position: 'relative'
    }}>
        <div role="tablist">
            {tabs.map((tabName, index) => (
                <button
                    key={index}
                    role="tab"
                    aria-selected={currentTab === index}
                    onClick={() => onTabChange(index)}
                    style={{
                        padding: '15px 16px',
                        border: 'none',
                        background: 'none',
                        borderBottom: currentTab === index ? '2px solid #2b603e' : 'none',
                        color: currentTab === index ? '#2b603e' : 'inherit',
                        fontWeight: currentTab === index ? 'bold' : 'normal',
                        cursor: 'pointer'
                    }}
                >
                    {tabName}
                </button>
            ))}
        </div>
        {loading && <ActionSpinner />}
    </Box>
);

export const EmptySelectionMessage: React.FC<{
    message: string;
}> = ({ message }) => (
    <Box sx={{ mt: 2, p: 2, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {message}
        </Typography>
    </Box>
);