import React from 'react';
import {VigilenceColors} from '../../../assets/styles/colors';
import {Box, Typography, LinearProgress, Divider} from "@mui/material";
import {DiagnosticPeuplement, DiagnosticTemperature} from "../../../interfaces/Diagnostic_interface";

interface PeuplementProps {
    diagnosticsPeuplement: DiagnosticPeuplement;
}

interface VigilanceSquaresProps {
    value: number;
}

interface DiebackProgressProps {
    value: number;
}

interface TemperatureSectionProps {
    temperature: DiagnosticTemperature;
    label: string;
}

export const getIndexColorDieback = (percent: number): number => {
    if (percent < 10) return 0;
    else if (percent < 20) return 1;
    else if (percent < 30) return 2;
    else if (percent < 40) return 3;
    else if (percent < 50) return 4;
    else if (percent < 60) return 5;
    else if (percent < 70) return 6;
    else if (percent < 80) return 7;
    else if (percent < 90) return 8;
    else if (percent < 95) return 9;
    else return 10;
};

const VigilanceSquares: React.FC<VigilanceSquaresProps> = ({value}) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            flexWrap: 'wrap',
            mb: 1
        }}>
            {[...Array(10)].map((_, index) => (
                <Box
                    key={index}
                    sx={{
                        width: {xs: '22px', sm: '25px', md: '35px'},
                        height: {xs: '22px', sm: '25px', md: '35px'},
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #ccc',
                        bgcolor: index + 1 === value ? VigilenceColors.getVigilenceColor(value) : 'white',
                        color: index + 1 === value ? 'white' : 'black',
                        fontSize: {xs: '12px', sm: '14px', md: '16px'},
                        fontWeight: 'bold'
                    }}
                >
                    {index + 1}
                </Box>
            ))}
        </Box>
    );
};


const DiebackProgress: React.FC<DiebackProgressProps> = ({value}) => {
    const color =  getIndexColorDieback(value);

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            mt: 2,
            mb: 1
        }}>
            <LinearProgress
                variant="determinate"
                value={value}
                sx={{
                    height: 15,
                    borderRadius: 1,
                    width: '100%',
                    backgroundColor: 'background.default',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: VigilenceColors.getVigilenceColor(color)
                    }
                }}
            />
        </Box>
    );
};

const TemperatureSection: React.FC<TemperatureSectionProps> = ({temperature, label}) => {
    return (
        <Box sx={{
            width: {xs: '95%', sm: '90%', md: '80%'},
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            mx: 'auto',
            my: 3,
        }}>
            <Box>
                <Typography sx={{
                    color: 'dark',
                    fontSize: {xs: '15px', sm: '16px', md: '17px'},
                    textAlign: 'start',
                    p: 1,
                    mx: 3,
                    fontWeight: 500
                }}>
                    {label}
                </Typography>
                <Divider sx={{ mx: 3 , width: '90%'}}/>
            </Box>
            <Box sx={{
                p: 2,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}>

                    <VigilanceSquares value={temperature.vigilence}/>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'},
                        alignItems: {xs: 'flex-start', sm: 'center'},
                        mt: 2,
                        width: '100%'
                    }}>
                        <Typography sx={{
                            fontSize: {xs: '12px', sm: '13px', md: '14px'},
                            fontWeight: 'bold',
                            width: {xs: '100%', sm: '30%'},
                            mr: 1,
                            mb: {xs: 1, sm: 0}
                        }}>
                            Probabilité de dépérissement
                        </Typography>

                        <Typography variant="caption" fontWeight="bold" sx={{
                            mr: 2,
                            mb: {xs: 1, sm: 0}
                        }}>
                            {temperature.deperissement}%
                        </Typography>

                        <Box sx={{flexGrow: 1, width: {xs: '100%', sm: 'auto'}}}>
                            <DiebackProgress value={temperature.deperissement}/>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
};

const Peuplement: React.FC<PeuplementProps> = ({diagnosticsPeuplement}) => {
    const formatEssence = (essence: string): string => {
        return essence.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <Box sx={{
            width: '100%',
            maxWidth: {
                xs: '100%',
                sm: '600px',
                md: '900px'
            },
            mx: 'auto',
            px: {
                xs: 2,
                sm: 3
            }
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                <Typography
                    variant="h5"
                    component="div"
                    sx={{
                        fontSize: {xs: '20px', sm: '24px', md: '28px'},
                        fontWeight: 'bold',
                        color: 'primary.main',
                        textAlign: 'center'
                    }}
                >
                    {formatEssence(diagnosticsPeuplement.essence)}
                </Typography>
            </Box>

            <Box sx={{
                overflow: 'hidden'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pb: 2
                }}>
                    <TemperatureSection
                        temperature={diagnosticsPeuplement.temperature[0]}
                        label="Climat 1981-2010"
                    />

                    <TemperatureSection
                        temperature={diagnosticsPeuplement.temperature[1]}
                        label="Climat 1981-2010 - Scénario +1°C"
                    />

                    <TemperatureSection
                        temperature={diagnosticsPeuplement.temperature[2]}
                        label="Climat 1981-2010 - Scénario +2°C"
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Peuplement;