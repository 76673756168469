import React from 'react';
import DocumentDetails from '../../../components/common/DocumentDetails';
import { PPD } from '../../../interfaces/ppd_interface';

interface PpdDetailsProps {
    ppd: PPD;
    editing: boolean;
    setEditing: (editing: boolean) => void;
    setCurrentPpd: (ppd: PPD) => void;
    setShowConfirmation: (show: boolean) => void;
    setShowOnlineConfirmation: (show: boolean) => void;
    setShowDeleteConfirmation: (show: boolean) => void;
    disabled?: boolean;
}

const PpdDetails: React.FC<PpdDetailsProps> = ({
                                                   ppd,
                                                   editing,
                                                   setEditing,
                                                   setCurrentPpd,
                                                   setShowConfirmation,
                                                   setShowOnlineConfirmation,
                                                   setShowDeleteConfirmation,
                                                   disabled = false
                                               }) => {
    return (
        <DocumentDetails<PPD>
            document={ppd}
            editing={editing}
            setEditing={setEditing}
            setCurrentDocument={setCurrentPpd}
            setShowConfirmation={setShowConfirmation}
            setShowOnlineConfirmation={setShowOnlineConfirmation}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            disabled={disabled}
        />
    );
};

export default PpdDetails;