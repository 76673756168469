import React, { memo } from 'react';
import { Project } from '../../interfaces/project_interface';
import { Button, TableCell, TableRow, CircularProgress } from '@mui/material';
import { ProjectApi } from '../../api/projects_api';

interface ProjectRowProps {
    project: Project;
}

export const ProjectRow = memo(({ project }: ProjectRowProps) => {
    const [isDownloading, setIsDownloading] = React.useState(false);

    const jsonHref = React.useMemo(() => {
        return `data:text/json;charset=utf-8, ${encodeURIComponent(
            JSON.stringify(project)
        )}`;
    }, [project]);

    const jsonFilename = React.useMemo(() => {
        return `projet-${project.id}.json`;
    }, [project.id]);

    const decodeString = (input: any) => {
        if (!input) return input;
        return input.replace(/\\u0027/g, "'").replace(/\\u0022/g, '"');
    };

    const csvDownload = async () => {
        setIsDownloading(true);
        try {
            const download = await ProjectApi.projectData(project.id);
            const blobUrl = URL.createObjectURL(download);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `project-${project.id}.zip`;
            link.click();
            URL.revokeObjectURL(blobUrl);
        } catch (e) {
            console.error(e);
            alert("Erreur lors du téléchargement du projet");
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <TableRow key={project.id} sx={{
            '&:hover': {
                backgroundColor: 'background.default',
            },
        }}>
            <TableCell>{decodeString(project.data.name)}</TableCell>
            <TableCell>{decodeString(project.data.operator)}</TableCell>
            <TableCell>
                <Button
                    onClick={csvDownload}
                    disabled={isDownloading}
                    startIcon={isDownloading ? <CircularProgress size={16} /> : null}
                >
                    {isDownloading ? 'Téléchargement...' : 'Format CSV'}
                </Button>
            </TableCell>
            <TableCell>
                <Button
                    download={jsonFilename}
                    href={jsonHref}
                >
                    Format Json
                </Button>
            </TableCell>
        </TableRow>
    );
});