import {ApiClient} from './api_client';
import {PPD} from "../interfaces/ppd_interface";

export interface ConsentResponse {
    hasConsent: boolean;
    consentDate?: string;
    userId: number;
    ppdId: number;
}

export class PPDApi {
    /**
     * Récupérer l'ensemble des PPDs
     * @returns PPD[]
     */
    static async getAll(): Promise<PPD[]> {
        try {
            return await ApiClient.get('/ppd/all')
        } catch (e) {
            throw new Error(
                `Echec de la récupération des ppds`
            )
        }

    }

    /**
     * Récupération des informations d'un PPD à l'aide de son id
     * @param ppdId ID de la PPD
     * @returns PPD
     */
    static async getById(ppdId : number): Promise<PPD> {
        try {
            return await ApiClient.get(`/ppd/${ppdId}`)
        } catch (e) {
            throw new Error(
                `Echec de la récupération de la PPD`
            )
        }

    }


    /**
     * Récupération du PPD actif
     * @returns PPD
     */
    static async getOnlinePPD(): Promise<PPD> {
        try {
            return await ApiClient.get('/ppd/online', {
                restricted: false,
            })
        } catch (e) {
            throw new Error(
                `Echec de la récupération des ppds`
            )
        }

    }

    /**
     * Créer une nouvelle PPD
     * @param ppd PPD
     * @returns PPD
     */
    static async create(ppd: PPD): Promise<PPD> {
        try {
            return await ApiClient.post('/ppd/create', {
                body: JSON.stringify(ppd)
            })
        } catch (e) {
            console.error('Erreur détaillée:', e);
            throw new Error(
                `Echec de la création de la ppd`
            )
        }
    }

    /**
     * Mettre à jour une PPD
     * @returns PPD
     */
    static async update(ppd: PPD): Promise<PPD> {
        try {
            return await ApiClient.put(`/ppd/${ppd.id}`, {
                body: JSON.stringify(ppd)
            });
        } catch (e) {
            throw new Error(
                `Echec de la modification de la ppd`
            );
        }
    }

    /**
     * Mettre en ligne une PPD
     * @returns PPD
     */
    static async setPPDOnline(id: number, updatedBy: number): Promise<PPD> {
        try {
            return await ApiClient.put(`/ppd/${id}/online?updatedBy=${updatedBy}`, {});
        } catch (e) {
            throw new Error(
                `Echec de la modification de la ppd`
            );
        }
    }

    /**
     * Supprimer une PPD
     * @param ppdId ID de la PPD
     */
    static async delete(ppdId: number): Promise<void> {
             await ApiClient.delete(`/ppd/${ppdId}`);
    }

    /**
     * Récupère le consentement PPD pour un utilisateur
     * @param ppdId ID de la PPD
     * @param userId ID de l'utilisateur
     * @returns Statut du consentement
     */
    static async getUserPpdConsent(ppdId: number, userId: number): Promise<ConsentResponse> {
        try {
            return  await ApiClient.get(`/users/${userId}/ppds/${ppdId}`, {
                restricted: false,
            });
        } catch (e) {
            throw new Error(`Échec de la récupération du consentement PPD`);
        }
    }

    /**
     * Mettre à jour le consentement d'un utilisateur pour une PPD
     * @param ppdId ID de la PPD
     * @param userId ID de l'utilisateur
     * @param hasConsent Valeur du consentement (true=accepté, false=refusé)
     */
    static async updateUserPpdConsent(ppdId: number, userId: number, hasConsent: boolean): Promise<void> {
        try {
            await ApiClient.put(`/users/${userId}/ppds/${ppdId}`, {
                body: JSON.stringify({
                    hasConsent: hasConsent
                }),
                restricted: false,
            });
        } catch (e) {
            throw new Error(`Échec de la mise à jour du consentement PPD`);
        }
    }


    /**
     * Récupérer tous les consentements PPD d'un utilisateur"
     * @param userId ID de l'utilisateur
     */
    static async getUserPpdConsents(userId: number): Promise<ConsentResponse> {
        try {
            return await ApiClient.get(`/users/${userId}/ppds`, {
                restricted: false,
            });
        } catch (e) {
            throw new Error(`Échec de la récupération du consentement PPD`);
        }
    }
}