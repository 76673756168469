import {ApiClient} from "./api_client";
import {ThematicData} from "../interfaces/thematicData_interface";

export class ThematicDataApi {

    /**
     * Récupérer toutes les données thématiques activées
     * @returns ThematicData[]
     */
    static async getAllOnline(): Promise<ThematicData[]> {
        try {
            return await ApiClient.get('/thematic-data/online')
        } catch (e) {
            throw new Error(
                `Echec de la récupération des données thématiques activées`
            )
        }

    }
}