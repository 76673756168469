import {View, Text, StyleSheet} from '@react-pdf/renderer';
import React from 'react';
import {VigilenceColors} from '../../../assets/styles/colors';
import {DiagnosticPeuplement, DiagnosticTemperature} from "../../../interfaces/Diagnostic_interface";
import {getIndexColorDieback} from "./Peuplement";

interface PeuplementProps {
    diagnosticsPeuplement: DiagnosticPeuplement;
}

interface VigilanceSquaresProps {
    value: number;
}

interface DiebackProgressProps {
    value: number;
}

interface TemperatureSectionProps {
    temperature: DiagnosticTemperature;
    label: string;
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        maxWidth: 900,
        marginHorizontal: 'auto',
        padding: 5,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    temperatureContainer: {
        width: '80%',
        marginHorizontal: 'auto',
        marginVertical: 15,
        border: '1pt solid #e0e0e0',
        borderRadius: 4,
        breakInside: 'avoid',
    },
    temperatureHeader: {
        padding: 8,
    },
    temperatureTitle: {
        fontSize: 12,
        textAlign: 'left',
        paddingHorizontal: 15,
        paddingVertical: 5,
        fontWeight: 900,
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
        marginHorizontal: 15,
        width: '90%',
    },
    temperatureContent: {
        padding: 10,
    },
    vigilanceSquaresContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 5,
        marginBottom: 5,
    },
    vigilanceSquare: {
        width: 25,
        height: 25,
        borderRadius: 3,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: '#cccccc',
    },
    vigilanceSquareText: {
        fontSize: 10,
        fontWeight: 'bold',
    },
    deperissementRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
    },
    deperissementLabel: {
        fontSize: 10,
        fontWeight: 'bold',
        width: '30%',
        marginRight: 5,
    },
    deperissementValue: {
        fontSize: 8,
        fontWeight: 'bold',
        marginRight: 10,
    },
    progressBarContainer: {
        flexGrow: 1,
        height: 15,
        backgroundColor: '#f5f5f5',
        borderRadius: 4,
        overflow: 'hidden',
        marginTop: 5,
        width: '100%',
    },
    progressBar: {
        height: '100%',
    },
});

const VigilanceSquares: React.FC<VigilanceSquaresProps> = ({value}) => {
    return (
        <View style={styles.vigilanceSquaresContainer}>
            {[...Array(10)].map((_, index) => (
                <View
                    key={index}
                    style={[
                        styles.vigilanceSquare,
                        {
                            backgroundColor: index + 1 === value ? VigilenceColors.getVigilenceColor(value) : 'white',
                        }
                    ]}
                >
                    <Text
                        style={[
                            styles.vigilanceSquareText,
                            {color: index + 1 === value ? 'white' : 'black'}
                        ]}
                    >
                        {index + 1}
                    </Text>
                </View>
            ))}
        </View>
    );
};

const DiebackProgress: React.FC<DiebackProgressProps> = ({value}) => {
    const color = getIndexColorDieback(value);

    return (
        <View style={styles.progressBarContainer}>
            <View
                style={[
                    styles.progressBar,
                    {
                        width: `${value}%`,
                        backgroundColor: VigilenceColors.getVigilenceColor(color)
                    }
                ]}
            />
        </View>
    );
};

const TemperatureSection: React.FC<TemperatureSectionProps> = ({temperature, label}) => {
    return (
        <View style={styles.temperatureContainer}>
            <View style={styles.temperatureHeader}>
                <Text style={styles.temperatureTitle}>
                    {label}
                </Text>
                <View style={styles.divider}/>
            </View>
            <View style={styles.temperatureContent}>
                <VigilanceSquares value={temperature.vigilence}/>

                <View style={styles.deperissementRow}>
                    <Text style={styles.deperissementLabel}>
                        Probabilité de dépérissement
                    </Text>

                    <Text style={styles.deperissementValue}>
                        {temperature.deperissement}%
                    </Text>

                    <View style={{width: '100%'}}>
                        <DiebackProgress value={temperature.deperissement}/>
                    </View>
                </View>
            </View>
        </View>
    );
};

const PeuplementPDF: React.FC<PeuplementProps> = ({diagnosticsPeuplement}) => {
    const formatEssence = (essence: string): string => {
        return essence.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <View style={styles.container}>
            <View style={styles.titleContainer}>
                <Text style={styles.title}>
                    {formatEssence(diagnosticsPeuplement.essence)}
                </Text>
            </View>

            <View style={{overflow: 'hidden'}}>
                <View style={{alignItems: 'center'}}>
                    <TemperatureSection
                        temperature={diagnosticsPeuplement.temperature[0]}
                        label="Climat 1981-2010"
                    />

                    <TemperatureSection
                        temperature={diagnosticsPeuplement.temperature[1]}
                        label="Climat 1981-2010 - Scénario +1°C"
                    />

                    <TemperatureSection
                        temperature={diagnosticsPeuplement.temperature[2]}
                        label="Climat 1981-2010 - Scénario +2°C"
                    />
                </View>
            </View>
        </View>
    );
};

export default PeuplementPDF;