import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { Route, Routes, useLocation, Location, Navigate } from 'react-router-dom';
import { AuthToken } from '../../api/token_api';
import { UserApi } from '../../api/users_api';
import { User } from '../../interfaces/user_interface';
import { AlgosChangelogs } from '../../routes/algos_changelog/algos_changelog_route';
import { AlgosJS } from '../../routes/algos_js/algos_js_route';
import { Apk } from '../../routes/apk/apk_route';
import NeedPasswordChange from '../../routes/authentification/need_password_change_route';
import  Luf from '../../routes/luf/luf_route';
import { Credits } from '../../routes/credits/credits_route';
import { Funders } from '../../routes/funders/funders_route';
import { EditionGeorestriction, EditionGeorestrictionMiddleWare } from '../../routes/georestrictions/edition_georestriction';
import { Georestrictions } from '../../routes/georestrictions/georestrictions_route';
import Home from '../../routes/home/home_route';
import { Rasters } from '../../routes/rasters/rasters_route';
import { Structures } from '../../routes/structures/structures_route';
import { EditionUser, EditionUserMiddleWare } from '../../routes/users/edition_user';
import { Users } from '../../routes/users/users_route';
import { Export } from '../../routes/export/export_route';
import { Profil } from "../../routes/profil/profil_route";
import { Projects } from "../../routes/projets/projets_route";
import Popup from "../../routes/popup/popup_route";
import { ViewProjectMiddleWare } from "../../routes/projets/details_projets";
import PDFViewerPage from "../../routes/projets/pdf/PDFViewer";
import SideNav from "../Navigation/SideNav/SideNav";
import Ppd from "../../routes/ppd/ppd_route";

const commonRoutes = [
    { path: "/export", element: Export },
    { path: "/monprofil", element: (props: { user: User }) => <Profil current_user={props.user.id}/> },
    { path: "/mesprojets", element: (props: { user: User }) => <Projects current_user={props.user.id}/> },
    { path: "/mesprojets/:id", element: ViewProjectMiddleWare },
    { path: "/mesprojets/:id/pdf", element: PDFViewerPage }
];

const adminRoutes = [
    { path: "/structures", element: Structures },
    { path: "/utilisateurs", element: Users },
    { path: "/utilisateurs/create", element: EditionUser },
    { path: "/utilisateurs/edit/:id", element: EditionUserMiddleWare },
    { path: "/georestrictions", element: Georestrictions },
    { path: "/georestrictions/create", element: EditionGeorestriction },
    { path: "/georestrictions/edit/:id", element: EditionGeorestrictionMiddleWare },
    { path: "/credits", element: Credits },
    { path: "/financeurs", element: Funders },
    { path: "/admin/luf", element: Luf },
    { path: "/admin/ppd", element: Ppd },
    { path: "/versions_algos", element: AlgosChangelogs },
    { path: "/algos_js", element: AlgosJS },
    { path: "/rasters", element: Rasters },
    { path: "/popup", element: Popup }
];

const RenderRoutes: React.FC<{user: User}> = ({user}) => {
    const routes = [...commonRoutes, ...(user.isAdmin ? adminRoutes : [])];

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/accueil" element={<Home />} />
            <Route path="/apk" element={<Apk />} />
            {routes.map(({path, element: Element}) => (
                <Route
                    key={path}
                    path={path}
                    element={React.isValidElement(Element) ? Element : <Element user={user} />}
                />
            ))}
        </Routes>
    );
};

export function DashboardMiddleWare(): React.ReactElement {
    return <Dashboard location={useLocation()} />;
}

interface DashboardProps {
    location: Location;
}

interface DashboardState {
    user?: User;
    error?: boolean;
    token?: string | null;
}

export class Dashboard extends React.Component<DashboardProps, DashboardState> {
    constructor(props: DashboardProps) {
        super(props);
        this.state = {
            error: false,
        };
    }

    componentDidMount(): void {
        this.load();
    }

    async load() {
        try {
            const user = await UserApi.getInfoCurrentUser();
            this.setState({ user });
        } catch (e) {
            console.error(e);
            this.setState({ error: true });
        }
    }

    render() {
        if (!AuthToken.has()) {
            return <Navigate to="/login" />;
        }

        if (this.state.error) {
            return <Navigate to="/login" />;
        }

        if (!this.state.user) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.user.needPasswordChange && !this.state.user.isCnpf) {
            return <NeedPasswordChange user={this.state.user} />;
        }

        return (
            <Box>
                <Box sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                }}>
                    <SideNav current_user={this.state.user} />
                    <Box sx={{ width: 1, height: 1, overflow: 'auto' }}>
                        <RenderRoutes user={this.state.user} />
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default Dashboard;