import {mdiDelete, mdiLockReset, mdiPencil} from '@mdi/js'
import Icon from '@mdi/react'
import {
    Button,
    Checkbox,
    List,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TableSortLabel,
    Tooltip,
} from '@mui/material'
import {Box} from '@mui/system'
import React from 'react'
import {Link} from 'react-router-dom'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import {RenewLicence} from '../../components/Popup/RenewLicence/RenewLicence'
import {UserApi} from '../../api/users_api'
import {User} from '../../interfaces/user_interface'
import {toast} from "react-toastify";
import success = toast.success;

export interface UserTableProps {
    users: User[];
    reload: () => Promise<void>;
    onSort?: (sortField: string, sortDirection: 'asc' | 'desc') => void;
}

interface TableState {
    all_checked: boolean
    checked_users: Set<number>
    delete: boolean
    change_password: boolean // TODO : remplacer delete & chnage_password par un enum d'action initialisé à null
    action_user_id: number
    new_password: string // TODO : supprimer
    renew_licence: boolean
    new_date: number,
    orderBy: keyof User;
    order: 'asc' | 'desc';
}

const sortLabelStyle = {
    color: '#ffffff !important',
    '&.MuiTableSortLabel-active': {
        color: '#ffffff !important',
        fontWeight: 'bold',
    },
    '& .MuiTableSortLabel-icon': {
        color: '#ffffff !important',
    },
};

const headerCellStyle = {
    color: '#ffffff',
    padding: '13px 10px',
    fontSize: '0.875rem'
};

const bodyCellStyle = {
    padding: '4px 3px',
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

export class UserTable extends React.Component<UserTableProps, TableState> {
    constructor(props: any) {
        super(props)
        this.state = {
            all_checked: false, // TODO : supprimer et remplacer par un simple getter
            checked_users: new Set(),
            delete: false,
            change_password: false,
            action_user_id: NaN,
            new_password: '',
            renew_licence: false,
            new_date: NaN,
            orderBy: 'timeCreate',
            order: 'desc'
        }

        this.checkUser = this.checkUser.bind(this)
        this.uncheckUser = this.uncheckUser.bind(this)
        this.groupedLicenceRenewal = this.groupedLicenceRenewal.bind(this)
    }

    async toggleAllCheck() {
        this.setState({all_checked: !this.state.all_checked})

        await this.checkAllUser()
    }

    async checkAllUser() {
        if (!this.state.all_checked) {
            this.props.users.forEach(async (user: User) => await this.checkUser(user.id!))
        } else {
            this.props.users.forEach(async (user: User) => await this.uncheckUser(user.id!))
        }
    }

    async toggleThisCheck(user_id: number) {
        if (this.getUserCheckedStatus(user_id)) {
            await this.uncheckUser(user_id)
        } else {
            await this.checkUser(user_id)
        }
    }

    async checkUser(user_id: number) {
        this.setState(({checked_users}) => ({
            checked_users: new Set(checked_users).add(user_id),
        }))
    }

    async uncheckUser(user_id: number) {
        this.setState(({checked_users}) => {
            const new_checked = new Set(checked_users)
            new_checked.delete(user_id)

            return {
                checked_users: new_checked,
            }
        })

    }

    getUserCheckedStatus(user_id: number) {
        return this.state.checked_users.has(user_id)
    }

    async updateLicenceRenew(user_id: number, newDate: number) {
        let user = await UserApi.getUser(user_id)
        user.expireOn = newDate
        UserApi.updateLicenceRenew(user).then()
    }

    async forEachLicenceRenewal(newDate: number) {
        const promises = Array.from(this.state.checked_users).map(id =>
            this.updateLicenceRenew(id, newDate)
        );
        await Promise.all(promises);
    }

    async forEachMakeActive() {
        const promises = Array.from(this.state.checked_users).map(id =>
            this.makeActive(id)
        );
        await Promise.all(promises);
    }

    async forEachMakeInactive() {
        const promises = Array.from(this.state.checked_users).map(id =>
            this.makeInactive(id)
        );
        await Promise.all(promises);
    }

    async forEachDelete() {
        const promises = Array.from(this.state.checked_users).map(id =>
            UserApi.deleteUser(id)
        );
        await Promise.all(promises);
    }

    async groupedLicenceRenewal(newDate: number) {
        await this.forEachLicenceRenewal(newDate)
        await this.props.reload()
        success('Licence(s) renouvelée(s) avec succès')
    }

    async groupedMakeActive() {
        await this.forEachMakeActive()
        await this.props.reload()
        success('Utilisateur(s) activé(s) avec succès')
    }

    async groupedMakeInactive() {
        await this.forEachMakeInactive()
        await this.props.reload()
        success('Utilisateur(s) désactivé(s) avec succès')
    }

    async groupedDelete() {
        await this.forEachDelete()
        await this.props.reload()
        success('Utilisateur(s) supprimé(s) avec succès')
    }


    async makeActive(user_id: number) {
        let user = await UserApi.getUser(user_id)
        user.isActive = true
        UserApi.updateMakeActive(user).then()
    }

    async makeInactive(user_id: number) {
        let user = await UserApi.getUser(user_id)
        user.isActive = false
        UserApi.updateMakeInactive(user)
    }

    getNumberOfDaysLastUsed(user: User) {
        if (!user.devices || user.devices.length === 0) {
            return "Non connecté";
        }

        const lastUsedTimestamp = Math.max(
            ...user.devices.map(device => device.lastUsed)
        );
        const lastUsedDate = new Date(lastUsedTimestamp * 1000);
        return lastUsedDate.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        });
    }

    handleRequestSort = (property: keyof User) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';

        this.setState({
            order: newOrder,
            orderBy: property
        });

        if (this.props.onSort) {
            this.props.onSort(property as string, newOrder);
        }
    };

    render() {
        return (
            <>
                <TableContainer
                    component={Paper}
                    sx={{
                        overflowX: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                        borderRadius: 1
                    }}
                >
                    <Table
                        sx={{
                            tableLayout: 'fixed',
                            width: '100%',
                            borderCollapse: 'collapse'
                        }}
                        size="small"
                    >
                        <TableHead sx={{backdropFilter: 2, backgroundColor: 'primary.main'}}>
                            <TableRow>
                                <TableCell align="center" sx={{...headerCellStyle, width: '40px'}}>
                                    <Checkbox
                                        size="small"
                                        sx={{
                                            color: '#ffffff',
                                            '&.Mui-checked': {
                                                color: '#ffffff',
                                            },
                                            padding: '2px'
                                        }}
                                        onChange={() => {
                                            this.toggleAllCheck().then()
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '110px'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'firstname'}
                                        direction={this.state.orderBy === 'firstname' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('firstname')}
                                        sx={sortLabelStyle}
                                    >
                                        Prénom
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '110px'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'lastname'}
                                        direction={this.state.orderBy === 'lastname' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('lastname')}
                                        sx={sortLabelStyle}
                                    >
                                        Nom
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '180px'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'email'}
                                        direction={this.state.orderBy === 'email' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('email')}
                                        sx={sortLabelStyle}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '90px', textAlign: 'center'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'structureId'}
                                        direction={this.state.orderBy === 'structureId' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('structureId')}
                                        sx={sortLabelStyle}
                                    >
                                        Structure
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '130px', textAlign: 'center'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'devices'}
                                        direction={this.state.orderBy === 'devices' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('devices')}
                                        sx={sortLabelStyle}
                                    >
                                        Dernière connexion
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '90px'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'expireOn'}
                                        direction={this.state.orderBy === 'expireOn' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('expireOn')}
                                        sx={sortLabelStyle}
                                    >
                                        Expire le
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '90px', textAlign: 'center'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'projectCount'}
                                        direction={this.state.orderBy === 'projectCount' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('projectCount')}
                                        sx={sortLabelStyle}
                                    >
                                        Projets
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '60px', textAlign: 'center'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'isCnpf'}
                                        direction={this.state.orderBy === 'isCnpf' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('isCnpf')}
                                        sx={sortLabelStyle}
                                    >
                                        CNPF
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '60px', textAlign: 'center'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'isActive'}
                                        direction={this.state.orderBy === 'isActive' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('isActive')}
                                        sx={sortLabelStyle}
                                    >
                                        Actif
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '60px', textAlign: 'center'}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'isAdmin'}
                                        direction={this.state.orderBy === 'isAdmin' ? this.state.order : 'asc'}
                                        onClick={() => this.handleRequestSort('isAdmin')}
                                        sx={sortLabelStyle}
                                    >
                                        Admin
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{...headerCellStyle, width: '100px'}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.users.length > 0 ? (
                                this.props.users.map((user: any) => (
                                    <TableRow
                                        key={user.id}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'background.default',
                                            },
                                        }}
                                    >
                                        <TableCell
                                            key={user.id}
                                            sx={{...bodyCellStyle, width: '40px', textAlign: 'center'}}
                                        >
                                            <input
                                                type="checkbox"
                                                id={user.id}
                                                onChange={() => {
                                                    this.toggleThisCheck(user.id).then()
                                                }}
                                                checked={this.getUserCheckedStatus(user.id)}
                                                style={{margin: 0}}
                                            />
                                        </TableCell>
                                        <TableCell sx={{...bodyCellStyle, width: '110px'}}>
                                            {user.firstname}
                                        </TableCell>
                                        <TableCell sx={{...bodyCellStyle, width: '110px'}}>
                                            {user.lastname}
                                        </TableCell>
                                        <TableCell sx={{...bodyCellStyle, width: '180px'}}>
                                            {user.email}
                                        </TableCell>
                                        <TableCell sx={{...bodyCellStyle, width: '90px', textAlign: 'center'}}>
                                            {user.structure_name ?? "Aucune"}
                                        </TableCell>
                                        <TableCell sx={{...bodyCellStyle, width: '130px', textAlign: 'center'}}>
                                            {this.getNumberOfDaysLastUsed(user)}
                                        </TableCell>
                                        <TableCell sx={{...bodyCellStyle, width: '90px'}}>
                                            {new Date(user.expireOn * 1000).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell sx={{...bodyCellStyle, width: '90px', textAlign: 'center'}}>
                                            {user.projectCount ?? 0}
                                        </TableCell>
                                        <UserBooleanCell
                                            bool={user.isCnpf}
                                            toggle_active={false}
                                            user_id={user.id}
                                            reload={this.props.reload}
                                            width="60px"
                                        />
                                        <UserBooleanCell
                                            bool={user.isActive}
                                            toggle_active={true}
                                            user_id={user.id}
                                            reload={this.props.reload}
                                            width="60px"
                                        />
                                        <UserBooleanCell
                                            bool={user.isAdmin}
                                            toggle_active={false}
                                            user_id={user.id}
                                            reload={this.props.reload}
                                            width="60px"
                                        />
                                        <TableCell sx={{py: 0}}>
                                            <Box>
                                                <List>
                                                    <ListItem sx={{p: 0, my: 1}}>
                                                        <Link
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            to={`edit/${user.id}`}
                                                        >
                                                            <Tooltip
                                                                title="Éditer"
                                                                placement="right"
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{p: 0}}
                                                                >
                                                                    <Icon
                                                                        path={
                                                                            mdiPencil
                                                                        }
                                                                    />
                                                                </Button>
                                                            </Tooltip>
                                                        </Link>
                                                    </ListItem>
                                                    <ListItem sx={{p: 0, my: 1}}>
                                                        <Tooltip
                                                            title="Supprimer"
                                                            placement="right"
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        action_user_id:
                                                                        user.id,
                                                                        delete: true,
                                                                    })
                                                                }}
                                                                sx={{p: 0, px: 1}}
                                                                color="error"
                                                            >
                                                                <Icon
                                                                    path={mdiDelete}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </ListItem>
                                                    {!user.isCnpf ? (
                                                        <ListItem
                                                            sx={{p: 0, my: 1}}
                                                        >
                                                            <Tooltip
                                                                title="Modifier le mot de passe"
                                                                placement="right"
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                action_user_id:
                                                                                user.id,
                                                                                change_password:
                                                                                    true,
                                                                            }
                                                                        )
                                                                    }}
                                                                    sx={{
                                                                        p: 0,
                                                                        px: 1,
                                                                    }}
                                                                    color="info"
                                                                >
                                                                    <Icon
                                                                        path={
                                                                            mdiLockReset
                                                                        }
                                                                    />
                                                                </Button>
                                                            </Tooltip>
                                                        </ListItem>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </List>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell sx={{py: 2}} colSpan={12}>
                                        Aucun utilisateur trouvé !
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.delete ? (
                    <Areyousure
                        message="supprimer un utilisateur"
                        action={async () => {
                            await UserApi.deleteUser(this.state.action_user_id)
                            success('Utilisateur supprimé avec succès')
                        }}
                        reload={async () => {
                            await this.props.reload()
                        }}
                        close={() => {
                            this.setState({delete: false})
                        }}
                    />
                ) : (
                    <></>
                )}
                {this.state.change_password ? (
                    <Areyousure
                        message="modifier le mot de passe de l'utilisateur"
                        action={async () => {
                            // TODO : créer une méthode de classe
                            const response = await UserApi.resetPassword(
                                this.state.action_user_id
                            )
                            this.setState({new_password: response.password})
                        }}
                        reload={async () => {
                            await this.props.reload()
                            alert(
                                `Veuillez copier le nouveau mot de passe : ${this.state.new_password}`
                            )
                        }}
                        close={() => {
                            this.setState({change_password: false})
                        }}
                    />
                ) : (
                    <></>
                )}
                {this.state.renew_licence ? (
                    <RenewLicence
                        onClose={() => {
                            this.setState({
                                renew_licence: !this.state.renew_licence,
                            })
                        }
                        }
                        onSubmit={this.groupedLicenceRenewal}
                    />
                ) : (
                    <></>
                )}
            </>
        )
    }
}

function UserBooleanCell(props: {
    bool: boolean
    toggle_active: boolean
    user_id: number
    reload: () => void
    width: string
}) {
    return (
        <TableCell
            sx={{
                color: props.bool ? 'text.primary' : 'text.disabled',
                padding: '4px 3px',
                fontSize: '0.875rem',
                width: props.width,
                textAlign: 'center'
            }}
        >
            {props.toggle_active ? (
                <Tooltip
                    title={props.bool ? 'Désactiver' : 'Activer'}
                    placement="right"
                >
                    <Button
                        onClick={async () => {
                            await UserApi.toggleActive(props.user_id)
                            props.reload()
                        }}
                        sx={{
                            color: 'text.primary',
                            p: 0,
                            minWidth: 'unset',
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                        }}
                    >
                        {props.bool ? 'OUI' : 'NON'}
                    </Button>
                </Tooltip>
            ) : (
                <span style={{fontWeight: 'bold'}}>{props.bool ? 'OUI' : 'NON'}</span>
            )}
        </TableCell>
    )
}