import React, { useCallback } from 'react';
import {
    Box,
    Button,
    Pagination,
    PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Typography,
    Alert,
    Snackbar,
} from '@mui/material';
import { Project } from '../../interfaces/project_interface';
import { ProjectRow } from './project_row';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export interface ProjectsTableProps {
    projects: Project[];
    loading: boolean;
    error: string | null;
    showErrorFlash: boolean;
    pagination: {
        page: number;
        limit: number;
        totalItems: number;
        totalPages: number;
    };

    title: string;
    downloadButtonText: string;
    emptyMessage?: string;

    onPageChange: (page: number) => void;
    onDownload: () => void;
    onErrorClose: () => void;
    downloading: boolean;
}

export const ProjectsTable: React.FC<ProjectsTableProps> = ({
                                                                projects,
                                                                loading,
                                                                error,
                                                                showErrorFlash,
                                                                pagination,
                                                                title,
                                                                downloadButtonText,
                                                                emptyMessage = "Aucun projet à afficher",
                                                                onPageChange,
                                                                onDownload,
                                                                onErrorClose,
                                                                downloading,
                                                            }) => {
    const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        onPageChange(page);
    }, [onPageChange]);

    const renderPaginationItem = (item: any) => {
        if (loading && (item.type === 'next' || item.type === 'previous')) {
            return (
                <PaginationItem
                    {...item}
                    slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon
                    }}
                    disabled={true}
                    sx={{ position: 'relative' }}
                >
                    {item.type === 'previous' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
                    <CircularProgress
                        size={16}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-8px',
                            marginLeft: '-8px'
                        }}
                    />
                </PaginationItem>
            );
        }

        if (loading && item.selected) {
            return (
                <PaginationItem
                    {...item}
                    sx={{ position: 'relative' }}
                >
                    {item.page}
                    <CircularProgress
                        size={16}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-8px',
                            marginLeft: '-8px'
                        }}
                    />
                </PaginationItem>
            );
        }

        return (
            <PaginationItem
                slots={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon
                }}
                {...item}
            />
        );
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5">{title}</Typography>
            </Box>

            <Snackbar
                open={showErrorFlash}
                autoHideDuration={6000}
                onClose={onErrorClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={onErrorClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <Button
                sx={{ mb: 2, mr: 2 }}
                variant="outlined"
                onClick={onDownload}
                disabled={downloading || loading}
                startIcon={downloading ? <CircularProgress size={16} /> : null}
            >
                {downloading ? 'Téléchargement en cours...' : downloadButtonText}
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead
                        sx={{
                            backdropFilter: 2,
                            backgroundColor: 'primary.main',
                        }}
                    >
                        <TableRow>
                            <TableCell sx={{ color: '#ffffff' }}>Nom du projet</TableCell>
                            <TableCell sx={{ color: '#ffffff' }}>Opérateur</TableCell>
                            <TableCell sx={{ color: '#ffffff' }}>Télécharger</TableCell>
                            <TableCell sx={{ color: '#ffffff' }}>Télécharger</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects && projects.length > 0 ? (
                            projects.map((project) => (
                                <ProjectRow key={project.id} project={project} />
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    {loading ? 'Chargement des données...' : emptyMessage}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 2
                }}>
                    <Pagination
                        count={pagination?.totalPages || 1}
                        page={pagination?.page || 1}
                        onChange={handlePageChange}
                        disabled={loading}
                        renderItem={renderPaginationItem}
                    />
                </Box>
            </TableContainer>
        </Box>
    );
};