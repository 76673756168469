import React from 'react';
import DocumentCreation from '../../../components/common/DocumentCreation';
import { PPD } from '../../../interfaces/ppd_interface';

interface PpdCreationProps {
    currentPpd: PPD | null;
    setCurrentPpd: (ppd: PPD) => void;
    newVersion: string;
    setNewVersion: (version: string) => void;
    setShowConfirmation: (show: boolean) => void;
    setCurrentTab: (tab: number) => void;
    disabled?: boolean;
}

const PpdCreation: React.FC<PpdCreationProps> = ({
                                                     currentPpd,
                                                     setCurrentPpd,
                                                     newVersion,
                                                     setNewVersion,
                                                     setShowConfirmation,
                                                     setCurrentTab,
                                                     disabled = false
                                                 }) => {
    return (
        <DocumentCreation<PPD>
            currentDocument={currentPpd}
            setCurrentDocument={setCurrentPpd}
            newVersion={newVersion}
            setNewVersion={setNewVersion}
            setShowConfirmation={setShowConfirmation}
            setCurrentTab={setCurrentTab}
            placeholder={{
                title: "Titre de la politique de protection des données",
                content: "Contenu de la politique de protection des données..."
            }}
            disabled={disabled}
        />
    );
};

export default PpdCreation;