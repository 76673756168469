import React from 'react';
import { TableCell, TableSortLabel, Tooltip, Box, CircularProgress } from '@mui/material';

interface SortableTableHeaderProps {
    label: string;
    field: string;
    currentSort?: string;
    currentDirection?: 'asc' | 'desc';
    onSort: (field: string, direction: 'asc' | 'desc') => void;
    sx?: any;
    tooltip?: string;
    isLoading?: boolean;
}

export const SortableTableHeader: React.FC<SortableTableHeaderProps> = ({
                                                                            label,
                                                                            field,
                                                                            currentSort,
                                                                            currentDirection = 'asc',
                                                                            onSort,
                                                                            sx,
                                                                            tooltip,
                                                                            isLoading = false
                                                                        }) => {
    const handleSort = () => {
        const newDirection = currentSort === field && currentDirection === 'asc' ? 'desc' : 'asc';
        onSort(field, newDirection);
    };

    const sortLabelContent = (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: sx?.textAlign === 'center' ? 'center' : 'flex-start' }}>
            <TableSortLabel
                active={currentSort === field}
                direction={currentSort === field ? currentDirection : 'asc'}
                onClick={handleSort}
                sx={{
                    color: 'inherit !important',
                    '& .MuiTableSortLabel-icon': {
                        color: 'inherit !important'
                    }
                }}
            >
                {label}
            </TableSortLabel>
            {isLoading && currentSort === field && (
                <CircularProgress size={16} sx={{ ml: 1 }} />
            )}
        </Box>
    );

    const cellContent = tooltip ? (
        <Tooltip title={tooltip} placement="top">
            {sortLabelContent}
        </Tooltip>
    ) : (
        sortLabelContent
    );

    return (
        <TableCell
            sx={{
                ...sx,
                padding: '13px 10px',
                fontSize: '0.875rem'
            }}
        >
            {cellContent}
        </TableCell>
    );
};