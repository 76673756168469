import {ApiClient} from "./api_client";
import {Popup} from "../interfaces/popup_interface";

export interface PopupResponse {
    data: Popup[];
    pagination: {
        page: number;
        limit: number;
        totalItems: number;
        totalPages: number;
    }
}

export class PopupApi {

    /**
     * Créer un nouveau popup
     * @param popup Popup
     * @returns Popup
     */
    static async createPopup(popup: Popup): Promise<Popup> {
        try {
            return await ApiClient.post('/popup/create', {
                body: JSON.stringify(popup)
            })
        } catch (e) {
            console.error('Erreur détaillée:', e);
            throw new Error(
                `Echec de la création du popup`
            )
        }
    }


    /**
     * Récupérer tous les popups avec pagination
     * @param page Numéro de la page à récupérer
     * @returns Réponse paginée contenant les popups et les informations de pagination
     */
    static async getAll(page: number = 1): Promise<PopupResponse> {
        try {
            return await ApiClient.get('/popup', {
                params: {
                    page
                }
            });
        } catch (e) {
            throw new Error(`Echec de la récupération des popups`);
        }
    }

    /**
     * Mettre à jour un popup
     * @returns Popup
     */
    static async update(popup: Popup): Promise<Popup> {
        try {
            return await ApiClient.put(`/popup/${popup.id}`, {
                body: JSON.stringify(popup)
            });
        } catch (e) {
            throw new Error(
                `Echec de la modification du popup`
            );
        }
    }

    /**
     * Récupérer un popup par son ID
     * @returns Popup
     */
    static async getById(popupId: number): Promise<Popup> {
        try {
            return await ApiClient.get(`/popup/${popupId}`)
        } catch (e) {
            throw new Error(
                `Echec de la récupération des popups`
            )
        }

    }
   /**
     * Supprimer un popup et tous ses médias associés
     * @param popupId ID du popup
     */
    static async delete( popupId: number,): Promise<void> {
        try {
            await ApiClient.delete(`/popup/${popupId}`);
        } catch (e) {
            throw new Error(
                `Échec de la suppression du popup}`
            );
        }
    }
}