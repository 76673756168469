import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useConsent } from "../Contexts/ConsentProvider";
import { useAuth } from "../Contexts/AuthContext";
import { User } from '../interfaces/user_interface';

interface AuthContextType {
    currentUser: User | null;
}

interface ProtectedRouteProps {
    children: React.ReactNode;
    requiresConsent?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
                                                           children,
                                                           requiresConsent = true
                                                       }) => {
    const { currentUser } = useAuth() as AuthContextType;
    const { checkConsents } = useConsent();
    const hasAuthToken = !!localStorage.getItem('userToken');
    const consentChecked = useRef<boolean>(false);

    useEffect(() => {
        if ((currentUser || hasAuthToken) && !consentChecked.current && requiresConsent) {
            consentChecked.current = true;

            const verifyConsent = async () => {
                try {
                    await checkConsents();
                } catch (error) {
                    console.error("Erreur lors de la vérification des consentements:", error);
                    consentChecked.current = false;
                }
            };

            verifyConsent();
        }
        //  eslint-disable-next-line
    }, [currentUser, hasAuthToken]);


    if (!currentUser && !hasAuthToken) {
        return <Navigate to="/login" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;