import React, { useState, useEffect } from 'react';
import AppHeader from "../../components/common/AppHeader";
import { PPD } from "../../interfaces/ppd_interface";
import { PPDApi } from "../../api/ppd_api";
import PpdList from './components/PpdList';
import PpdDetails from './components/PpdDetails';
import PpdCreation from './components/PpdCreation';
import PpdConfirmationDialogs from './components/PpdConfirmationDialogs';
import { toast } from "react-toastify";
import { useAuth } from "../../Contexts/AuthContext";
import {
    PageContainer,
    LoadingSpinner,
    TwoColumnLayout,
    TabBar,
    EmptySelectionMessage
} from '../../components/common/UIComponents';

const Ppd: React.FC = () => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [actionLoading, setActionLoading] = useState<boolean>(false);
    const [ppds, setPpds] = useState<PPD[]>([]);
    const [currentPpd, setCurrentPpd] = useState<PPD | null>(null);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [editing, setEditing] = useState<boolean>(false);
    const [creating, setCreating] = useState<boolean>(false);
    const [newVersion, setNewVersion] = useState<string>('');

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showOnlineConfirmation, setShowOnlineConfirmation] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    useEffect(() => {
        loadPpds();
    }, []);

    const loadPpds = async (selectNewlyCreatedId?: number) => {
        setLoading(true);
        try {
            const ppds = await PPDApi.getAll();
            setPpds(ppds);

            if (selectNewlyCreatedId) {
                const selectedPpd = ppds.find(ppd => ppd.id === selectNewlyCreatedId);
                if (selectedPpd) {
                    setCurrentPpd(selectedPpd);
                } else {
                    setCurrentPpd(ppds.find(ppd => ppd.online) || ppds[0]);
                }
            } else {
                setCurrentPpd(ppds.find(ppd => ppd.online) || ppds[0]);
            }

            setLoading(false);
        } catch (err) {
            toast.error('Échec du chargement des Politiques de protection des données');
            setLoading(false);
        }
    };

    const createPpd = async () => {
        if (!currentPpd || !currentPpd.content || !newVersion || !currentPpd.title) {
            toast.error('Veuillez remplir tous les champs');
            return;
        }
        setActionLoading(true);
        try {
            const newPpd: PPD = {
                createdBy: currentUser?.id!,
                title: currentPpd.title,
                version: newVersion,
                content: currentPpd.content,
                online: false
            };
            const result = await PPDApi.create(newPpd);

            toast.success('Politique de protection des données créée avec succès');
            setCreating(false);
            setShowConfirmation(false);
            setNewVersion('');
            setCurrentTab(0);

            await loadPpds(result.id);

        } catch (err) {
            toast.error("Échec lors de la création de la Politique de protection des données");
        } finally {
            setActionLoading(false);
        }
    };

    const updatePpd = async () => {
        if (!currentPpd) return;

        setActionLoading(true);
        try {
            const updatedPpd = {
                ...currentPpd,
                updatedBy: currentUser?.id!
            };
            await PPDApi.update(updatedPpd);
            const updatedId = currentPpd.id;

            toast.success('Politique de protection des données mise à jour avec succès');
            setEditing(false);
            setShowConfirmation(false);

            await loadPpds(updatedId);

        } catch (err) {
            toast.error('Échec de la mise à jour de la Politique de protection des données');
        } finally {
            setActionLoading(false);
        }
    };

    const setPpdOnline = async () => {
        if (!currentPpd) return;

        setActionLoading(true);
        try {
            await PPDApi.setPPDOnline(currentPpd.id!, currentUser?.id!);
            const onlineId = currentPpd.id;

            toast.success('Politique de protection des données mise en ligne avec succès');
            setShowOnlineConfirmation(false);

            await loadPpds(onlineId);

        } catch (err) {
            toast.error('Échec de la mise en ligne de la Politique de protection des données');
        } finally {
            setActionLoading(false);
        }
    };

    const deletePpd = async () => {
        if (!currentPpd) return;
        setActionLoading(true);
        try {
            await PPDApi.delete(currentPpd.id!);
            toast.success('Politique de protection des données supprimée avec succès');
            setShowDeleteConfirmation(false);
            await loadPpds();
        } catch (err: any) {
            if (err && err.message && err.message.includes("400")) {
                toast.error('Impossible de supprimer une PPD déjà acceptée ou refusée');
            } else {
                toast.error('Échec de la suppression de la Politique de protection des données');
            }
        } finally {
            setActionLoading(false);
        }
    };

    const handleTabChange = (newValue: number) => {
        setCurrentTab(newValue);
        if (newValue === 0) {
            setCreating(false);
        } else if (newValue === 1) {
            setEditing(false);
            setCreating(true);

            setCurrentPpd({
                title: '',
                version: '',
                content: '',
                online: false
            });
        }
    };

    const selectPpd = (ppd: PPD) => {
        setCurrentPpd(ppd);
        setCurrentTab(0);
        setEditing(false);
    };

    const renderTabContent = () => {
        if (currentTab === 0) {
            if (!currentPpd || (currentPpd && (!currentPpd.title && !currentPpd.version && !currentPpd.content))) {
                return (
                    <EmptySelectionMessage
                        message="Sélectionnez une politique de confidentialité dans la liste ou créez-en une nouvelle."
                    />
                );
            } else {
                return (
                    <PpdDetails
                        ppd={currentPpd}
                        editing={editing}
                        setEditing={setEditing}
                        setCurrentPpd={setCurrentPpd}
                        setShowConfirmation={setShowConfirmation}
                        setShowOnlineConfirmation={setShowOnlineConfirmation}
                        setShowDeleteConfirmation={setShowDeleteConfirmation}
                        disabled={actionLoading}
                    />
                );
            }
        } else if (currentTab === 1) {
            return (
                <PpdCreation
                    currentPpd={currentPpd}
                    setCurrentPpd={setCurrentPpd}
                    newVersion={newVersion}
                    setNewVersion={setNewVersion}
                    setShowConfirmation={setShowConfirmation}
                    setCurrentTab={setCurrentTab}
                    disabled={actionLoading}
                />
            );
        }
        return null;
    };

    return (
        <PageContainer>
            <AppHeader title="Gestion des politiques de protection des données (PPD)" />

            {loading ? (
                <LoadingSpinner />
            ) : (
                <TwoColumnLayout
                    leftColumn={
                        <PpdList
                            ppds={ppds}
                            currentPpdId={currentPpd?.id}
                            onSelectPpd={selectPpd}
                        />
                    }
                    rightColumn={
                        <>
                            <TabBar
                                currentTab={currentTab}
                                onTabChange={handleTabChange}
                                tabs={['Détails', 'Nouvelle version']}
                                loading={actionLoading}
                            />
                            {renderTabContent()}
                        </>
                    }
                />
            )}

            <PpdConfirmationDialogs
                showConfirmation={showConfirmation}
                showOnlineConfirmation={showOnlineConfirmation}
                showDeleteConfirmation={showDeleteConfirmation}
                setShowConfirmation={setShowConfirmation}
                setShowOnlineConfirmation={setShowOnlineConfirmation}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
                currentPpd={currentPpd}
                creating={creating}
                createPpd={createPpd}
                updatePpd={updatePpd}
                setPpdOnline={setPpdOnline}
                deletePpd={deletePpd}
                disabled={actionLoading}
            />
        </PageContainer>
    );
};

export default Ppd;