import React from 'react';
import DocumentList from '../../../components/common/DocumentList';
import { PPD } from '../../../interfaces/ppd_interface';

interface PpdListProps {
    ppds: PPD[];
    currentPpdId?: number;
    onSelectPpd: (ppd: PPD) => void;
}

const PpdList: React.FC<PpdListProps> = ({ ppds, currentPpdId, onSelectPpd }) => {
    return (
        <DocumentList<PPD>
            items={ppds}
            currentItemId={currentPpdId}
            onSelectItem={onSelectPpd}
            title="Liste des PPD"
            emptyMessage="Aucune Politique de confidentialité disponible"
        />
    );
};

export default PpdList;