import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { UserApi } from '../api/users_api';
import { User } from '../interfaces/user_interface';

interface AuthContextType {
    currentUser: User | null;
    loading: boolean;
    refreshUser: () => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth doit être utilisé à l\'intérieur d\'un AuthProvider');
    }

    return context;
};

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    /**
     * Récupère les informations de l'utilisateur actuellement connecté
     */
    const fetchCurrentUser = async (): Promise<void> => {
        try {
            setLoading(true);

            const user: User = await UserApi.getInfoCurrentUser();
            setCurrentUser(user);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'utilisateur:', error);
            setCurrentUser(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCurrentUser();
    }, []);

    const value: AuthContextType = {
        currentUser,
        loading,
        refreshUser: fetchCurrentUser
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;