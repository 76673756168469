import {
    get_restricted_url,
    get_unrestricted_url,
} from '../global_variables/backend_url_api'
import { AuthToken } from './token_api'

export interface ApiClientOptions {
    body?: BodyInit
    restricted?: boolean
    params?: Record<string, any>;
    responseType?: 'json' | 'blob';
    // TODO : ajout content-type ?
    // ou plutôt : un champs jsonBody qui définisse tout seul le
    // champ content-type et assure la sérialisation du JSON ?
}

export class ApiError extends Error {
    code: number

    constructor(message: string, code: number) {
        super(message)
        this.code = code
    }
}

export class ApiClient {
    static async fetch(
        method: string,
        URL: string,
        options: ApiClientOptions | undefined
    ) {
        // TODO : refactoriser ce bout de code
        let backend = get_restricted_url()
        if (options?.restricted !== undefined) {
            backend = options.restricted
                ? get_restricted_url()
                : get_unrestricted_url()
        }

        let finalURL = URL;
        if (options?.params) {
            const searchParams = new URLSearchParams();
            Object.entries(options.params).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    searchParams.append(key, String(value));
                }
            });
            const queryString = searchParams.toString();
            if (queryString) {
                finalURL += `?${queryString}`;
            }
        }

        let isMultipart = options?.body instanceof FormData;
        let headers = this.getHeaders(options?.body !== undefined, isMultipart);
        let response = await fetch(`${backend}${finalURL}`, {
            method: method,
            headers: headers,
            body: options?.body,
        })

        // TODO : simplifier cette condition en inversant les opérateurs de contrôle
        if (!(response.status >= 200 && response.status <= 299)) {
            throw new ApiError(`Error : ${response.status}`, response.status)
        }
        if (options?.responseType === 'blob') {
            return await response.blob();
        }
        let contentType = response.headers.get('content-type')
        if (contentType?.includes('application/json')) {
            return await response.json()
        }
    }

    // TODO : refactoriser ce code pour le simplifier
    // TODO : renommer la variable contentType en hasJsonBody par exemple
    static getHeaders(contentType: boolean, isMultipart: boolean = false): HeadersInit {
        let headers: HeadersInit = {};

        if (AuthToken.has()) {
            headers["Authorization"] = AuthToken.get();
        }

        // Ne pas définir 'Content-Type' si isMultipart est activé (fetch le gère automatiquement)
        if (contentType && !isMultipart) {
            headers["Content-Type"] = "application/json";
        }

        return headers;
    }


    static async get(URL: string, options?: ApiClientOptions | undefined) {
        return this.fetch('GET', URL, options);
    }

    static async post(URL: string, options: ApiClientOptions | undefined) {
        return this.fetch('POST', URL, options)
    }

    static async put(URL: string, options: ApiClientOptions | undefined) {
        return this.fetch('PUT', URL, options)
    }

    static async patch(URL: string, options: ApiClientOptions | undefined) {
        return this.fetch('PATCH', URL, options)
    }

    static async delete(URL: string, options?: ApiClientOptions | undefined) {
        return this.fetch('DELETE', URL, options)
    }
}
