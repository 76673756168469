import {ApiClient} from './api_client';
import {LUF} from "../interfaces/luf_interface";

export interface ConsentResponse {
    hasConsent: boolean;
    consentDate?: string;
    userId: number;
    lufId: number;
}

export class LUFApi {
    /**
     * Récupérer l'ensemble des LUFs
     * @returns LUF[]
     */
    static async getAll(): Promise<LUF[]> {
        try {
            return await ApiClient.get('/luf/all')
        } catch (e) {
            throw new Error(
                `Echec de la récupération des lufs`
            )
        }

    }

    /**
     * Récupération des informations d'une LUF à l'aide de son id
     * @param lufId ID de la LUF
     * @returns LUF
     */
    static async getById(lufId : number): Promise<LUF> {
        try {
            return await ApiClient.get(`/luf/${lufId}`)
        } catch (e) {
            throw new Error(
                `Echec de la récupération de la LUF`
            )
        }

    }

    /**
     * Récupération du LUF actif
     * @returns LUF
     */
    static async getOnlineLUF(): Promise<LUF> {
        try {
            return await ApiClient.get('/luf/online', {
                restricted: false,
            })
        } catch (e) {
            throw new Error(
                `Echec de la récupération des lufs`
            )
        }

    }

    /**
     * Créer une nouvelle LUF
     * @param luf LUF
     * @returns LUF
     */
    static async create(luf: LUF): Promise<LUF> {
        try {
            return await ApiClient.post('/luf/create', {
                body: JSON.stringify(luf)
            })
        } catch (e) {
            console.error('Erreur détaillée:', e);
            throw new Error(
                `Echec de la création de la luf`
            )
        }
    }

    /**
     * Mettre à jour une LUF
     * @returns LUF
     */
    static async update(luf: LUF): Promise<LUF> {
        try {
            return await ApiClient.put(`/luf/${luf.id}`, {
                body: JSON.stringify(luf)
            });
        } catch (e) {
            throw new Error(
                `Echec de la modification de la luf`
            );
        }
    }

    /**
     * Mettre en ligne une LUF
     * @returns LUF
     */
    static async setLUFOnline(id: number, updatedBy: number): Promise<LUF> {
        try {
            return await ApiClient.put(`/luf/${id}/online?updatedBy=${updatedBy}`, {});
        } catch (e) {
            throw new Error(
                `Echec de la modification de la luf`
            );
        }
    }

    /**
     * Supprimer une LUF
     * @param lufId ID de la LUF
     */
    static async delete(lufId: number,): Promise<void> {
        await ApiClient.delete(`/luf/${lufId}`);
    }

    /**
     * Récupère le consentement LUF pour un utilisateur
     * @param lufId ID de la LUF
     * @param userId ID de l'utilisateur
     * @returns Statut du consentement
     */
    static async getUserLufConsent(lufId: number, userId: number): Promise<ConsentResponse> {
        try {
            return await ApiClient.get(`/users/${userId}/lufs/${lufId}`, {
                restricted: false,
            });
        } catch (e) {
            throw new Error(`Échec de la récupération du consentement LUF`);
        }
    }

    /**
     * Mettre à jour le consentement d'un utilisateur pour un LUF
     * @param lufId ID de la LUF
     * @param userId ID de l'utilisateur
     * @param hasConsent Valeur du consentement (true=accepté, false=refusé)
     */
    static async updateUserLufConsent(lufId: number, userId: number, hasConsent: boolean): Promise<void> {
        try {
            await ApiClient.put(`/users/${userId}/lufs/${lufId}`, {

                body: JSON.stringify({
                    hasConsent: hasConsent
                }),
                restricted: false,
            });
        } catch (e) {
            throw new Error(`Échec de la mise à jour du consentement LUF`);
        }
    }


    /**
     * Récupérer tous les consentements LUF d'un utilisateur"
     * @param userId ID de l'utilisateur
     */
    static async getUserLufConsents(userId: number): Promise<ConsentResponse> {
        try {
            return await ApiClient.get(`/users/${userId}/lufs`, {
                restricted: false,
            });
        } catch (e) {
            throw new Error(`Échec de la récupération du consentement LUF`);
        }
    }
}