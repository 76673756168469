import {StyleSheet} from "@react-pdf/renderer";
import {Theme} from "@mui/material";


// pdf
export const stylesTable = StyleSheet.create({
    table: {
        width: '100%',
        backgroundColor: '#F5F5F5',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'white',
        minHeight: 40,
        alignItems: 'center',
    },
    headerCell: {
        flex: 1,
        padding: 5,
        alignItems: 'center',
        fontSize: 12,
    },
    cell: {
        flex: 1,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 10,
    },
    cellEssence: {
        flex: 1,
        padding: 5,
        alignItems: 'flex-start',
        justifyContent: 'center',
        fontSize: 10,
        marginLeft: 6,
    },
    circle: {
        width: 25,
        height: 25,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    vigilenceText: {
        color: 'white',
        fontSize: 12,
    },
    iconContainer: {
        width: 20,
        height: 60,
        borderRadius: 5,
        borderWidth: 1,
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 4,
    },
    icon: {
        width: 10,
        height: 10,
    },
});



// web
export const firstCellStyles = (theme:  Theme) => ({
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
        minWidth: '150px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        minWidth: '200px',
    },
    [theme.breakpoints.up('md')]: {
        minWidth: '300px',
    }
});

export const commonCellStyles = {
    whiteSpace: 'nowrap',
    align: 'center'
};
