import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Pagination,
    PaginationItem,
    Select,
    TextField,
} from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'
import {StructureApi} from '../../api/structures_api'
import {UserApi} from '../../api/users_api'
import {SearchInput} from '../../components/Inputs/SearchInput/SearchInput'
import {SelectInput} from '../../components/Inputs/SelectInput/SelectInput'
import {UserTable} from './user_table'
import {User} from '../../interfaces/user_interface'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AppHeader from "../../components/common/AppHeader";
import {RenewLicence} from '../../components/Popup/RenewLicence/RenewLicence';

export interface UsersProps {
}

export interface UsersFilter {
    firstname?: string
    lastname?: string
    email?: string
    isActive?: boolean
    isCnpf?: boolean
    isAdmin?: boolean
    structureId?: number
    expireIn?: number
    devices?: number
    page?: number
    sort?: string;
    sortDir?: string;
}


interface Userstate {
    users: User[]
    structures: any[]
    filters: UsersFilter
    isAdmin: boolean | null
    isCnpf: boolean | null
    isActive: boolean | null
    is_loaded: boolean
    pagination: {
        page: number
        limit: number
        totalItems: number
        totalPages: number
    }
    renew_licence: boolean
}

export class Users extends React.Component<UsersProps, Userstate> {
    private userTableRef: React.RefObject<UserTable>;

    constructor(props: UsersProps) {
        super(props)
        this.state = {
            users: [],
            structures: [],
            filters: {},
            isAdmin: null,
            isCnpf: null,
            isActive: true,
            is_loaded: false,
            pagination: {
                page: 1,
                limit: 15,
                totalItems: 0,
                totalPages: 0
            },
            renew_licence: false
        }
        this.getStructures = this.getStructures.bind(this)
        this.userTableRef = React.createRef();
        this.handleGroupedLicenceRenewal = this.handleGroupedLicenceRenewal.bind(this);
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const response = await UserApi.listUsers(this.state.filters)
            const structures = await this.getStructures()

            // Enrichir les utilisateurs avec le nom de leur structure
            const enhancedUsers = response.data.map((user: User) => {
                if (user.structureId) {
                    const structure = structures.find(s => s.value === user.structureId)
                    return {
                        ...user,
                        structure_name: structure ? structure.name : `Structure ID: ${user.structureId}`
                    }
                }
                return {
                    ...user,
                    structure_name: "Sans structure"
                }
            })

            this.setState({
                users: enhancedUsers,
                structures: structures,
                pagination: response.pagination,
                is_loaded: true
            })
        } catch (e) {
            console.error(e)
            alert('Echec du chargement de la liste des utilisateurs')
        }
    }

    async getStructures() {
        const structureList = await StructureApi.listStructures()
        const structures = structureList.map((structure: any) => {
            return {name: structure.name, value: structure.id}
        })
        return structures
    }


    updateFilters = (newFilters: Partial<UsersFilter>) => {
        const shouldResetPage = !('page' in newFilters);
        this.setState(
            prevState => ({
                filters: {
                    ...prevState.filters,
                    ...newFilters,
                    page: shouldResetPage ? 1 : newFilters.page
                }
            }),
            () => this.load()
        )
    }

    handlePageChange = async (page: number) => {
        this.updateFilters({page})
    }

    handleSort = (sortField: string, sortDirection: 'asc' | 'desc') => {
        this.updateFilters({
            sort: sortField,
            sortDir: sortDirection
        });
    }

    handleGroupedLicenceRenewal(newDate: number) {
        if (this.userTableRef.current) {
            this.userTableRef.current.groupedLicenceRenewal(newDate);
        }
    }

    render() {
        return (
            <Box sx={{p: 5}}>
                <AppHeader title="Gestion des utilisateurs" />

                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    },
                    justifyContent: 'space-between',
                    alignItems: {
                        xs: 'flex-start',
                        sm: 'center'
                    },
                    gap: 2,
                }}>
                    <Box>
                        <Link to="create">
                            <Button variant="outlined">
                                <span>Ajouter un utilisateur</span>
                            </Button>
                        </Link>
                    </Box>

                    <FormControl
                        sx={{
                            width: 200,
                            backdropFilter: 'blur(8px)',
                            backgroundColor: '#ffffff1c',
                            color: 'text.primary',
                        }}
                    >
                        <InputLabel>Action Groupée</InputLabel>
                        <Select>
                            <MenuItem
                                value={2}
                                onClick={() => {
                                    this.setState({
                                        renew_licence: true
                                    })
                                }}
                            >
                                Renouveler les Licences
                            </MenuItem>
                            <MenuItem
                                value={3}
                                onClick={() => {
                                    if (this.userTableRef.current) {
                                        this.userTableRef.current.groupedMakeActive().then();
                                    }
                                }}
                            >
                                Rendre Actif
                            </MenuItem>
                            <MenuItem
                                value={4}
                                onClick={() => {
                                    if (this.userTableRef.current) {
                                        this.userTableRef.current.groupedMakeInactive().then();
                                    }
                                }}
                            >
                                Rendre Inactif
                            </MenuItem>
                            <MenuItem
                                value={5}
                                onClick={() => {
                                    if (this.userTableRef.current) {
                                        this.userTableRef.current.groupedDelete().then();
                                    }
                                }}
                            >
                                Supprimer
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box
                    display="flex"
                    sx={{
                        py: 4,
                        flexDirection: {
                            xs: 'column',
                            sm: 'row'
                        }
                    }}
                >
                    {[
                        {
                            label: 'Prénom',
                            type: 'text',
                            changeValue: (firstname: string) => {
                                this.updateFilters({firstname})
                            },
                        },
                        {
                            label: 'Nom',
                            type: 'text',
                            changeValue: (lastname: string) => {
                                this.updateFilters({lastname})
                            },
                        },
                        {
                            label: 'Email',
                            type: 'text',
                            changeValue: (email: string) => {
                                this.updateFilters({email})
                            },
                        },
                    ].map((element, index) => (
                        <SearchInput
                            label={element.label}
                            type={element.type}
                            changeValue={element.changeValue}
                            key={index}
                        />
                    ))}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Dernière connexion avant le"
                            value={this.state.filters.devices ? dayjs.unix(this.state.filters.devices) : null}
                            onChange={(newValue: any) => {
                                if (newValue && dayjs.isDayjs(newValue)) {
                                    const endOfDay = newValue.endOf('day');
                                    this.updateFilters({
                                        devices: endOfDay.unix()
                                    });
                                } else {
                                    this.updateFilters({
                                        devices: undefined
                                    });
                                }
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                        mx: {
                                            xs: 0,
                                            sm: 2
                                        }
                                    }}/>
                            )}
                        />

                        <DesktopDatePicker
                            label="Licences expirant avant le"
                            value={this.state.filters.expireIn ? dayjs.unix(this.state.filters.expireIn) : null}
                            onChange={(newValue: any) => {
                                if (newValue && dayjs.isDayjs(newValue)) {
                                    const endOfDay = newValue.endOf('day');
                                    this.updateFilters({
                                        expireIn: endOfDay.unix()
                                    });
                                } else {
                                    this.updateFilters({
                                        expireIn: undefined
                                    });
                                }
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                        mx: {
                                            xs: 0,
                                            sm: 2
                                        }
                                    }}
                                />
                            )}
                        />

                    </LocalizationProvider>
                    <SelectInput
                        options={this.state.structures}
                        label="Structures"
                        filter={(structure_id: number) => {
                            this.updateFilters({
                                structureId: isNaN(structure_id) ? undefined : structure_id
                            })
                        }}
                        structureId={this.state.filters.structureId || ''}
                    />
                    <FormGroup sx={{color: 'text.primary',px: {
                            xs: 0,
                            sm: 4
                        }}}>
                        {[
                            {
                                name: 'Administrateurs',
                                action: (_event: any) => {
                                    const newValue = !this.state.isAdmin;
                                    this.setState({
                                        isAdmin: newValue,
                                        filters: {
                                            ...this.state.filters,
                                            isAdmin: newValue ? newValue : undefined
                                        }
                                    }, () => this.load())
                                },
                            },
                            {
                                name: 'CNPF',
                                action: (_event: any) => {
                                    const newValue = !this.state.isCnpf;
                                    this.setState({
                                        isCnpf: newValue,
                                        filters: {
                                            ...this.state.filters,
                                            isCnpf: newValue ? newValue : undefined
                                        }
                                    }, () => this.load())
                                },
                            },
                            {
                                name: 'Inactif',
                                action: (_event: any) => {
                                    const newValue = !this.state.isActive;
                                    this.setState({
                                        isActive: newValue,
                                        filters: {
                                            ...this.state.filters,
                                            isActive: !newValue ? newValue : undefined
                                        }
                                    }, () => this.load())
                                },
                            }
                        ].map((object, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        onChange={object.action}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: 'secondary.main',
                                            },
                                        }}
                                    />
                                }
                                label={object.name}
                            />
                        ))}
                    </FormGroup>
                </Box>{' '}
                {this.state.is_loaded ? (
                    <UserTable
                        ref={this.userTableRef}
                        users={this.state.users}
                        reload={async () => {
                            await this.load()
                        }}
                        onSort={this.handleSort}
                    />
                ) : (
                    <Box
                        sx={{
                            width: 1,
                            height: '500px',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress/>
                    </Box>
                )}
                {this.state.users && (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        mt: 2
                    }}>
                        <Pagination
                            count={this.state.pagination?.totalPages || 1}
                            page={this.state.pagination?.page || 1}
                            onChange={(event, page) => this.handlePageChange(page)}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Box>
                )}

                {this.state.renew_licence && (
                    <RenewLicence
                        onClose={() => {
                            this.setState({
                                renew_licence: false
                            })
                        }}
                        onSubmit={this.handleGroupedLicenceRenewal}
                    />
                )}
            </Box>
        )
    }
}