export const PropDeperisTreeEnum = {
    NON_ESTIMATED: 'Non estimée',
    ZERO: '0%',
    LOWER_20: '< 20%',
    GREATER_OR_EQUAL_20: '>= 20%',
}

export const PropDeperisTreeMapping:{ [key: string]: string }  = {
    '0': PropDeperisTreeEnum.NON_ESTIMATED,
    '1': PropDeperisTreeEnum.ZERO,
    '2': PropDeperisTreeEnum.LOWER_20,
    '3': PropDeperisTreeEnum.GREATER_OR_EQUAL_20,
};
