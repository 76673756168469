import React from 'react';
import DocumentConfirmationDialogs from '../../../components/common/DocumentConfirmationDialogs';
import { PPD } from '../../../interfaces/ppd_interface';

interface PpdConfirmationDialogsProps {
    showConfirmation: boolean;
    showOnlineConfirmation: boolean;
    showDeleteConfirmation: boolean;
    setShowConfirmation: (show: boolean) => void;
    setShowOnlineConfirmation: (show: boolean) => void;
    setShowDeleteConfirmation: (show: boolean) => void;
    currentPpd: PPD | null;
    creating: boolean;
    createPpd: () => Promise<void>;
    updatePpd: () => Promise<void>;
    setPpdOnline: () => Promise<void>;
    deletePpd: () => Promise<void>;
    disabled?: boolean;
}

const PpdConfirmationDialogs: React.FC<PpdConfirmationDialogsProps> = ({
                                                                           showConfirmation,
                                                                           showOnlineConfirmation,
                                                                           showDeleteConfirmation,
                                                                           setShowConfirmation,
                                                                           setShowOnlineConfirmation,
                                                                           setShowDeleteConfirmation,
                                                                           currentPpd,
                                                                           creating,
                                                                           createPpd,
                                                                           updatePpd,
                                                                           setPpdOnline,
                                                                           deletePpd,
                                                                           disabled = false
                                                                       }) => {
    return (
        <DocumentConfirmationDialogs<PPD>
            showConfirmation={showConfirmation}
            showOnlineConfirmation={showOnlineConfirmation}
            showDeleteConfirmation={showDeleteConfirmation}
            setShowConfirmation={setShowConfirmation}
            setShowOnlineConfirmation={setShowOnlineConfirmation}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            currentDocument={currentPpd}
            creating={creating}
            createDocument={createPpd}
            updateDocument={updatePpd}
            setDocumentOnline={setPpdOnline}
            deleteDocument={deletePpd}
            documentType="Politique de confidentialité"
            disabled={disabled}
        />
    );
};

export default PpdConfirmationDialogs;