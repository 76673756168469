import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'

// TODO : give a more explicit name (ex: SelectStructureInput) to the function & the class
// TODO : ajouter un typage fort des paramètres
// TODO : renommer le paramètre filter par onValueChange
export const SelectInput = ({options, label, filter, structureId}: any) => {
    return (
        <FormControl sx={{
            width: {
                xs: 'auto',
                sm: '0.2'
            },
        }}>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                variant="standard"
                label={label}
                id={label}
                name={label}
                onChange={(event) => {
                    filter(Number(event.target.value))
                }}
                value={structureId.toString()}
            >
                <MenuItem value={NaN.toString()}>
                    <em>Tous</em>
                </MenuItem>
                {options.map((option: any, index: number) => (
                    <MenuItem value={option.value.toString()} key={index}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
