import React from 'react'
import {LUFApi} from '../../api/luf_api'
import {LUF} from '../../interfaces/luf_interface'
import DocumentDisplay from "../../components/common/DocumentDisplay";
import {toast} from "react-toastify";
import error = toast.error;

export interface PublicLufProps {
}

export interface PublicLufState {
    loaded: boolean
    luf: LUF
}

export class PublicLuf extends React.Component<PublicLufProps, PublicLufState> {
    constructor(props: PublicLufProps) {
        super(props)

        this.state = {
            loaded: false,
            luf: {
                content: '',
                title: '',
                version: '',
                online: true
            },
        }
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const onlineLuf = await LUFApi.getOnlineLUF()

            this.setState({
                luf: onlineLuf,
                loaded: true
            })
        } catch (e) {
            console.error('Erreur lors du chargement de la LUF:', e)
            error('Échec du chargement de la licence utilisateur final')
            this.setState({loaded: true})
        }
    }

    render() {
        return (
            <DocumentDisplay
                title={this.state.luf.title}
                isLoaded={this.state.loaded}
                documentContent={this.state.luf.content}
            />
        )
    }
}

export default PublicLuf