import React, { useState, useEffect } from 'react';
import AppHeader from "../../components/common/AppHeader";
import { LUF } from "../../interfaces/luf_interface";
import { LUFApi } from "../../api/luf_api";
import LufList from './components/LufList';
import LufDetails from './components/LufDetails';
import LufCreation from './components/LufCreation';
import LufConfirmationDialogs from './components/LufConfirmationDialogs';
import { toast } from "react-toastify";
import { useAuth } from "../../Contexts/AuthContext";
import {
    PageContainer,
    LoadingSpinner,
    TwoColumnLayout,
    TabBar,
    EmptySelectionMessage
} from '../../components/common/UIComponents';

const Luf: React.FC = () => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [actionLoading, setActionLoading] = useState<boolean>(false);
    const [lufs, setLufs] = useState<LUF[]>([]);
    const [currentLuf, setCurrentLuf] = useState<LUF | null>(null);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [editing, setEditing] = useState<boolean>(false);
    const [creating, setCreating] = useState<boolean>(false);
    const [newVersion, setNewVersion] = useState<string>('');

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showOnlineConfirmation, setShowOnlineConfirmation] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    useEffect(() => {
        loadLufs();
    }, []);

    const loadLufs = async (selectNewlyCreatedId?: number) => {
        setLoading(true);
        try {
            const Lufs = await LUFApi.getAll();
            setLufs(Lufs);

            if (selectNewlyCreatedId) {
                const selectedLuf = Lufs.find(luf => luf.id === selectNewlyCreatedId);
                if (selectedLuf) {
                    setCurrentLuf(selectedLuf);
                } else {
                    setCurrentLuf(Lufs.find(luf => luf.online) || Lufs[0]);
                }
            } else {
                setCurrentLuf(Lufs.find(luf => luf.online) || Lufs[0]);
            }

            setLoading(false);
        } catch (err) {
            toast.error('Échec du chargement des LUFs');
            setLoading(false);
        }
    };

    const createLuf = async () => {
        if (!currentLuf || !currentLuf.content || !newVersion || !currentLuf.title) {
            toast.error('Veuillez remplir tous les champs');
            return;
        }
        setActionLoading(true);
        try {
            const newLuf: LUF = {
                createdBy: currentUser?.id!,
                title: currentLuf.title,
                version: newVersion,
                content: currentLuf.content,
                online: false
            };
            const result = await LUFApi.create(newLuf);

            toast.success('LUF créé avec succès');
            setCreating(false);
            setShowConfirmation(false);
            setNewVersion('');
            setCurrentTab(0);

            await loadLufs(result.id);

        } catch (err) {
            toast.error("Échec lors de la création du LUF");
        } finally {
            setActionLoading(false);
        }
    };

    const updateLuf = async () => {
        if (!currentLuf) return;

        setActionLoading(true);
        try {
            const updatedLuf = {
                ...currentLuf,
                updatedBy: currentUser?.id!
            };
            await LUFApi.update(updatedLuf);
            const updatedId = currentLuf.id;

            toast.success('LUF mis à jour avec succès');
            setEditing(false);
            setShowConfirmation(false);

            await loadLufs(updatedId);

        } catch (err) {
            toast.error('Échec de la mise à jour du LUF');
        } finally {
            setActionLoading(false);
        }
    };

    const setLufOnline = async () => {
        if (!currentLuf) return;

        setActionLoading(true);
        try {
            await LUFApi.setLUFOnline(currentLuf.id!, currentUser?.id!);
            const onlineId = currentLuf.id;

            toast.success('LUF mis en ligne avec succès');
            setShowOnlineConfirmation(false);

            await loadLufs(onlineId);

        } catch (err) {
            toast.error('Échec de la mise en ligne du LUF');
        } finally {
            setActionLoading(false);
        }
    };

    const deleteLuf = async () => {
        if (!currentLuf) return;
        setActionLoading(true);
        try {
            await LUFApi.delete(currentLuf.id!);
            toast.success('LUF supprimée avec succès');
            setShowDeleteConfirmation(false);
            await loadLufs();
        } catch (err: any) {
            if (err && err.message && err.message.includes("400")) {
                toast.error('Impossible de supprimer une LUF déjà acceptée ou refusée');
            } else {
                toast.error('Échec de la suppression de la LUF');
            }
        } finally {
            setActionLoading(false);
        }
    };

    const handleTabChange = (newValue: number) => {
        setCurrentTab(newValue);
        if (newValue === 0) {
            setCreating(false);
        } else if (newValue === 1) {
            setEditing(false);
            setCreating(true);

            setCurrentLuf({
                title: '',
                version: '',
                content: '',
                online: false
            });
        }
    };

    const selectLuf = (luf: LUF) => {
        setCurrentLuf(luf);
        setCurrentTab(0);
        setEditing(false);
    };

    const renderTabContent = () => {
        if (currentTab === 0) {
            if (!currentLuf || (currentLuf && (!currentLuf.title && !currentLuf.version && !currentLuf.content))) {
                return (
                    <EmptySelectionMessage
                        message="Sélectionnez une licence dans la liste ou créez-en une nouvelle."
                    />
                );
            } else {
                return (
                    <LufDetails
                        luf={currentLuf}
                        editing={editing}
                        setEditing={setEditing}
                        setCurrentLuf={setCurrentLuf}
                        setShowConfirmation={setShowConfirmation}
                        setShowOnlineConfirmation={setShowOnlineConfirmation}
                        setShowDeleteConfirmation={setShowDeleteConfirmation}
                        disabled={actionLoading}
                    />
                );
            }
        } else if (currentTab === 1) {
            return (
                <LufCreation
                    currentLuf={currentLuf}
                    setCurrentLuf={setCurrentLuf}
                    newVersion={newVersion}
                    setNewVersion={setNewVersion}
                    setShowConfirmation={setShowConfirmation}
                    setCurrentTab={setCurrentTab}
                    disabled={actionLoading}
                />
            );
        }
        return null;
    };

    return (
        <PageContainer>
            <AppHeader title="Gestion des licences utilisateur final (LUF)" />

            {loading ? (
                <LoadingSpinner />
            ) : (
                <TwoColumnLayout
                    leftColumn={
                        <LufList
                            lufs={lufs}
                            currentLufId={currentLuf?.id}
                            onSelectLuf={selectLuf}
                        />
                    }
                    rightColumn={
                        <>
                            <TabBar
                                currentTab={currentTab}
                                onTabChange={handleTabChange}
                                tabs={['Détails', 'Nouvelle version']}
                                loading={actionLoading}
                            />
                            {renderTabContent()}
                        </>
                    }
                />
            )}

            <LufConfirmationDialogs
                showConfirmation={showConfirmation}
                showOnlineConfirmation={showOnlineConfirmation}
                showDeleteConfirmation={showDeleteConfirmation}
                setShowConfirmation={setShowConfirmation}
                setShowOnlineConfirmation={setShowOnlineConfirmation}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
                currentLuf={currentLuf}
                creating={creating}
                createLuf={createLuf}
                updateLuf={updateLuf}
                setLufOnline={setLufOnline}
                deleteLuf={deleteLuf}
                disabled={actionLoading}
            />
        </PageContainer>
    );
};

export default Luf;