import {createTheme, ThemeProvider} from '@mui/material/styles'
import {Router} from './components/Router/Router'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './assets/styles/animation.css'
import {Box} from '@mui/system'
import {ToastProvider} from "./providers/ToastProvider";
import {AuthProvider} from "./Contexts/AuthContext";

// Extension du type de thème pour inclure les nouvelles catégories de couleurs
declare module '@mui/material/styles' {
    interface Palette {
        rensProjet: Palette['primary'];
        descSol: Palette['primary'];
        contexteTopo: Palette['primary'];
        descSylvicole: Palette['primary'];
        obsSanitaire: Palette['primary'];
        deperis: Palette['primary'];
    }

    interface PaletteOptions {
        rensProjet?: PaletteOptions['primary'];
        descSol?: PaletteOptions['primary'];
        contexteTopo?: PaletteOptions['primary'];
        descSylvicole?: PaletteOptions['primary'];
        obsSanitaire?: PaletteOptions['primary'];
        deperis?: PaletteOptions['primary'];
    }
}

const theme = createTheme({
    palette: {
        background: {
            default: '#f8f8f8',
        },
        info: {
            main: '#799776',
        },
        primary: {
            main: '#2b603e',
            light: '#3e7a52',
            dark: '#1e472e',
        },
        secondary: {
            main: '#bc3a00',
        },
        success: {
            main: '#ffa269',
        },

        descSol: {
            main: '#7D7642',
            light: '#BED000',
        },
        contexteTopo: {
            main: '#273375',
            light: '#79AAC1',
        },
        descSylvicole: {
            main: '#397A31',
            light: '#9ECB84',
        },
        obsSanitaire: {
            main: '#6566AC',
            light: '#9D79B4',
        },
        deperis: {
            main: '#E30613',
            light: '#FFA269',
        }
    },
    typography: {
        fontFamily: [
            'ui-sans-serif',
            'system-ui',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            '"Noto Sans"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '"Noto Color Emoji"',
        ].join(','),
    },
})

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <ToastProvider>
                    <Box sx={{height: '100vh', color: 'text.primary'}}>
                        <Router/>
                    </Box>
                </ToastProvider>
            </AuthProvider>
        </ThemeProvider>
    )
}

export default App