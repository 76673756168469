import React, {useState, useCallback, useEffect, useRef} from 'react';
import { Box, TextField, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { debounce } from 'lodash';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {ProjectApi, ProjectResponse} from "../../api/projects_api";

export interface FilterState {
    name: string;
    commune: string;
    creationDate: Date | null;
    updatedDate: Date | null;
    archiveFilter: string;
}

export interface ProjectFilter {
    name?: string;
    commune?: string;
    creationDate?: number;
    updatedDate?: number;
    archive?: boolean;
    page?: number;
    sort?: string;
    sortDir?: string;
}

interface ProjectsFilterProps {
    onSearch: (response: ProjectResponse, filterCriteria?: ProjectFilter) => void;
}

export const ProjectsFilter: React.FC<ProjectsFilterProps> = ({ onSearch }) => {
    const [filters, setFilters] = useState<FilterState>({
        name: '',
        commune: '',
        creationDate: null,
        updatedDate: null,
        archiveFilter: ''
    });

    const handleSearch = useCallback(async (filters: FilterState) => {
        try {
            const filterCriteria: ProjectFilter = {
                name: filters.name || undefined,
                commune: filters.commune || undefined,
                creationDate: filters.creationDate ? Math.floor(filters.creationDate.valueOf() / 1000) : undefined,
                updatedDate: filters.updatedDate ? Math.floor(filters.updatedDate.valueOf() / 1000) : undefined,
                archive: !filters.archiveFilter || filters.archiveFilter === 'all'
                    ? undefined
                    : filters.archiveFilter === 'archived'
            };

            Object.keys(filterCriteria).forEach(key => {
                const typedKey = key as keyof ProjectFilter;
                if (filterCriteria[typedKey] === '' || filterCriteria[typedKey] === undefined) {
                    delete filterCriteria[typedKey];
                }
            });

            const response = await ProjectApi.listUserProjects(filterCriteria);

            onSearch(response, filterCriteria);
        } catch (error) {
            console.error('Erreur de recherche:', error);
            onSearch({
                data: [],
                pagination: {
                    page: 1,
                    limit: 15,
                    totalItems: 0,
                    totalPages: 0
                }
            }, {});
        }
    }, [onSearch]);

    const debouncedSearch = useRef(
        debounce(handleSearch, 500)
    );

    useEffect(() => {
        const currentSearch = debouncedSearch.current;

        return () => {
            currentSearch.cancel();
        };
    }, []);

    const handleInputChange = (field: keyof FilterState) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const newFilters = { ...filters, [field]: newValue };
        setFilters(newFilters);
        debouncedSearch.current(newFilters);
    };

    const handleDateChange = (dateType: 'creationDate' | 'updatedDate', date: Date | null) => {
        const newFilters = { ...filters, [dateType]: date };
        setFilters(newFilters);
        debouncedSearch.current(newFilters);
    };

    const handleArchiveFilterChange = (event: SelectChangeEvent) => {
        const newFilters = { ...filters, archiveFilter: event.target.value };
        setFilters(newFilters);
        debouncedSearch.current(newFilters);
    };

    return (
        <Box
            component="form"
            sx={{ '& .MuiTextField-root, & .MuiFormControl-root': { m: 2, width: '25ch' } }}
            noValidate
            autoComplete="off"
        >
            <TextField
                label="Nom du projet"
                value={filters.name}
                onChange={handleInputChange('name')}
                variant="standard"
            />

            <TextField
                label="Commune"
                value={filters.commune}
                onChange={handleInputChange('commune')}
                variant="standard"
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label="Date de création"
                    inputFormat="DD/MM/YYYY"
                    value={filters.creationDate}
                    onChange={(date) => handleDateChange('creationDate', date)}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                    )}
                />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label="Date de mise à jour"
                    inputFormat="DD/MM/YYYY"
                    value={filters.updatedDate}
                    onChange={(date) => handleDateChange('updatedDate', date)}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                    )}
                />
            </LocalizationProvider>

            <FormControl variant="standard">
                <InputLabel id="archive-filter-label">État d'archivage</InputLabel>
                <Select
                    labelId="archive-filter-label"
                    id="archive-filter"
                    value={filters.archiveFilter}
                    onChange={handleArchiveFilterChange}
                    label="État d'archivage"
                    displayEmpty
                >
                    <MenuItem value="all">Tous</MenuItem>
                    <MenuItem value="archived">Archivés</MenuItem>
                    <MenuItem value="not-archived">Non archivés</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};