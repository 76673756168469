import React from 'react';
import DocumentDetails from '../../../components/common/DocumentDetails';
import { LUF } from '../../../interfaces/luf_interface';

interface LufDetailsProps {
    luf: LUF;
    editing: boolean;
    setEditing: (editing: boolean) => void;
    setCurrentLuf: (luf: LUF) => void;
    setShowConfirmation: (show: boolean) => void;
    setShowOnlineConfirmation: (show: boolean) => void;
    setShowDeleteConfirmation: (show: boolean) => void;
    disabled?: boolean;
}

const LufDetails: React.FC<LufDetailsProps> = ({
                                                   luf,
                                                   editing,
                                                   setEditing,
                                                   setCurrentLuf,
                                                   setShowConfirmation,
                                                   setShowOnlineConfirmation,
                                                   setShowDeleteConfirmation,
                                                   disabled = false
                                               }) => {
    return (
        <DocumentDetails<LUF>
            document={luf}
            editing={editing}
            setEditing={setEditing}
            setCurrentDocument={setCurrentLuf}
            setShowConfirmation={setShowConfirmation}
            setShowOnlineConfirmation={setShowOnlineConfirmation}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            disabled={disabled}
        />
    );
};

export default LufDetails;