import React from 'react';
import DocumentConfirmationDialogs from '../../../components/common/DocumentConfirmationDialogs';
import { LUF } from '../../../interfaces/luf_interface';

interface LufConfirmationDialogsProps {
    showConfirmation: boolean;
    showOnlineConfirmation: boolean;
    showDeleteConfirmation: boolean;
    setShowConfirmation: (show: boolean) => void;
    setShowOnlineConfirmation: (show: boolean) => void;
    setShowDeleteConfirmation: (show: boolean) => void;
    currentLuf: LUF | null;
    creating: boolean;
    createLuf: () => Promise<void>;
    updateLuf: () => Promise<void>;
    setLufOnline: () => Promise<void>;
    deleteLuf: () => Promise<void>;
    disabled?: boolean;
}

const LufConfirmationDialogs: React.FC<LufConfirmationDialogsProps> = ({
                                                                           showConfirmation,
                                                                           showOnlineConfirmation,
                                                                           showDeleteConfirmation,
                                                                           setShowConfirmation,
                                                                           setShowOnlineConfirmation,
                                                                           setShowDeleteConfirmation,
                                                                           currentLuf,
                                                                           creating,
                                                                           createLuf,
                                                                           updateLuf,
                                                                           setLufOnline,
                                                                           deleteLuf,
                                                                           disabled = false
                                                                       }) => {
    return (
        <DocumentConfirmationDialogs<LUF>
            showConfirmation={showConfirmation}
            showOnlineConfirmation={showOnlineConfirmation}
            showDeleteConfirmation={showDeleteConfirmation}
            setShowConfirmation={setShowConfirmation}
            setShowOnlineConfirmation={setShowOnlineConfirmation}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            currentDocument={currentLuf}
            creating={creating}
            createDocument={createLuf}
            updateDocument={updateLuf}
            setDocumentOnline={setLufOnline}
            deleteDocument={deleteLuf}
            documentType="LUF"
            disabled={disabled}
        />
    );
};

export default LufConfirmationDialogs;