import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

interface InfoRowProps {
    label: string;
    value?: string | number | boolean | null;
    unit?: string;
}

export const InfoRow: React.FC<InfoRowProps> = ({ label, value, unit }) => {

    let displayValue: string | number | null = value as string | number | null;

    if (displayValue === null || displayValue === undefined) {
        displayValue = '';
    } else if (typeof value === 'boolean') {
        displayValue = value ? 'Oui' : 'Non';
    } else if (typeof displayValue === 'string' && displayValue.length > 0) {
        displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
    }

    const showUnit = displayValue !== '' && unit;

    return (
        <>
            <Box sx={{
                display: 'flex',
                gap: 2,
                mt: 2,
                mb: 2
            }}>
                <Box sx={{ flexBasis: '30%' }}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: '0.8rem',
                    }}>
                        {label}
                    </Typography>
                </Box>
                <Box sx={{ flexBasis: '70%' }}>
                    <Typography sx={{ fontSize: '0.9rem' }}>
                        {displayValue}{showUnit ? ` ${unit}` : ''}
                    </Typography>
                </Box>
            </Box>
            <Divider />
        </>
    );
};